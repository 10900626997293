import useCommonUtils from '@/composables/app/useCommonUtils';
import useCrud from '@/composables/app/useCrud';
const { isJsonParsable } = useCommonUtils();
const { updateData: updateMailboxConfiguration } = useCrud(
	'/customer/mailbox-configuration/'
);
const jsonFields = ['access_tokens', 'email_signatures', 'meta'];
const parseData = payload => {
	return Object.entries(payload || {}).reduce((r, [key, val]) => {
		if (jsonFields.includes(key)) {
			return { ...r, [key]: isJsonParsable(val) ? JSON.parse(val) : val };
		}
		return { ...r, [key]: val };
	}, {});
};

const stringifyData = payload => {
	return Object.entries(payload || {}).reduce((r, [key, val]) => {
		if (['id', 'user_id'].includes(key)) return r;
		if (jsonFields.includes(key)) {
			return { ...r, [key]: JSON.stringify(val) };
		}
		return { ...r, [key]: val };
	}, {});
};

const state = {
	msalInstance: null,
	gapiInstance: null,
	configuration: null,
};

const mutations = {
	setMsal: (state, payload) => {
		state.msalInstance = payload;
	},
	setGapi: (state, payload) => {
		state.gapiInstance = payload;
	},
	set: (state, payload) => {
		state.configuration = parseData(payload);
	},
	update: (state, payload) => {
		state.configuration = payload;
		if (payload?.id) updateMailboxConfiguration(stringifyData(payload), false);
	},
};

export default { state, mutations };
