import store from '@/store';
import { computed } from 'vue';
const useAppConfig = () => {
	const currentWindowWidth = computed({
		get: () => store.state.currentWindowWidth,
		set: value => store.commit('setWindowWidth', value),
	});

	const currentBreakPoint = computed(() => {
		if (currentWindowWidth.value < 576) return 'xs';
		else if (currentWindowWidth.value >= 576 && currentWindowWidth.value < 768)
			return 'sm';
		else if (currentWindowWidth.value >= 768 && currentWindowWidth.value < 992)
			return 'md';
		else if (currentWindowWidth.value >= 992 && currentWindowWidth.value < 1200)
			return 'lg';
		else if (
			currentWindowWidth.value >= 1200 &&
			currentWindowWidth.value < 1400
		)
			return 'xl';
		else if (currentWindowWidth.value >= 1400) return 'xxl';
		else return '';
	});

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	const scrollToBottom = () => {
		setTimeout(() => {
			window.scrollTo(0, document.body.scrollHeight);
		}, 50);
	};

	const scrollToHeight = height => {
		if (typeof height === 'number')
			setTimeout(() => {
				window.scrollTo(0, height);
			}, 50);
		if (typeof height === 'string') {
			const el = document.querySelector(height);
			if (el)
				setTimeout(() => {
					window.scrollTo(0, el.scrollHeight);
				}, 50);
		}
	};

	const perfectScrollbarScrollToHeight = (
		cssSelector = null,
		height = null
	) => {
		if (!cssSelector) cssSelector = '.ps';
		const el = document.querySelector(cssSelector);
		if (el) {
			if (!height) height = el.scrollHeight;
			setTimeout(() => {
				el.scrollTop = height;
			}, 500);
		}
	};

	const scrollElementIntoView = (cssSelector, options = null) => {
		setTimeout(() => {
			const el = document.querySelector(cssSelector);
			if (el) el.scrollIntoView(options);
		}, 150);
	};

	const darkMode = computed(() => {
		return store?.state?.is_dark_mode;
	});

	return {
		currentWindowWidth,
		currentBreakPoint,
		scrollToTop,
		scrollToBottom,
		scrollToHeight,
		perfectScrollbarScrollToHeight,
		scrollElementIntoView,
		darkMode,
	};
};

export default useAppConfig;
