import useDate from '@/composables/app/useDate';
const { getAvailableTimeZones } = useDate();

const state = {
	timezones: getAvailableTimeZones(),
	userTimezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
	userTimezoneOffset: new Date().getTimezoneOffset(),
};

export default { state };
