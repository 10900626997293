<script setup>
import {
    ref,
    computed,
    watchEffect,
    watch,
    onMounted,
    onBeforeMount
} from 'vue';
import { useStore } from 'vuex';
import { format, subDays, isAfter } from 'date-fns';
import * as yup from 'yup';
import useValidation from '@/composables/app/useValidation';
import useCommonUtils from '@/composables/app/useCommonUtils';
import Vselect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import useToast from '@/composables/app/useToast';
import useCrud from '@/composables/app/useCrud';

const store = useStore();
const { runYupValidation } = useValidation();
const { disableKeys, isEmptyObject, stringCapitalize } = useCommonUtils();
const { showDefaultToast, showConfirmModal } = useToast();
const {
    getData: getList,
    createData: createApproval,
    deleteData: deleteApproval,
    updateData: updateApproval
} = useCrud('/admin-app/job-approval/');
const { getData: getStatus, bulkPatch: changeStatus } = useCrud(
    '/admin-app/job-approval-status/'
);

const { getData: getBudgetStatus, bulkPatch: changeBudgetStatus } = useCrud(
    '/admin-app/budgeted-status/'
);

const isChecked = ref(true);
const isBudgetedChecked = ref(true);

const list = ref([]);
const listLoading = ref(true);
const buttonLoading = ref(false);
const isMatrixCreated = ref(false);
const showOffCanvas = ref(false);
const userTypes = ['recruiter', 'hiring_manager'];
const validationErrors = ref({});
const validationSchema = yup.object().shape({
    name: yup.string().required('Approval Name is required').nullable(),
    org: yup.object().required('Organization is required').nullable()
});
const initialFormData = {
    name: null,
    org: null,
    company: null,
    business_unit: null,
    department: null,
    id: null,
    levels: [{ level: 1, type: null, user: null }],
    updated_by: null,
    updated_on: null
};
const formData = ref(initialFormData);

const filterByColumn = ref(true);
const allColumns = [
    'name',
    'organization',
    'company',
    'business_uint',
    'department',
    'levels',
    'last_update',
    'action'
];

const columns = ref(allColumns);
const approveTable = ref(null);
const orderedColumns = computed(() =>
    allColumns.filter((column) => columns.value.includes(column))
);

const table_option = computed(() => ({
    perPage: 10,
    perPageValues: [5, 10, 20, 50],
    skin: 'table',
    columnsClasses: { actions: 'actions text-center' },
    sortable: [],
    pagination: { nav: 'scroll', chunk: 5 },
    texts: {
        count: 'Showing {from} to {to} of {count}',
        filter: '',
        filterPlaceholder: 'Search...',
        limit: 'Show:'
    },
    sortable: [
        'name',
        'organization',
        'company',
        'business_uint',
        'department',
        'levels',
        'last_update',
        'action'
    ],
    sortIcon: {
        base: 'sort-icon-none',
        up: 'sort-icon-asc',
        down: 'sort-icon-desc'
    },
    resizableColumns: false,
    filterByColumn: true,
    listColumns: {
        last_update: [
            { id: subDays(new Date(), 7), text: 'Last 7 days' },
            { id: subDays(new Date(), 15), text: 'Last 15 days' },
            { id: subDays(new Date(), 30), text: 'Last month' },
            { id: subDays(new Date(), 90), text: 'Last 3 months' },
            { id: subDays(new Date(), 365), text: 'Last year' }
        ]
    },
    filterAlgorithm: {
        name: (row, q) => {
            if (q.slice(-1) === ' ') {
                q = q.slice(1, -1);
            }
            return row?.name.toLowerCase().includes(q.toLowerCase());
        },
        organization: (row, q) => {
            if (q.slice(-1) === ' ') {
                q = q.slice(1, -1);
            }
            return row?.org?.name.toLowerCase().includes(q.toLowerCase());
        },
        company: (row, q) => {
            if (q.slice(-1) === ' ') {
                q = q.slice(1, -1);
            }
            return row?.company?.name.toLowerCase().includes(q.toLowerCase());
        },

        business_uint: (row, q) => {
            if (q.slice(-1) === ' ') {
                q = q.slice(1, -1);
            }
            return row?.business_unit?.name
                .toLowerCase()
                .includes(q.toLowerCase());
        },
        department: (row, q) => {
            if (q.slice(-1) === ' ') {
                q = q.slice(1, -1);
            }
            return row?.department?.name
                .toLowerCase()
                .includes(q.toLowerCase());
        },
        levels: (row, q) => {
            if (q.slice(-1) === ' ') {
                q = q.slice(1, -1);
            }
            return row?.levels?.length == q;
        },
        last_update: (row, q) => {
            if (!row.updated_on) return false;
            return isAfter(new Date(row.updated_on), new Date(q));
        }
    }
}));
const organizations = computed(() => {
    return store.state?.recruitments?.organizations?.orgs
        ? store.state?.recruitments?.organizations?.orgs
        : [];
});
const companies = computed(() => {
    if (
        !formData.value?.org ||
        !formData.value?.org?.id ||
        !organizations.value ||
        !organizations.value.length
    )
        return [];

    const data = store.state?.recruitments?.companies?.companies
        ? store.state?.recruitments?.companies?.companies
        : [];
    if (!data || !data.length) return [];

    return data.filter(
        (item) =>
            item.org &&
            parseInt(item.org?.id) === parseInt(formData.value.org.id)
    );
});
const businessUnits = computed(() => {
    if (
        !formData.value?.org ||
        !formData.value?.org?.id ||
        !organizations.value ||
        !organizations.value.length
    )
        return [];

    let data = store.state?.recruitments?.businessUnits?.units
        ? store.state?.recruitments?.businessUnits?.units
        : [];
    if (!data || !data.length) return [];

    data = data.filter(
        (item) =>
            item.org &&
            parseInt(item.org?.id) === parseInt(formData.value.org.id)
    );

    if (formData.value.company && formData.value.company?.id) {
        data = data.filter(
            (item) =>
                item.company &&
                parseInt(item.company?.id) ===
                    parseInt(formData.value.company.id)
        );
    }
    return data;
});
const departments = computed(() => {
    if (
        !formData.value?.org ||
        !formData.value?.org?.id ||
        !organizations.value ||
        !organizations.value.length
    )
        return [];

    let data = store.state?.recruitments?.departments?.depts
        ? store.state?.recruitments?.departments?.depts
        : [];

    if (!data || !data.length) return [];

    data = data.filter(
        (item) =>
            item.org &&
            parseInt(item.org?.id) === parseInt(formData.value.org.id)
    );

    if (formData.value.company && formData.value.company?.id) {
        data = data.filter(
            (item) =>
                item.company &&
                parseInt(item.company?.id) ===
                    parseInt(formData.value.company.id)
        );
    }

    if (formData.value.business_unit && formData.value.business_unit?.id) {
        data = data.filter(
            (item) =>
                item.business_unit &&
                parseInt(item.business_unit?.id) ===
                    parseInt(formData.value.business_unit.id)
        );
    }
    return data;
});
const recruiterList = computed(() => {
    return store.getters['auth/getRecruitersList'];
});
const filteredRecruiterList = computed(() => {
    let users = [...formData.value.levels].map((levels) => {
        return [...recruiterList.value].filter((recruiters) => {
            return [...formData.value.levels].every(
                (item) => item?.user?.id !== recruiters?.id
            );
        });
    });
    return users[0];
});

const onUpdateApproval = (item) => {
    formData.value.id = item?.id;
    formData.value.name = item?.name;
    formData.value.org = item?.org;
    formData.value.company = item?.company;
    formData.value.business_unit = item?.business_unit;
    formData.value.department = item?.department;
    formData.value.levels = [...item?.levels];
    formData.value.updated_by = item?.updated_by;
    formData.value.updated_on = item?.updated_on;
    showOffCanvas.value = true;
};
const onChangeStatus = async () => {
    let data = (isChecked.value = !isChecked.value);
    const payload = {
        status: data
    };
    await changeStatus(payload).then(async (res) => {
        if (res?.code === 0) {
            showDefaultToast(res?.message, 'success');
            listLoading.value = true;
            list.value = await getList();
            const status = await getStatus();
            isChecked.value = status?.status;
            listLoading.value = false;
        } else {
            showDefaultToast(res?.message, 'danger');
        }
    });
};

const onBudgetedChangeStatus = async () => {
    let data = (isBudgetedChecked.value = !isBudgetedChecked.value);
    const payload = {
        status: data
    };
    await changeBudgetStatus(payload).then(async (res) => {
        if (res?.code === 0) {
            showDefaultToast(res?.message, 'success');
            listLoading.value = true;
            list.value = await getList();
            const status = await getBudgetStatus();
            isBudgetedChecked.value = status?.status;
            listLoading.value = false;
        } else {
            showDefaultToast(res?.message, 'danger');
        }
    });
};

const onDeleteApproval = async (id) => {
    showConfirmModal(null, async (confirmed) => {
        if (confirmed) {
            const success = await deleteApproval(id);
            if (success) {
                showDefaultToast('Success', 'success', 'Approval deleted');
                listLoading.value = true;
                list.value = await getList();
                listLoading.value = false;
            }
        }
    });
};
const showLevels = (id) => {
    if (approveTable.value) {
        approveTable.value.toggleChildRow(id);
    }
};
const levelsValidation = () => {
    const levels = [...formData?.value.levels];
    const validLevels = levels?.filter((level) => level.type && level.user);
    if (levels?.length !== validLevels?.length) {
        showDefaultToast('User is required', 'danger');
        return false;
    } else if (levels?.length === validLevels?.length) {
        return true;
    }
};
const gerHiringManagerList = () => {
    if (formData.value.department) {
        return formData.value.department?.hiring_managers?.map((i) => {
            return i?.manager;
        });
    } else if (formData.value.business_unit) {
        return formData.value.business_unit?.hiring_managers?.map((i) => {
            return i?.manager;
        });
    } else if (formData.value?.company) {
        return formData.value.company?.hiring_managers?.map((i) => {
            return i?.manager;
        });
    } else if (formData.value.org) {
        return formData.value.org?.hiring_managers?.map((i) => {
            return i?.manager;
        });
    }
};
const reset_Company_BusinessUnit_Department = (type) => {
    if (type === 'all') {
        formData.value.company = null;
        formData.value.business_unit = null;
        formData.value.department = null;
        formData.value.levels = [{ level: 1, type: null, user: null }];
    } else if (type === 'levels') {
        formData.value.levels = [{ level: 1, type: null, user: null }];
    }
};
const addMoreLevel = () => {
    if (formData.value.levels?.length === 5) {
        showDefaultToast('Upto 5 Levels maximum', 'danger');
    } else {
        const validateResult = levelsValidation();
        if (validateResult) {
            formData?.value.levels.push({
                level: formData?.value.levels?.length + 1,
                type: null,
                user: null
            });
        }
    }
};
const openOffCanvas = () => {
    showOffCanvas.value = true;
};
const hideOffcanvas = () => {
    formData.value = {
        name: null,
        org: null,
        company: null,
        business_unit: null,
        department: null,
        levels: [{ level: 1, type: null, user: null }],
        id: null
    };
    showOffCanvas.value = false;
    validationErrors.value = {};
};
const runValidationSchema = async () => {
    const { validated, errors } = await runYupValidation(validationSchema, {
        ...formData.value
    });
    if (validated) validationErrors.value = {};
    else
        validationErrors.value = {
            ...errors
        };

    return isEmptyObject(validationErrors.value);
};
const removeUser = (index) => {
    const oldLevels = [...formData.value?.levels];
    oldLevels.splice(index, 1);
    const newLevels = oldLevels?.map((item, index) => {
        return {
            level: index + 1,
            type: item?.type,
            user: item?.user
        };
    });
    formData.value.levels = newLevels;
};
const onCreateApproval = async () => {
    const validated = await runValidationSchema();
    if (!validated) return;
    else {
        const isLevelsValidate = levelsValidation();
        if (isLevelsValidate) {
            const payload = {
                name: formData?.value?.name,
                org: formData?.value?.org?.id,
                company: formData?.value?.company?.id
                    ? formData.value.company.id
                    : null,
                business_unit: formData?.value?.business_unit?.id
                    ? formData.value.business_unit.id
                    : null,
                department: formData?.value?.department?.id
                    ? formData.value.department.id
                    : null,
                levels: formData?.value?.levels?.map((item) => {
                    return {
                        level: item?.level,
                        type: item?.type,
                        user: item?.user?.id
                    };
                })
            };

            buttonLoading.value = true;
            if (formData?.value?.id) {
                await updateApproval(payload, formData.value.id).then(
                    async (res) => {
                        buttonLoading.value = false;
                        if (res?.code === 0) {
                            showDefaultToast(res?.message, 'success');
                            hideOffcanvas();
                            listLoading.value = true;
                            list.value = await getList();
                            listLoading.value = false;
                        } else {
                            showDefaultToast(res?.message, 'danger');
                        }
                    }
                );
            } else {
                await createApproval(payload)
                    .then(async (res) => {
                        buttonLoading.value = false;
                        if (res?.code === 0) {
                            showDefaultToast(res?.message, 'success');
                            hideOffcanvas();
                            listLoading.value = true;
                            list.value = await getList();
                            listLoading.value = false;
                        } else {
                            showDefaultToast(res?.message, 'danger');
                        }
                    })
                    .catch((er) => {
                        buttonLoading.value = false;
                        console.log(er);
                    });
            }
        }
    }
};
const onRefresh = async () => {
    listLoading.value = true;
    list.value = await getList();

    listLoading.value = false;
};
onMounted(async () => {
    list.value = await getList();
    const status = await getStatus();
    isChecked.value = status?.status;

    const BudgetStatus = await getBudgetStatus();
    isBudgetedChecked.value = BudgetStatus?.status;

    listLoading.value = false;
});
</script>
<template>
    <div>
        <div class="container py-5">
            <div
                class="title mb-6 d-flex align-items-center justify-content-between"
            >
                <h4 class="fw-bold">Job Approval Matrix</h4>
                <div>
                    <div
                        class="d-flex align-items-center justify-content-between gap-4"
                    >
                        <p>Enable Settings</p>
                        <label class="switch s-success">
                            <input
                                type="checkbox"
                                :checked="isChecked"
                                @change="onChangeStatus()"
                            />
                            <span
                                class="slider round"
                                :class="[
                                    !$store.state.is_dark_mode && !isChecked
                                        ? 'border bg-white'
                                        : ''
                                ]"
                            ></span>
                        </label>
                    </div>
                    <div class="d-flex align-items-center gap-4">
                        <p>Enable Budget Settings</p>
                        <label class="switch s-success">
                            <input
                                type="checkbox"
                                :checked="isBudgetedChecked"
                                @change="onBudgetedChangeStatus()"
                            />
                            <span
                                class="slider round"
                                :class="[
                                    !$store.state.is_dark_mode &&
                                    !isBudgetedChecked
                                        ? 'border bg-white'
                                        : ''
                                ]"
                            ></span>
                        </label>
                    </div>
                </div>
            </div>

            <div
                v-if="!isChecked"
                class="alert alert-light-warning alert-dismissible border-0 my-4 approval-alert"
                role="alert"
            >
                <strong>Warning!</strong> All jobs are open now. Job approval
                feature is disabled application-wide. No job approval matrix
                will take action after creating new jobs
            </div>
            <div
                v-if="listLoading"
                class="d-flex flex-column table-loading"
            >
                <p class="placeholder-glow mb-2">
                    <span class="col-9 placeholder"></span>
                </p>
                <p class="placeholder-glow mb-2">
                    <span class="col-12 placeholder"></span>
                </p>
                <p class="placeholder-glow mb-2">
                    <span class="col-6 placeholder"></span>
                </p>
            </div>
            <div v-else>
                <div
                    v-if="list?.length === 0"
                    class="row"
                >
                    <div
                        class="col-12"
                        style="min-width: 320px"
                    >
                        <div class="card border-0 bg-body">
                            <div
                                class="w-100 h-100 d-flex align-items-center justify-content-center"
                            >
                                <div
                                    class="d-flex flex-column justify-content-center align-items-center py-5"
                                >
                                    <img
                                        :src="
                                            require('@/assets/images/IconImage.e6f38398.png')
                                        "
                                        class="mb-3 empty-case-image"
                                    />
                                    <div class="fw-100 mb-3 text-14px">
                                        No Job Matrix Found
                                    </div>
                                    <button
                                        class="btn btn-primary px-3 py-2 text-14px"
                                        @click="openOffCanvas"
                                    >
                                        <FeatherIcon
                                            type="plus"
                                            size="6"
                                            class="me-2"
                                        />
                                        Create New Job Approval Matrix
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="list?.length > 0"
                    class="custom-table pt-0 mt-0"
                    :class="[
                        !filterByColumn ? 'filter-by-column-disabled' : ''
                    ]"
                >
                    <v-client-table
                        :data="list"
                        :columns="orderedColumns"
                        :options="table_option"
                        ref="approveTable"
                    >
                        <template #afterFilterWrapper>
                            <div
                                class="d-flex flex-row align-items-center table-buttons me-3"
                            >
                                <button
                                    class="btn btn-outline-info me-1 px-2 py-2 d-flex flex-row justify-content-center align-items-center"
                                    @click="showOffCanvas = true"
                                >
                                    <FeatherIcon
                                        type="plus-circle"
                                        stroke-width="2"
                                        class="me-1"
                                        size="12"
                                    />
                                    Add new
                                </button>
                                <button
                                    class="btn btn-outline-info me-1 px-2 py-2 d-flex flex-row justify-content-center align-items-center"
                                    @click="onRefresh"
                                >
                                    <FeatherIcon
                                        type="refresh-ccw"
                                        stroke-width="2"
                                        class="me-1"
                                        size="100"
                                    />
                                    Refresh
                                </button>
                                <button
                                    class="btn me-1 px-2 py-2 d-flex flex-row justify-content-center align-items-center"
                                    :class="[
                                        filterByColumn
                                            ? 'btn-info'
                                            : 'btn-outline-info'
                                    ]"
                                    @click="filterByColumn = !filterByColumn"
                                >
                                    <FeatherIcon
                                        type="filter"
                                        stroke-width="2"
                                        class="me-1"
                                    />
                                    <span class="d-none d-md-block"
                                        >Filter</span
                                    >
                                </button>
                            </div>
                        </template>
                        <template #name="props">
                            {{ props.row.name }}
                        </template>
                        <template #organization="props">
                            {{ props?.row?.org ? props?.row?.org.name : '-' }}
                        </template>
                        <template #company="props">
                            {{
                                props?.row?.company?.name
                                    ? props?.row?.company?.name
                                    : '-'
                            }}
                        </template>
                        <template #business_uint="props">
                            {{
                                props?.row?.business_unit?.name
                                    ? props?.row?.business_unit?.name
                                    : '-'
                            }}
                        </template>
                        <template #department="props">
                            {{
                                props?.row?.department?.name
                                    ? props?.row?.department?.name
                                    : '-'
                            }}
                        </template>
                        <template #levels="props">
                            <div class="d-flex align-items-start gap-3">
                                <p>
                                    {{ props?.row?.levels?.length }}
                                </p>
                                <FeatherIcon
                                    type="chevrons-down"
                                    class="text-secondary cursor-pointer"
                                    size="20"
                                    @click="showLevels(props?.row?.id)"
                                />
                            </div>
                        </template>
                        <template #last_update="props">
                            <div class="d-flex align-items-start gap-3">
                                <p>
                                    {{
                                        `${
                                            props?.row?.updated_by
                                                ? props?.row?.updated_by
                                                      ?.username + ' on '
                                                : ' '
                                        }${
                                            props?.row?.updated_on
                                                ? format(
                                                      new Date(
                                                          props?.row?.updated_on
                                                      ),
                                                      'dd/MM/yyyy'
                                                  )
                                                : '-'
                                        }`
                                    }}
                                </p>
                            </div>
                        </template>
                        <template #action="props">
                            <div class="d-flex align-items-start gap-3">
                                <FeatherIcon
                                    type="edit"
                                    class="text-primary cursor-pointer"
                                    size="20"
                                    @click="onUpdateApproval(props?.row)"
                                />
                                <FeatherIcon
                                    type="trash"
                                    class="text-danger cursor-pointer"
                                    size="20"
                                    @click="onDeleteApproval(props?.row?.id)"
                                />
                            </div>
                        </template>
                        <template #child_row="props">
                            <div class="row my-3">
                                <div
                                    class="col-4 col-md-3 mb-3"
                                    v-for="(i, index) in props?.row?.levels"
                                    :key="index"
                                >
                                    <div class="panel-body">
                                        <div
                                            class="card component-card_1 w-100 p-3"
                                        >
                                            <h5 class="card-title mb-4">
                                                Level {{ i?.level }}
                                            </h5>
                                            <div class="card-text">
                                                <div
                                                    class="d-flex align-items-center gap-3 mb-3"
                                                >
                                                    <p>Type</p>
                                                    <button
                                                        class="btn btn-outline-info px-4"
                                                    >
                                                        {{
                                                            stringCapitalize(
                                                                i?.type
                                                                    ?.split('_')
                                                                    ?.join(' ')
                                                            )
                                                        }}
                                                    </button>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center gap-3"
                                                >
                                                    <p>User</p>
                                                    <button
                                                        class="btn btn-outline-primary px-4"
                                                    >
                                                        {{ i?.user?.username }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </v-client-table>
                </div>
            </div>
        </div>

        <!-- create form -->
        <div class="w-100">
            <div
                v-if="showOffCanvas"
                class="overshow-overlay bg-body"
                @click="hideOffcanvas"
            ></div>
            <Transition>
                <div
                    v-if="showOffCanvas"
                    class="slide-left-overshow overflow-auto department-overshow bg-body p-3 p-md-4 border-start"
                >
                    <div class="w-100">
                        <div
                            class="w-100 d-flex flex-row justify-content-end align-items-center mb-3"
                        >
                            <FeatherIcon
                                type="x"
                                size="18"
                                class="cursor-pointer"
                                @click="hideOffcanvas"
                            />
                        </div>

                        <h4 class="mb-4">Create Job Approval Matrix</h4>
                        <div class="row mb-4">
                            <div class="col-12 col-md-6 mb-4 mb-md-0">
                                <label
                                    >Approval Name<span class="text-danger mx-2"
                                        ><strong>*</strong></span
                                    ></label
                                >
                                <input
                                    v-model="formData.name"
                                    type="text"
                                    class="form-control h-min-content py-2"
                                    placeholder="Enter Approval Name"
                                />
                                <div
                                    v-if="validationErrors.name"
                                    class="mt-1 text-danger text-12px"
                                >
                                    {{ validationErrors.name }}
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <label
                                    >Organization<span class="text-danger mx-2"
                                        ><strong>*</strong></span
                                    ></label
                                >
                                <Vselect
                                    v-model="formData.org"
                                    :options="organizations"
                                    label="name"
                                    :clearable="false"
                                    class="no-tags"
                                    placeholder="Select Organization"
                                    @option:selected="
                                        reset_Company_BusinessUnit_Department(
                                            'all'
                                        )
                                    "
                                >
                                </Vselect>
                                <div
                                    v-if="validationErrors.org"
                                    class="mt-1 text-danger text-12px"
                                >
                                    {{ validationErrors.org }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="row mb-4"
                            v-if="formData.org"
                        >
                            <div class="col-12 col-md-6 mb-4 mb-md-0">
                                <label>Company</label>
                                <Vselect
                                    v-model="formData.company"
                                    :options="companies"
                                    label="name"
                                    :clearable="false"
                                    :disabled="!formData.org"
                                    class="no-tags"
                                    @option:selected="
                                        reset_Company_BusinessUnit_Department(
                                            'levels'
                                        );
                                        formData.business_unit = null;
                                        formData.department = null;
                                    "
                                >
                                </Vselect>
                            </div>
                            <div class="col-12 col-md-6">
                                <label>Business Unit</label>
                                <Vselect
                                    v-model="formData.business_unit"
                                    :options="businessUnits"
                                    label="name"
                                    :clearable="false"
                                    :disabled="!formData.org"
                                    class="no-tags"
                                    @option:selected="
                                        reset_Company_BusinessUnit_Department(
                                            'levels'
                                        );
                                        formData.department = null;
                                    "
                                >
                                </Vselect>
                            </div>
                        </div>
                        <div
                            class="row mb-4"
                            v-if="formData.org"
                        >
                            <div class="col-12 col-md-6">
                                <label>Department</label>
                                <Vselect
                                    v-model="formData.department"
                                    :options="departments"
                                    label="name"
                                    :clearable="false"
                                    :disabled="!formData.org"
                                    class="no-tags"
                                    @option:selected="
                                        reset_Company_BusinessUnit_Department(
                                            'levels'
                                        )
                                    "
                                >
                                </Vselect>
                            </div>
                        </div>
                        <div
                            v-if="formData.org"
                            class="mb-4"
                        >
                            <div class="mb-4 text-end">
                                <button
                                    class="btn btn-primary px-3 py-2"
                                    @click="addMoreLevel"
                                >
                                    <FeatherIcon
                                        type="plus"
                                        size="6"
                                        class="me-2"
                                    />
                                    Add New Level
                                </button>
                            </div>
                            <div
                                class="table-responsive"
                                style="min-height: 380px"
                            >
                                <table class="table">
                                    <thead role="rowgroup">
                                        <tr role="row">
                                            <th
                                                role="columnheader"
                                                scope="col"
                                                aria-colindex="1"
                                                width="10%"
                                            >
                                                <div>Level</div>
                                            </th>
                                            <th
                                                role="columnheader"
                                                scope="col"
                                                aria-colindex="2"
                                                width="30%"
                                            >
                                                <div>
                                                    User Type<span
                                                        class="text-danger mx-2"
                                                        ><strong
                                                            >*</strong
                                                        ></span
                                                    >
                                                </div>
                                            </th>
                                            <th
                                                role="columnheader"
                                                scope="col"
                                                aria-colindex="2"
                                                width="50%"
                                            >
                                                <div>
                                                    User<span
                                                        class="text-danger mx-2"
                                                        ><strong
                                                            >*</strong
                                                        ></span
                                                    >
                                                </div>
                                            </th>
                                            <th
                                                role="columnheader"
                                                scope="col"
                                                aria-colindex="2"
                                                width="10%"
                                            >
                                                <div>Action</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody role="rowgroup">
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in formData.levels"
                                            :key="index"
                                            role="row"
                                            class="text-center"
                                        >
                                            <td
                                                aria-colindex="1"
                                                role="cell"
                                            >
                                                {{ item?.level }}
                                            </td>
                                            <td
                                                aria-colindex="2"
                                                role="cell"
                                            >
                                                <Vselect
                                                    class="no-tags"
                                                    :clearable="false"
                                                    v-model="item.type"
                                                    :options="userTypes"
                                                    @option:selected="
                                                        item.user = null
                                                    "
                                                    placeholder="Select User Type"
                                                >
                                                    <template
                                                        #selected-option="{
                                                            label
                                                        }"
                                                    >
                                                        {{
                                                            stringCapitalize(
                                                                label
                                                                    .split('_')
                                                                    .join(' ')
                                                            )
                                                        }}
                                                    </template>
                                                    <template
                                                        #option="{ label }"
                                                    >
                                                        {{
                                                            stringCapitalize(
                                                                label
                                                                    .split('_')
                                                                    .join(' ')
                                                            )
                                                        }}
                                                    </template>
                                                </Vselect>
                                            </td>
                                            <td
                                                aria-colindex="3"
                                                role="cell"
                                            >
                                                <Vselect
                                                    v-if="
                                                        item.type ===
                                                        'recruiter'
                                                    "
                                                    :multiple="false"
                                                    :clearable="false"
                                                    v-model="item.user"
                                                    class="no-tags"
                                                    :options="
                                                        filteredRecruiterList
                                                    "
                                                    placeholder="Select User"
                                                    :get-option-label="
                                                        (option) =>
                                                            option?.first_name +
                                                            ' ' +
                                                            option?.last_name +
                                                            ' ' +
                                                            `(${option?.username})`
                                                    "
                                                >
                                                </Vselect>

                                                <Vselect
                                                    v-else-if="
                                                        item.type ===
                                                        'hiring_manager'
                                                    "
                                                    :multiple="false"
                                                    :clearable="false"
                                                    v-model="item.user"
                                                    :options="
                                                        gerHiringManagerList()
                                                    "
                                                    class="no-tags"
                                                    placeholder="Select User"
                                                    :get-option-label="
                                                        (option) =>
                                                            option?.first_name +
                                                            ' ' +
                                                            option?.last_name +
                                                            ' ' +
                                                            `(${option?.username})`
                                                    "
                                                >
                                                </Vselect>
                                            </td>
                                            <td
                                                aria-colindex="3"
                                                role="cell"
                                            >
                                                <FeatherIcon
                                                    type="trash"
                                                    class="text-danger cursor-pointer"
                                                    size="20"
                                                    @click="removeUser(index)"
                                                    v-if="
                                                        formData.levels
                                                            ?.length > 1
                                                    "
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-12 col-md-6 offset-md-6">
                                <button
                                    class="btn btn-success py-2 w-100"
                                    @click="onCreateApproval"
                                    :disabled="buttonLoading"
                                >
                                    <div
                                        v-if="buttonLoading"
                                        class="spinner-border spinner-border-sm me-1"
                                        role="status"
                                        style="width: 10px; height: 10px"
                                    >
                                        <span class="visually-hidden"
                                            >Loading...</span
                                        >
                                    </div>
                                    <FeatherIcon
                                        v-else
                                        type="save"
                                        size="20"
                                        class="cursor-pointer me-2"
                                    />
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div
                            v-if="formData?.id"
                            class="w-100 d-flex flex-row justify-content-between mt-3"
                        >
                            <div class="d-flex flex-column">
                                <div class="text-semi-contrast">
                                    Last updated by
                                </div>
                                <div class="text-12px text-medium">
                                    {{
                                        formData.updated_by &&
                                        formData.updated_by.username
                                            ? formData.updated_by.username
                                            : '-'
                                    }}
                                </div>
                            </div>

                            <div class="d-flex flex-column">
                                <div class="text-semi-contrast">
                                    Last updated on
                                </div>
                                <div class="text-12px text-medium">
                                    {{
                                        formData.updated_on
                                            ? format(
                                                  new Date(formData.updated_on),
                                                  'dd/MM/yyyy'
                                              )
                                            : '-'
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/base/_base.scss';
@import '@/assets/sass/components/cards/card.scss';
@import '@/assets/sass/forms/switches.scss';
.empty-case-image {
    width: 136px;
    height: 100%;
    border-radius: 4px;
}
.slide-left-overshow {
    width: 800px;
    max-width: 100%;
    z-index: 9999 !important;
}
</style>
