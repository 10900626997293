import { computed } from 'vue';
import { isPlainObject } from 'lodash';
import useCustomFields from '@/composables/app/useCustomFields';

const useHtmlEditor = () => {
  const { customFields } = useCustomFields();

  const dynamicFieldsToArray = (fields) => {
    return Object.keys(fields).reduce((result, key) => {
      const current = fields[key];
      current.forEach((item) => result.push(item.value));
      return result;
    }, []);
  };

  const validateDynamicFieldResult = (text, givendynamicFieldValues) => {
    if (isPlainObject(givendynamicFieldValues)) {
      givendynamicFieldValues = [
        ...dynamicFieldsToArray(givendynamicFieldValues)
      ];
    }
    let errors = [];

    const countStartBrace = (text.match(/{/g) || []).length;
    const countEndBrace = (text.match(/}/g) || []).length;
    if (countStartBrace !== countEndBrace) {
      const diff = countStartBrace - countEndBrace;
      const countError = `contains ${Math.abs(diff)} ${
        diff >= 0
          ? 'start curly braces without end curly brace'
          : 'end curly braces without start curly brace'
      }`;
      errors.push(countError);
    }

    const matches = [...text.matchAll(/{([^}]*)}/g)];
    matches.forEach((match) => {
      if (match[0].includes(' ')) {
        errors.push(`spaces is not allowed in dynamic field ${match[0]}`);
      }
      if (!givendynamicFieldValues.includes(match[1])) {
        errors.push(`${match[0]} doesn't match any of the dynamic fields`);
      }
    });
    return errors;
  };

  const globalDynamicFields = computed(() => {
    return {
      resume: [{ title: 'Original cv', value: 'original_cv' }],
      recruitment: [
        { title: 'Organization', value: 'org' },
        { title: 'Company', value: 'company' },
        { title: 'Business unit', value: 'business_unit' },
        { title: 'Department', value: 'department' }
      ],
      applicant: [
        { title: 'Candidate Id', value: 'candidate_id' },
        { title: 'First name', value: 'first_name' },
        { title: 'Last name', value: 'last_name' },
        { title: 'gender', value: 'gender' },
        { title: 'Location', value: 'candidate_location' },
        { title: 'Description', value: 'candidate_description' },
        { title: 'Address', value: 'candidate_address' },
        { title: 'Email', value: 'candidate_email' },
        { title: 'Skype', value: 'skype' },
        { title: 'Other contact', value: 'other_contact' },
        { title: 'Current salary', value: 'current_salary' },
        { title: 'Expected salary', value: 'expected_salary' },
        { title: 'Total experience', value: 'total_experience' },
        { title: 'Country', value: 'country' },
        { title: 'Linkedin', value: 'linkedin' },
        { title: 'Negative reason', value: 'negative_reason' },
        { title: 'Candidate table', value: 'candidate_table' },
        { title: 'Nationality', value: 'applicant_nationality' },
        { title: 'Date of birth', value: 'applicant_dob' },
        {
          title: 'Latest education',
          value: 'applicant_latest_education'
        },
        { title: 'Applicant mobile', value: 'applicant_mobile' },
        { title: 'Career history', value: 'career_history' }
      ],
      job: [
        { title: 'Job Code', value: 'job_code' },
        { title: 'Postion', value: 'position' },
        { title: 'Job location', value: 'job_location' },
        { title: 'Vacancy', value: 'vacancy' },
        { title: 'Open date', value: 'open_date' },
        { title: 'Close date', value: 'close_date' },
        { title: 'Education type', value: 'education_type' },
        { title: 'Specialization', value: 'specialization' },
        { title: 'Currency', value: 'currency' },
        { title: 'Minimum Salary', value: 'minimum_salary' },
        { title: 'Maximum Salary', value: 'maximum_salary' },
        { title: 'Minimum Experience', value: 'minimum_exp_year' },
        { title: 'Maximum Experience', value: 'maximum_exp_year' },
        { title: 'Current Stage', value: 'current_stage' },
        { title: 'Current Status', value: 'current_status' },
        { title: 'Hiring Manager first name', value: 'hm_first_name' },
        { title: 'Hiring Manager last name', value: 'hm_last_name' },
        { title: 'Hiring Manager email', value: 'hm_email' }
      ],
      interview: [
        { title: 'Interview name', value: 'interview_name' },
        { title: 'Interview date', value: 'interview_date' },
        { title: 'Interview time', value: 'interview_time' },
        { title: 'Interview link', value: 'interview_link' },
        { title: 'Interview location', value: 'interview_location' },
        { title: 'Interview type', value: 'interview_type' },
        {
          title: 'Interviewer Firstname',
          value: 'interviewer_first_name'
        },
        {
          title: 'Interviewer Lastname',
          value: 'interviewer_last_name'
        },
        { title: 'Interviewer Email', value: 'interviewer_email' },
        { title: 'Interview Members', value: 'interview_members' },
        { title: 'Interview Location', value: 'interview_location' }
      ],
      offer: [
        { title: 'Designation', value: 'designation' },
        { title: 'Offer location', value: 'offer_location' },
        { title: 'Joining date', value: 'joining_date' },
        { title: 'Probation Period', value: 'probation_period' },
        { title: 'Salary', value: 'salary' },
        { title: 'Salary breakup', value: 'salary_breakup' },
        { title: 'Salary in words', value: 'salary_in_words' }
      ],
      system: [
        { title: 'Current Date', value: 'current_date' },
        { title: 'Email signature', value: 'email_signature' },
        { title: 'Page break(pdf only)', value: 'page_break' }
      ]
    };
  });

  const customFieldDynamicFields = computed(() => {
    let tags = [];
    customFields.value.forEach((customFieldTable) => {
      const fieldsToShowOnTemplate = (customFieldTable?.fields || []).filter(
        (field) => field?.show_on_email_templates
      );
      if (fieldsToShowOnTemplate?.length) {
        fieldsToShowOnTemplate.forEach((field) => {
          const tag = {
            title: `${field?.title} (${customFieldTable?.table})`,
            value: `${customFieldTable?.table}__${field?.key}`
          };
          tags = tags.concat([tag]);
        });
      }
    });
    return {
      custom_fields: tags
    };
  });

  return {
    validateDynamicFieldResult,
    globalDynamicFields,
    customFieldDynamicFields
  };
};

export default useHtmlEditor;
