<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useCommonUtils from '@/composables/app/useCommonUtils';

const route = computed(() => useRoute());
const router = useRouter();

const { stringCapitalize } = useCommonUtils();

const resolveRoutesByPath = () => {
	let path = route.value.path;
	if (!path || path === '/') {
		return [];
	}

	if (path.charAt(0) === '/') path = path.substring(1);

	return path.split('/').reduce((prev, current) => {
		const path =
			prev && prev.length
				? `${prev[prev.length - 1].path}/${current}`
				: `/${current}`;
		let resolved = router.resolve({ path });
		resolved = {
			...resolved,
			breadcrumbpath: current,
			exists: Boolean(
				resolved.matched &&
					resolved.matched.length &&
					resolved.name !== '404-not-found'
			),
			current: resolved.path === route.value.path,
		};
		return prev.concat(resolved);
	}, []);
};
const paths = computed(() => resolveRoutesByPath());
</script>

<template>
	<div id="breadcrumb" v-if="route.path && route.path !== '/'">
		<ul class="navbar-nav flex-row">
			<li>
				<div class="page-header">
					<nav class="breadcrumb-one" aria-label="breadcrumb">
						<ol class="breadcrumb">
							<li
								v-for="path in paths"
								:key="path.path"
								class="breadcrumb-item"
								:class="[path.current ? 'active' : '']"
							>
								<span v-if="path.current || !path.exists">
									{{ stringCapitalize(path.breadcrumbpath) }}
								</span>
								<RouterLink v-else :to="{ path: path.path }">
									{{ stringCapitalize(path.breadcrumbpath) }}
								</RouterLink>
							</li>
						</ol>
					</nav>
				</div>
			</li>
		</ul>
	</div>
</template>
