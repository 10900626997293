import NotificationComponent from '@/views/NotificationComponent.vue';
const defaultRoutes = [
	//dashboard
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/app-pages/Home.vue'),
	},
	{
		path: '/notification',
		name: 'notification',
		component: NotificationComponent,
	},
	{
		path: '/authorize',
		name: 'authorize',
		component: () =>
			import(
				/* webpackChunkName: "authorize" */ '@/views/settings/master/integrations/Authorization.vue'
			),
	},
	{
		path: '/feedback',
		name: 'feedback',
		component: () =>
			import(/* webpackChunkName: "feedback" */ '@/views/profile/Feedback.vue'),
	},

	{
		path: '/auth/login',
		name: 'login',
		component: () =>
			import(/* webpackChunkName: "auth-login" */ '@/views/auth/Login.vue'),
		meta: { layout: 'auth' },
	},

	{
		path: '/auth/password-recovery',
		name: 'password-recovery',
		component: () =>
			import(
				/* webpackChunkName: "auth-pass-recovery" */ '@/views/auth/PasswordRecovery.vue'
			),
		meta: { layout: 'auth' },
	},
];

export default defaultRoutes;
