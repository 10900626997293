const clientPortalRoutes = [
	{
		path: '/client-portal/resume-format',
		name: 'resume-format',
		component: () =>
			import('@/views/client-portal/resume-format/ResumeFormat.vue'),
	},
];

export default clientPortalRoutes;
