import { createStore } from 'vuex';
import i18n from '../i18n';
import {
	organizations,
	companies,
	businessUnits,
	departments,
	applicants,
} from '@/store/recruitments';
import auth from '@/store/auth/auth';
import utils from '@/store/utils/utils';
import { masterView, quota } from '@/store/master-settings';
import resumeSearch from '@/store/resume-search';
import notification from '@/store/notification';
import mailbox from '@/store/mailbox';
import settings from '@/store/settings';
import date from '@/store/date';

export default new createStore({
	state: {
		layout: 'app',
		is_show_sidebar: true,
		is_show_search: false,
		is_dark_mode: false,
		dark_mode: 'system',
		locale: null,
		menu_style: 'vertical',
		layout_style: 'full',
		countryList: [
			{ code: 'zh', name: 'Chinese' },
			{ code: 'da', name: 'Danish' },
			{ code: 'en', name: 'English' },
			{ code: 'fr', name: 'French' },
			{ code: 'de', name: 'German' },
			{ code: 'el', name: 'Greek' },
			{ code: 'hu', name: 'Hungarian' },
			{ code: 'it', name: 'Italian' },
			{ code: 'ja', name: 'Japanese' },
			{ code: 'pl', name: 'Polish' },
			{ code: 'pt', name: 'Portuguese' },
			{ code: 'ru', name: 'Russian' },
			{ code: 'es', name: 'Spanish' },
			{ code: 'sv', name: 'Swedish' },
			{ code: 'tr', name: 'Turkish' },
		],
		currentWindowWidth: 1960,
		storeDataLoaded: false,
	},
	mutations: {
		setLayout(state, payload) {
			state.layout = payload;
		},
		toggleSideBar(state, value) {
			state.is_show_sidebar = value;
		},
		toggleSearch(state, value) {
			state.is_show_search = value;
		},
		toggleLocale(state, value) {
			value = value || 'en';
			i18n.global.locale = value;
			localStorage.setItem('i18n_locale', value);
			state.locale = value;
		},

		toggleDarkMode(state, value) {
			//light|dark|system
			value = value || 'light';
			localStorage.setItem('dark_mode', value);
			state.dark_mode = value;
			if (value == 'light') {
				state.is_dark_mode = false;
			} else if (value == 'dark') {
				state.is_dark_mode = true;
			} else if (value == 'system') {
				if (
					window.matchMedia &&
					window.matchMedia('(prefers-color-scheme: dark)').matches
				) {
					state.is_dark_mode = true;
				} else {
					state.is_dark_mode = false;
				}
			}

			const body = document.querySelector('body');
			if (state.is_dark_mode) {
				body.classList.add('dark');
				body.classList.remove('light');
			} else {
				body.classList.add('light');
				body.classList.remove('dark');
			}
		},

		toggleMenuStyle(state, value) {
			//horizontal|vertical|collapsible-vertical
			value = value || '';
			localStorage.setItem('menu_style', value);
			state.menu_style = value;
			if (!value || value === 'vertical') {
				state.is_show_sidebar = true;
			} else if (value === 'collapsible-vertical') {
				state.is_show_sidebar = false;
			}
		},

		toggleLayoutStyle(state, value) {
			//boxed-layout|large-boxed-layout|full
			value = value || '';
			localStorage.setItem('layout_style', value);
			state.layout_style = value;
		},
		setWindowWidth: (state, payload) => (state.currentWindowWidth = payload),
		setStoreDataLoaded: (state, payload) => (state.storeDataLoaded = payload),
	},
	getters: {
		layout(state) {
			return state.layout;
		},
	},
	actions: {},
	modules: {
		utils: { namespaced: true, ...utils },
		auth: { namespaced: true, ...auth },
		recruitments: {
			namespaced: true,
			modules: {
				organizations: { namespaced: true, ...organizations },
				companies: { namespaced: true, ...companies },
				businessUnits: { namespaced: true, ...businessUnits },
				departments: { namespaced: true, ...departments },
				applicants: { namespaced: true, ...applicants },
			},
		},
		masterSettings: {
			namespaced: true,
			modules: {
				masterView: { namespaced: true, ...masterView },
				quota: { namespaced: true, ...quota },
			},
		},
		resumeSearch: {
			namespaced: true,
			...resumeSearch,
		},
		notification: {
			namespaced: true,
			...notification,
		},
		mailbox: {
			namespaced: true,
			...mailbox,
		},
		settings: {
			namespaced: true,
			...settings,
		},
		date: {
			namespaced: true,
			...date,
		},
	},
});
