<script setup>
import { computed } from 'vue';

import useCrud from '@/composables/app/useCrud';
import useToast from '@/composables/app/useToast';

const props = defineProps({
	file: {
		type: Object,
	},
});
const emit = defineEmits(['action-success']);

const { createData: onDuplicateCandidateUpdate } = useCrud(
	'/customer/bulk-resume/update-duplicate/'
);
const { showDefaultToast } = useToast();

const status = computed(() => {
	if (props.file?.invalid_cv) {
		return {
			state: 'invalid',
			text: 'File is not a valid cv',
			variant: 'warning',
		};
	}

	if (!props.file?.uploaded)
		return {
			state: 'before-upload',
			text: 'uploading...',
			variant: 'secondary',
		};

	if (!props.file?.parsed)
		return {
			state: 'before-parse',
			text: 'uploaded and parsing...',
			variant: 'secondary',
		};

	if (props.file?.candidate?.id)
		return {
			state: 'parsed-duplicate',
			text: `duplicated with ${props.file?.candidate?.first_name} ${props.file?.candidate?.last_name}(CC-${props.file?.candidate?.id})`,
			variant: 'danger',
		};

	return { state: 'parsed', text: 'parsed successfully', variant: 'success' };
});

const onDuplicateCandidateAction = async action => {
	const candidates = props.file?.candidate ? [props.file?.candidate] : [];
	if (!candidates?.length) return;
	let payload = {};

	if (action === 'discard') {
		payload = {
			...payload,
			discard: candidates.map(candidate => ({ id: candidate?.id })),
		};
	}

	if (['duplicate', 'override'].includes(action)) {
		payload = {
			...payload,
			save: candidates.map(candidate => ({ id: candidate?.id, action })),
		};
	}

	const response = await onDuplicateCandidateUpdate(payload);
	if (response) {
		if (response?.code == 0) {
			showDefaultToast('Success', 'success', response?.message);
			emit('action-success', {
				...props.file,
				candidate: null,
				parsed: true,
				timestamp: Date.now(),
			});
		} else {
			showDefaultToast('Error', 'danger', response?.message);
		}
	}
};
</script>

<template>
	<div
		class="w-100 p-3 rounded-1 shadow-small border bg-color-1 dark-bg-color-12 mb-2"
	>
		<div class="flex-between">
			<div class="d-flex flex-column">
				<div class="fw-bold text-14px text-semi-contrast">{{ file?.name }}</div>
				<div class="text-12px" :class="[`text-${status.variant}`]">
					{{ status.text }}
				</div>
			</div>

			<div class="ms-4 w-max-content flex-end flex-1">
				<!-- v-if="['before-upload', 'before-parse'].includes(status.state)" -->
				<FeatherIcon
					v-if="['before-upload', 'before-parse'].includes(status.state)"
					type="loader"
					size="14"
					animation="spin"
				/>

				<FeatherIcon
					v-else-if="status.state === 'parsed'"
					type="check"
					stroke-width="3px"
					size="14"
					class="text-success"
				/>

				<FeatherIcon
					v-else-if="status.state === 'invalid'"
					type="alert-circle"
					stroke-width="3px"
					size="14"
					class="text-warning"
				/>

				<template v-else>
					<button
						class="btn btn-success text-9px px-2 py-1"
						@click="onDuplicateCandidateAction('discard')"
					>
						Discard
					</button>
					<button
						class="btn btn-danger text-9px px-2 py-1 ms-1"
						@click="onDuplicateCandidateAction('override')"
					>
						Override
					</button>
					<button
						class="btn btn-primary text-9px px-2 py-1 ms-1"
						@click="onDuplicateCandidateAction('duplicate')"
					>
						Duplicate
					</button>
				</template>
			</div>
		</div>
	</div>
</template>
