const state = {
    depts: [],
    loading: false,
};

const mutations = {
    depts: (state, payload) => (state.depts = payload),
	loading: (state, payload) => (state.loading = payload)
};

export default { state, mutations };
