import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import axios from 'axios';

const useOrganization = () => {
	const store = useStore();
	const route = useRoute();
	const routeQuery = computed(() => route.query);

	const getAllOrganizations = async (queries = undefined) => {
		try {
			store.commit('recruitments/organizations/loading', true);

			queries = queries
				? { ...routeQuery.value, ...queries }
				: routeQuery.value;

			const query = '?' + new URLSearchParams(queries).toString();

			const response = await axios.get(`/customer/org/${query}`);
			if (response.data) {
				store.commit('recruitments/organizations/orgs', response.data);
				store.commit('recruitments/organizations/loading', false);
			}
			store.commit('recruitments/organizations/loading', false);
		} catch (error) {
			store.commit('recruitments/organizations/orgs', []);
			store.commit('recruitments/organizations/loading', false);
		}
	};

	const createOrganization = async params => {
		try {
			const response = await axios.post('/customer/org/', params, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data) {
				await getAllOrganizations();
				return response.data;
			}
		} catch (error) {
			return null;
		}
	};

	const updateOrganization = async (params, id) => {
		try {
			const response = await axios.patch(`/customer/org/${id}/`, params, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data) {
				await getAllOrganizations();
				return true;
			}
			return false;
		} catch (error) {
			return false;
			// do something with error
		}
	};

	const getOrganizationFiles = async orgId => {
		try {
			const response = await axios.get(`/customer/org-file/?org_id=${orgId}`);
			if (response.data) {
				return response.data;
			}
			return [];
		} catch (error) {
			return [];
		}
	};

	const deleteOrganizationFile = async fileId => {
		try {
			const response = await axios.delete(`/customer/org-file/${fileId}/`);
			if ([200, 201, 204].includes(response.status)) return true;
			return false;
		} catch (error) {
			return false;
		}
	};

	return {
		getAllOrganizations,
		createOrganization,
		updateOrganization,
		getOrganizationFiles,
		deleteOrganizationFile,
	};
};

export default useOrganization;
