import axios from 'axios';
import useEnv from '@/composables/app/useEnv';
import useToast from '@/composables/app/useToast';

export default {
	install: app => {
		const { serverProductionUrl } = useEnv();
		const { showDefaultToast } = useToast();
		const router = app?.config?.globalProperties?.$router;
		const store = app?.config?.globalProperties?.$store;

		let sessionExpiredNotificationShowed = false;

		axios.defaults.baseURL = `${serverProductionUrl}/api/v1`;
		axios.defaults.withCredentials = true;

		axios.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				if (
					error?.response?.status === 401 &&
					error?.response?.data?.status === 'another_session_initiated'
				) {
					if (
						!sessionExpiredNotificationShowed &&
						typeof router?.push === 'function'
					) {
						showDefaultToast(
							'Login session ended',
							'danger',
							'Another user logged in for same account'
						);

						sessionExpiredNotificationShowed = true;

						if (typeof router?.push === 'function')
							router?.push({ name: 'login' });
						store.commit('auth/setAuthUser', null);

						setTimeout(() => {
							sessionExpiredNotificationShowed = false;
						}, 30000);
					}
				}
				// console.log(router);
				return error;
			}
		);
	},
};
