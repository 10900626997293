import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import axios from 'axios';

const useDepartment = () => {
	const store = useStore();
	const route = useRoute();
	const routeQuery = computed(() => route.query);

	const getAllDepartments = async (queries = undefined) => {
		try {
			store.commit('recruitments/departments/loading', true);

			queries = queries
				? { ...routeQuery.value, ...queries }
				: routeQuery.value;

			const query = '?' + new URLSearchParams(queries).toString();

			const response = await axios.get(`/customer/department/${query}`);
			if (response.data) {
				store.commit('recruitments/departments/depts', response.data);
				store.commit('recruitments/departments/loading', false);
			}
			store.commit('recruitments/departments/loading', false);
		} catch (error) {
			store.commit('recruitments/departments/depts', []);
			store.commit('recruitments/departments/loading', false);
		}
	};

	const createDepartment = async params => {
		try {
			const response = await axios.post('/customer/department/', params, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data) {
				getAllDepartments();
				return response.data;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

	const updateDepartment = async (params, id) => {
		try {
			const response = await axios.patch(
				`/customer/department/${id}/`,
				params,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			if (response.data) {
				getAllDepartments();
				return true;
			}
			return false;
		} catch (error) {
			return false;
			// do something with error
		}
	};

	const getDepartmentFiles = async deptId => {
		try {
			const response = await axios.get(
				`/customer/dept-file/?dept_id=${deptId}`
			);
			if (response.data) {
				return response.data;
			}
			return [];
		} catch (error) {
			return [];
		}
	};

	const deleteDepartmentFile = async fileId => {
		try {
			const response = await axios.delete(`/customer/dept-file/${fileId}/`);
			if ([200, 201, 204].includes(response.status)) return true;
			return false;
		} catch (error) {
			return false;
		}
	};

	return {
		getAllDepartments,
		createDepartment,
		updateDepartment,
		getDepartmentFiles,
		deleteDepartmentFile,
	};
};

export default useDepartment;
