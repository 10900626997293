const useBootstrap = () => {
	const initPopover = (parentSelector = null) => {
		var popoverTriggerList = [].slice.call(
			document.querySelectorAll(
				parentSelector
					? `${parentSelector} [data-bs-toggle="popover"]`
					: '[data-bs-toggle="popover"]'
			)
		);
		return popoverTriggerList.map(function (popoverTriggerEl) {
			return new window.bootstrap.Popover(popoverTriggerEl);
		});
	};

	const destroyPopovers = popovers => {
		if (!popovers) return;
		if (Array.isArray(popovers)) {
			popovers.forEach(popover => popover.dispose());
		} else {
			popovers.dispose();
		}
	};

	const initModal = cssSelector => {
		if (typeof cssSelector === 'string') {
			return new window.bootstrap.Modal(document.querySelector(cssSelector));
		}
		if (Array.isArray(cssSelector)) {
			return cssSelector.reduce((result, selector) => {
				return {
					...result,
					[selector]: new window.bootstrap.Modal(
						document.querySelector(selector)
					),
				};
			}, {});
		}
	};

	const toggleModal = modal => modal.toggle();
	const showModal = modal => modal.show();
	const hideModal = modal => modal.hide();

	const initCollapse = (cssSelector, options = { toggle: false }) => {
		const collapseEl = document.querySelector(cssSelector);
		if (collapseEl) return new window.bootstrap.Collapse(collapseEl, options);
		return null;
	};

	const toggleCollapse = collapse => collapse && collapse.toggle();
	const showCollapse = collapse => collapse && collapse.show();
	const hideCollapse = collapse => collapse && collapse.hide();

	return {
		initPopover,
		initModal,
		toggleModal,
		showModal,
		hideModal,
		destroyPopovers,
		initCollapse,
		toggleCollapse,
		showCollapse,
		hideCollapse,
	};
};

export default useBootstrap;
