import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import axios from 'axios';

const useCandidate = () => {
	const store = useStore();
	const route = useRoute();
	const routeQuery = computed(() => route.query);

	const getAllCandidates = async (queries = undefined) => {
		try {
			store.commit('recruitments/candidates/loading', true);

			queries = queries
				? { ...routeQuery.value, ...queries }
				: routeQuery.value;

			const query = '?' + new URLSearchParams(queries).toString();

			const response = await axios.get(`/customer/candidate/${query}`);
			if (response.data) {
				// take ongoing on first place
				for (const element of response.data) {
					// element.skills = [
					// 	{
					// 		skill: {
					// 			id: 1,
					// 			name: 'Java Script',
					// 			team_id: 1,
					// 			added_by: null,
					// 			updated_by: null,
					// 		},
					// 		proficiency: 'Beginner',
					// 	},
					// 	{
					// 		skill: {
					// 			id: 2,
					// 			name: 'Python',
					// 			team_id: 1,
					// 			added_by: null,
					// 			updated_by: null,
					// 		},
					// 		proficiency: 'Expert',
					// 	},
					// ];
					// element.languages = [
					// 	{
					// 		language: {
					// 			id: 1,
					// 			name: 'Bangla',
					// 			team_id: 1,
					// 			added_by: null,
					// 			updated_by: null,
					// 		},
					// 		proficiency: 'Beginner',
					// 	},
					// 	{
					// 		language: {
					// 			id: 2,
					// 			name: 'English',
					// 			team_id: 1,
					// 			added_by: null,
					// 			updated_by: null,
					// 		},
					// 		proficiency: 'Expert',
					// 	},
					// ];
					
					if (element.education.length > 0) {
						const onGoing = element.education.find(x => x.completed === false);
						if (onGoing) {
							const education = element.education.filter(x => x !== onGoing);
							education.unshift(onGoing);
							element.education = education;
						}
					}
				}
				store.commit('recruitments/candidates/candidates', response.data);
				store.commit('recruitments/candidates/loading', false);
			}
			store.commit('recruitments/candidates/loading', false);
		} catch (error) {
			store.commit('recruitments/candidates/candidates', []);
			store.commit('recruitments/candidates/loading', false);
		}
	};

	const createCandidate = async params => {
		try {
			const response = await axios.post('/customer/candidate/', params, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data) {
				getAllCandidates();
				return response.data;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

	const updateCandidate = async (params, id) => {
		try {
			const response = await axios.patch(`/customer/candidate/${id}/`, params, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data) {
				getAllCandidates();
				return true;
			}
			return false;
		} catch (error) {
			return false;
			// do something with error
		}
	};
	const getGenericSkillsList = () => {
		return new Promise(async function (resolve, reject) {
			const response = await axios.get(`/customer/candidate-skill/`);
			if (response.data) {
				resolve(response.data);
			} else {
				resolve([])
			}
			// resolve([{id:1,name:'Java Script'},{id:2,name:'Python'}])
		});
	};
	const getGenericLanguagesList = () => {
		return new Promise(async function (resolve, reject) {
			const response = await axios.get(`/customer/candidate-language/`);
			if (response.data) {
				resolve(response.data);
			} else {
				resolve([])
			}
			// resolve([
			// 	{ id: 1, name: 'English' },
			// 	{ id: 2, name: 'Arabic' },
			// ]);
		});
	};
	const getGenericSkillsProficiencyList = () => {
		return new Promise(async function (resolve, reject) {
			// const response = await axios.get(`/customer/candidate-language/`);
			// if (response.data) {
			// 	return response.data;
			// } else {
			// 	return [];
			// }
			resolve([
				{ id: 1, name: 'Beginner' },
				{ id: 2, name: 'Intermediate' },
				{ id: 2, name: 'Expert' },
			]);
		});
	};
	const getGenericLanguagesProficiencyList = () => {
		return new Promise(async function (resolve, reject) {
			// const response = await axios.get(`/customer/candidate-language-proficiency/`);
			// if (response.data) {
			// 	return response.data;
			// } else {
			// 	return [];
			// }
			resolve([
				{ id: 1, name: 'Beginner' },
				{ id: 2, name: 'Intermediate' },
				{ id: 2, name: 'Expert' },
			]);
		});
	};

	return {
		getAllCandidates,
		createCandidate,
		updateCandidate,
		getGenericSkillsList,
		getGenericSkillsProficiencyList,
		getGenericLanguagesList,
		getGenericLanguagesProficiencyList,
	};
};

export default useCandidate;
