// import { isToday } from '@core/utils/utils';
import { subMinutes } from 'date-fns';
import papa from 'papaparse';
import { Parser } from '@json2csv/plainjs';

const useCommonUtils = () => {
  // take first characters of a string
  const fitstCharsOfString = (string) => {
    if (typeof string !== 'string') {
      return '';
    }
    string = string.split(' ');
    if (string.length > 1) {
      return string[0].substring(0, 1) + string[1].substring(0, 1);
    } else {
      return string[0].substring(0, 2);
    }
  };

  // get full first name & first char of last name
  const shortName = (name) => {
    if (typeof name !== 'string') {
      return '';
    }
    name = name.split(' ');
    if (name.length > 1) {
      return (
        stringCapitalize(name[0]) + ' ' + upper(name[1].substring(0, 1)) + '.'
      );
    } else {
      return name[0];
    }
  };

  // check if a object is empty
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  // strip last character if matechs
  const stripLastChars = (str, lastChars) => {
    const length = -lastChars?.length;
    if (str.slice(length) === lastChars) return str.slice(0, length);
    return str;
  };

  // check if two objects are equals
  // if object has methods or dom elements this method will not work
  const isEqualObject = (object1, object2) => {
    return JSON.stringify(object1) === JSON.stringify(object2);
  };

  // array of null elements
  const arrayOfNullElements = (array) => array.every((element) => !element);

  // generate random string
  const generateRandomString = (length, includeNumbers = true) => {
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    if (includeNumbers) characters = `${characters}0123456789`;

    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  //generate random number
  const generateRandomNumber = (digit = 6) => {
    return parseInt(Math.random() * Math.pow(10, digit - 1));
  };

  const randomNumberWithinRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  // capitalize first character of a string
  const stringCapitalize = (string) =>
    string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();

  // capilatie first character of a text(capilaize all invidual words)
  const textCapitalize = (string) => {
    if (!string) return '';
    const capitalize = string.split(' ').reduce((r, word) => {
      return `${r} ${stringCapitalize(word)}`;
    }, '');
    return capitalize.trim();
  };

  // upper and lower case string
  const upper = (string) => string.toUpperCase();
  const lower = (string) => string.toLowerCase();

  // filter html, css, js tags and returns inner text only
  const filterHtmlTags = (html) => {
    html = html.replace(/(<style[\w\W]+style>)/g, '');
    html = html.replace(/<[^>]*>?/gm, '');
    html = html.replace(/\&nbsp;/g, '');
    html = html.replace(/\s?\{[^}]+\}/g, '');
    html = html.replace(/\s\s+/g, ' ');

    return html;
  };

  // return a random item from array
  const randomItemFromArray = (array) =>
    array[Math.floor(Math.random() * array.length)];

  // formatted time
  // const showDateTime = (
  // 	value,
  // 	toTimeForCurrentDay = true,
  // 	formatting = { month: 'short', day: 'numeric' },
  // 	timezone = true
  // ) => {
  // 	let date;
  // 	if (typeof value === 'string') {
  // 		date = new Date(value);
  // 	} else {
  // 		date = value;
  // 	}
  // 	if (!isValidDate(date)) return '';
  // 	if (timezone) {
  // 		const utcOffset = new Date().getTimezoneOffset();
  // 		date = subMinutes(new Date(value), parseInt(utcOffset));
  // 	}

  // 	if (toTimeForCurrentDay && isToday(date)) {
  // 		formatting = { hour: 'numeric', minute: 'numeric' };
  // 	}

  // 	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(date));
  // };

  // check if the date passed
  const isPastDate = (date) =>
    Boolean(Date.parse(date) - Date.parse(new Date()) < 0);

  // check if a date is a valid date object
  const isValidDate = (date) => date instanceof Date && !isNaN(date);

  // get last index of array
  const lastIndexOfArray = (array) => array.length && array.length - 1;

  // get file size
  const getFileSize = (size) => {
    size = parseInt(size);
    const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
    let i = 0;

    while (size > 900) {
      size /= 1024;
      i++;
    }
    return `${Math.floor(Math.round(size * 100) / 100)} ${fSExt[i]}`;
  };

  // set cursor to the end of content editable div
  // https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity
  const setEndOfContenteditable = (contentEditableElement) => {
    var range, selection;
    if (document.createRange) {
      //Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange(); //Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection(); //get the selection object (allows you to change selection)
      selection.removeAllRanges(); //remove any selections already made
      selection.addRange(range); //make the range you have just created the visible selection
    } else if (document.selection) {
      //IE 8 and lower
      range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
      range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      range.select(); //Select the range (make it the visible selection
    }
  };

  // get cursor position in contenteditable div
  const getCursorPosition = (editableDiv) => {
    var caretPos = 0,
      sel,
      range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() == editableDiv) {
        var tempEl = document.createElement('span');
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint('EndToEnd', range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  };

  const typedTextsWidth = (element, type = 'contentEditable') => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    if (type === 'contentEditable')
      return ctx.measureText(element.innerText).width;
    if (type === 'input') return ctx.measureText(element.value).width;
  };

  // is file is a image file
  const isImageFile = (mimeType) => {
    const arr = mimeType.split('/');
    return Boolean(arr[0] === 'image');
  };

  const isImageByFilename = (fileName) => {
    const imageExtensions = [
      '.png',
      '.jpg',
      '.jpeg',
      '.jfif',
      '.pjpeg',
      '.pjp',
      '.apng',
      '.avif',
      '.gif',
      '.webp'
    ];

    return imageExtensions.some((extension) => fileName?.endsWith(extension));
  };

  // is file is a video file
  const isVideoFile = (mimeType) => {
    const arr = mimeType.split('/');
    return Boolean(arr[0] === 'video');
  };

  const isPdfFile = (mimeType) => {
    const arr = mimeType.split('/');
    return Boolean(arr[1] && arr[1] === 'pdf');
  };

  const isDocFile = (mimeType) => {
    const types = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword'
    ];
    return types.includes(mimeType);
  };

  const isExcelFile = (mimeType) => {
    const types = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel'
    ];
    return types.includes(mimeType);
  };

  // convert hex code to rgb
  const hexToRgb = (hex, returnRYBObject = false) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) return hex;

    if (returnRYBObject) {
      return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      };
    }
    return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
      result[3],
      16
    )}`;
  };

  // return hex code from rgb
  const rgbToHex = (r, g, b) => {
    const componentToHex = (c) => {
      var hex = c.toString(16);
      return hex.length == 1 ? `0${hex}` : hex;
    };
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  };

  const getEncodedURI = (string) => encodeURI(string);

  const cssPixelToInt = (string) => parseInt(string.slice(0, -2));
  const intToCssPixel = (int) => `${int}px`;

  // return 20 char of long text
  const shortStr = (str) => {
    if (str == undefined || str == null) return str;
    if (typeof str == 'string') {
      if (str.length > 20) {
        return str.slice(0, 20) + '...';
      } else {
        return str;
      }
    }
    return str;
  };

  const isObject = (data) => {
    if (typeof data === 'object' && !Array.isArray(data) && data !== null)
      return true;
    return false;
  };

  const isArray = (data) => Array.isArray(data);

  const disableKeys = (event, keys = ['e', 'E', '+', '-']) => {
    if (!keys) return;
    keys.includes(event.key) && event.preventDefault();
  };

  const filterPropsFromObject = (object, filteringProps = []) => {
    if (!filteringProps || !filteringProps.length) return object;
    return Object.entries(object).reduce((fields, field) => {
      if (filteringProps.includes(field[0]))
        fields = { ...fields, [field[0]]: field[1] };
      return fields;
    }, {});
  };

  const omitPropsFromObject = (object, omittingProps = []) => {
    if (!omittingProps || !omittingProps.length) return object;
    return Object.entries(object).reduce((fields, field) => {
      if (!omittingProps.includes(field[0]))
        fields = { ...fields, [field[0]]: field[1] };
      return fields;
    }, {});
  };

  // filter out the props that has no value
  const filterNotValuesFromObject = (
    object,
    exceptions = {
      includeFalse: false,
      includeNull: false,
      includeEmptyArray: false
    }
  ) => {
    if (!isObject(object)) return object;

    const { includeFalse, includeNull, includeEmptyArray } = exceptions;

    return Object.entries(object).reduce((object, [key, value]) => {
      if (isArray(value)) {
        if (includeEmptyArray) return { ...object, [key]: value };
        else {
          if (value.length) return { ...object, [key]: value };
          else return { ...object };
        }
      }

      return value ||
        (includeFalse && value === false) ||
        (includeNull && value === null)
        ? { ...object, [key]: value }
        : { ...object };
    }, {});
  };

  const getComparedValue = (key, object1, object2) => {
    if (object1 && object1[key] !== undefined) return object1[key];
    else if (object2 && object2[key] !== undefined) return object2[key];
    return undefined;
  };

  const OldDownloadCsv = (
    items,
    omitKeys = [],
    filename = null,
    download = true
  ) => {
    return new Promise((resolve) => {
      const mappedItems = omitKeys?.length
        ? items.map((item) => omitPropsFromObject(item, omitKeys))
        : items;
      let stringKeyValuePairs = toKeyValuePair(mappedItems);
      stringKeyValuePairs = Object.keys(stringKeyValuePairs).reduce(
        (r, key) => {
          if (typeof stringKeyValuePairs[key] === 'string') {
            return { ...r, [key]: `"${stringKeyValuePairs[key]}"` };
          }
          return { ...r, [key]: stringKeyValuePairs[key] };
        },
        {}
      );

      const stringKeyValuePairKeysLength =
        Object.keys(stringKeyValuePairs)?.length || 0;

      let index = 0;
      let result = [];
      let temp = {};

      Object.keys(stringKeyValuePairs).forEach((k, i) => {
        const key = k.split('.');
        const keyIndex = parseInt(
          key.shift().replace('[', '').replace(']', '')
        );

        if (keyIndex === index) {
          temp = { ...temp, [key.join('.')]: stringKeyValuePairs[k] };
          if (i === stringKeyValuePairKeysLength - 1) {
            result.push({ ...temp });
          }
        } else {
          result.push({ ...temp });
          index = keyIndex;
          temp = {};
          temp = { ...temp, [key.join('.')]: stringKeyValuePairs[k] };
        }
      });

      const allKeys = result.reduce((r, item) => {
        const newKeys = Object.keys(item).filter((k) => !r.includes(k));
        return r.concat(newKeys);
      }, []);

      result = result.map((item) => {
        return allKeys.reduce((r, key) => {
          if (item[key] !== undefined) return { ...r, [key]: item[key] };
          return { ...r, [key]: null };
        }, {});
      });
      const csvData = [
        allKeys,
        ...result.map((r) =>
          allKeys.map((k) => (r[k] !== undefined ? r[k] : null))
        )
      ]
        .map((c) => c.join(','))
        .join('\n');

      if (download) {
        let link = document.createElement('a');
        link.id = 'download-csv';
        link.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' + encodeURIComponent(csvData)
        );
        link.setAttribute(
          'download',
          filename ? filename : `${generateRandomString(8, false)}.csv`
        );
        document.body.appendChild(link);

        const linkButton = document.querySelector('#download-csv');
        if (linkButton) {
          linkButton.click();
          linkButton.remove();
        }
      }

      resolve(csvData);
    });
  };
  const downloadCsv = (
    items,
    omitKeys = [],
    filename = null,
    download = true
  ) => {
    return new Promise(async (resolve, reject) => {
      const mappedItems = omitKeys?.length
        ? items.map((item) => omitPropsFromObject(item, omitKeys))
        : items;
      let csvData = null;
      try {
        const opts = {};
        const parser = new Parser(opts);
        csvData = await parser.parse(mappedItems);
      } catch (err) {
        reject(err);
        console.error(err);
      }
      if (download) {
        let link = document.createElement('a');
        link.id = 'download-csv';
        link.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' + encodeURIComponent(csvData)
        );
        link.setAttribute(
          'download',
          filename ? filename : `${generateRandomString(8, false)}.csv`
        );
        document.body.appendChild(link);

        const linkButton = document.querySelector('#download-csv');
        if (linkButton) {
          await linkButton.click();
          linkButton.remove();
        }
      }
      resolve(csvData);
    });
  };

  const toKeyValuePair = (data, prefix = '', result = {}) => {
    if (isArray(data)) {
      return data.reduce((r, k, i) => {
        const newKey = prefix ? `${prefix}.[${i}]` : `[${i}]`;
        return toKeyValuePair(k, newKey, r);
      }, result);
    } else if (isObject(data)) {
      return Object.keys(data).reduce((r, k) => {
        const newKey = prefix ? `${prefix}.${k}` : k;
        return toKeyValuePair(data[k], newKey, r);
      }, result);
    } else {
      const newKey = prefix ? prefix : `unique_${generateRandomNumber(4)}`;
      return { ...result, [newKey]: data };
    }
  };

  const csvStringToOriginalData = (file, dataProcessed = null) => {
    papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: function (results) {
        const result = results.data;
        const items = result.map((item, index) =>
          reconstructObjectFromKey(item, index)
        );
        if (dataProcessed && typeof dataProcessed === 'function')
          dataProcessed(items);
      }
    });
  };

  const reconstructObjectFromKey = (data, idx) => {
    const singleKeyData = Object.keys(data).reduce((r, key) => {
      if (key.split('.')?.length === 1) return { ...r, [key]: data[key] };
      return r;
    }, {});

    return Object.keys(data).reduce((r, objKey) => {
      let keys = objKey.split('.');
      if (keys?.length < 2) return r;
      keys = keys.map((key, index) => {
        const type =
          index === keys?.length - 1
            ? 'data'
            : keys[index + 1].includes('[') && keys[index + 1].includes(']')
            ? 'array'
            : 'object';
        return {
          key,
          type,
          value:
            key.includes('[') && key.includes(']')
              ? parseInt(key.replace('[', '').replace(']', ''))
              : key
        };
      });

      if (keys?.length === 2) {
        let item = r[keys[0].value]
          ? r[keys[0].value]
          : keys[0].type === 'array'
          ? []
          : {};

        item[keys[1].value] = data[objKey];
        return { ...r, [keys[0].value]: item };
      }

      if (keys?.length === 3) {
        let item = r[keys[0].value]
          ? r[keys[0].value]
          : keys[0].type === 'array'
          ? []
          : {};

        item[keys[1].value] =
          keys[1].type === 'object' && item[keys[1].value]
            ? { ...item[keys[1].value] }
            : keys[1].type === 'array' && item[keys[1].value]
            ? [...item[keys[1].value]]
            : keys[1].type === 'object'
            ? {}
            : keys[1].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value] = data[objKey];
        return { ...r, [keys[0].value]: item };
      }

      if (keys?.length === 4) {
        let item = r[keys[0].value]
          ? r[keys[0].value]
          : keys[0].type === 'array'
          ? []
          : {};

        item[keys[1].value] =
          keys[1].type === 'object' && item[keys[1].value]
            ? { ...item[keys[1].value] }
            : keys[1].type === 'array' && item[keys[1].value]
            ? [...item[keys[1].value]]
            : keys[1].type === 'object'
            ? {}
            : keys[1].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value] =
          keys[2].type === 'object' && item[keys[1].value][keys[2].value]
            ? { ...item[keys[1].value][keys[2].value] }
            : keys[2].type === 'array' && item[keys[1].value][keys[2].value]
            ? [...item[keys[1].value][keys[2].value]]
            : keys[2].type === 'object'
            ? {}
            : keys[2].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value][keys[3].value] = data[objKey];
        return { ...r, [keys[0].value]: item };
      }

      if (keys?.length === 5) {
        let item = r[keys[0].value]
          ? r[keys[0].value]
          : keys[0].type === 'array'
          ? []
          : {};

        item[keys[1].value] =
          keys[1].type === 'object' && item[keys[1].value]
            ? { ...item[keys[1].value] }
            : keys[1].type === 'array' && item[keys[1].value]
            ? [...item[keys[1].value]]
            : keys[1].type === 'object'
            ? {}
            : keys[1].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value] =
          keys[2].type === 'object' && item[keys[1].value][keys[2].value]
            ? { ...item[keys[1].value][keys[2].value] }
            : keys[2].type === 'array' && item[keys[1].value][keys[2].value]
            ? [...item[keys[1].value][keys[2].value]]
            : keys[2].type === 'object'
            ? {}
            : keys[2].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value][keys[3].value] =
          keys[3].type === 'object' &&
          item[keys[1].value][keys[2].value][keys[3].value]
            ? { ...item[keys[1].value][keys[2].value][keys[3].value] }
            : keys[3].type === 'array' &&
              item[keys[1].value][keys[2].value][keys[3].value]
            ? [...item[keys[1].value][keys[2].value][keys[3].value]]
            : keys[3].type === 'object'
            ? {}
            : keys[3].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value][keys[3].value][keys[4].value] =
          data[objKey];
        return { ...r, [keys[0].value]: item };
      }

      if (keys?.length === 6) {
        let item = r[keys[0].value]
          ? r[keys[0].value]
          : keys[0].type === 'array'
          ? []
          : {};

        item[keys[1].value] =
          keys[1].type === 'object' && item[keys[1].value]
            ? { ...item[keys[1].value] }
            : keys[1].type === 'array' && item[keys[1].value]
            ? [...item[keys[1].value]]
            : keys[1].type === 'object'
            ? {}
            : keys[1].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value] =
          keys[2].type === 'object' && item[keys[1].value][keys[2].value]
            ? { ...item[keys[1].value][keys[2].value] }
            : keys[2].type === 'array' && item[keys[1].value][keys[2].value]
            ? [...item[keys[1].value][keys[2].value]]
            : keys[2].type === 'object'
            ? {}
            : keys[2].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value][keys[3].value][keys[4].value] =
          keys[4].type === 'object' &&
          item[keys[1].value][keys[2].value][keys[3].value][keys[4].value]
            ? {
                ...item[keys[1].value][keys[2].value][keys[3].value][
                  keys[4].value
                ]
              }
            : keys[4].type === 'array' &&
              item[keys[1].value][keys[2].value][keys[3].value][keys[4].value]
            ? [
                ...item[keys[1].value][keys[2].value][keys[3].value][
                  keys[4].value
                ]
              ]
            : keys[4].type === 'object'
            ? {}
            : keys[4].type === 'array'
            ? []
            : {};

        item[keys[1].value][keys[2].value][keys[3].value][keys[4].value][
          keys[5].value
        ] = data[objKey];
        return { ...r, [keys[0].value]: item };
      }

      return r;
    }, singleKeyData);
  };

  const isObjectHasValue = (data) => {
    if (isArray(data)) {
      return data.every((arr) => isObjectHasValue(arr));
    } else if (isObject(data)) {
      return Object.keys(data).every((key) => isObjectHasValue(data[key]));
    } else return data !== null;
  };

  function deepCompareArray(priority, base, callback, arrayKey = 'id') {
    if (!isArray(priority) || !isArray(base)) {
      return isArray(priority)
        ? priority
        : isArray(base)
        ? item
        : priority || base;
    }

    if (
      priority.every((p) => !isObject(p) && !isArray(p)) &&
      base.every((b) => !isObject(b) && !isArray(b))
    ) {
      return priority;
    }

    return priority.map((item, index) => {
      if (
        callback &&
        typeof callback === 'function' &&
        typeof callback(
          'array',
          item,
          base.find((b) => b[arrayKey] == item[arrayKey]),
          index
        ) !== 'undefined'
      ) {
        return callback(
          item,
          base.find((b) => b[arrayKey] == item[arrayKey]),
          index
        );
      }

      const itemInBase = base.find((b) => b[arrayKey] == item[arrayKey]);
      if (isArray(item) && isArray(itemInBase)) {
        return deepCompareArray(item, itemInBase, callback, arrayKey);
      } else if (isObject(item) && isObject(itemInBase)) {
        return deepCompare(item, itemInBase, callback, arrayKey);
      } else {
        return item;
      }
    });
  }

  function deepCompare(priority, base, callback, arrayKey = 'id') {
    const priorityKeys = Object.keys(priority);
    const baseKeys = Object.keys(base);
    const priorityKeysNotExistsInBase = priorityKeys.filter(
      (key) => !baseKeys.includes(key)
    );

    let compared = Object.keys(base).reduce((result, key) => {
      if (
        callback &&
        typeof callback === 'function' &&
        typeof callback(priority[key], base[key], key) !== 'undefined'
      ) {
        return {
          ...result,
          [key]: callback('object', priority[key], base[key], key)
        };
      }

      if (priority.hasOwnProperty(key)) {
        if (isObject(priority[key]) && isObject(base[key])) {
          return {
            ...result,
            [key]: deepCompare(priority[key], base[key], callback, arrayKey)
          };
        } else if (isArray(priority[key]) && isArray(base[key])) {
          return {
            ...result,
            [key]: deepCompareArray(
              priority[key],
              base[key],
              callback,
              arrayKey
            )
          };
        } else {
          return { ...result, [key]: priority[key] };
        }
      } else {
        return { ...result, [key]: base[key] };
      }
    }, {});
    if (priorityKeysNotExistsInBase.length) {
      priorityKeysNotExistsInBase.forEach((key) => {
        compared = { ...compared, [key]: priority[key] };
      });
    }
    return compared;
  }

  function deepDifferenceArray(
    next,
    previous,
    omitKeys = [],
    callback = null,
    arrayKey = 'id'
  ) {
    if (!next || !previous) return null;
    // if (!isArray(priority) || !isArray(base)) {
    // 	return isArray(priority)
    // 		? priority
    // 		: isArray(base)
    // 		? item
    // 		: priority || base;
    // }

    if (
      next.every((n) => !isObject(n) && !isArray(n)) &&
      previous.every((p) => !isObject(p) && !isArray(p))
    ) {
      if (JSON.stringify(previous) === JSON.stringify(next)) return null;
      return { diff: true, next, previous };
    }

    return next.map((item, index) => {
      if (
        callback &&
        typeof callback === 'function' &&
        typeof callback(
          item,
          previous.find((p) => p[arrayKey] == item[arrayKey]) ||
            previous[index],
          index
        ) !== 'undefined'
      ) {
        return callback(
          item,
          previous.find((p) => p[arrayKey] == item[arrayKey]) ||
            previous[index],
          index
        );
      }

      if (isArray(item)) {
        if (
          isArray(
            previous.find((p) => p[arrayKey] == item[arrayKey]) ||
              previous[index]
          )
        )
          return deepDifferenceArray(
            item,
            previous[index],
            omitKeys,
            callback,
            arrayKey
          );
      } else if (isObject(item)) {
        const itemInPrev =
          previous.find((p) => p[arrayKey] == item[arrayKey]) ||
          previous[index];

        if (isObject(itemInPrev))
          return deepDifference(item, itemInPrev, omitKeys, callback, arrayKey);
      } else {
        // return item;
        // if (typeof priority[index] !== 'undefined') return priority[index];
        // return item;
      }
    });
  }

  function deepDifference(
    next,
    previous,
    omitKeys = [],
    callback = null,
    arrayKey = 'id'
  ) {
    if (!previous || !next) return null;
    return Object.keys(next).reduce((result, key) => {
      if (
        callback &&
        typeof callback === 'function' &&
        typeof callback(next[key], previous[key], key) !== 'undefined'
      ) {
        return {
          ...result,
          [key]: callback(next[key], previous[key], key)
        };
      }

      if (omitKeys.includes(key)) return { ...result };

      if (previous.hasOwnProperty(key)) {
        if (isObject(next[key]) && isObject(previous[key])) {
          return {
            ...result,
            [key]: deepDifference(
              next[key],
              previous[key],
              omitKeys,
              callback,
              arrayKey
            )
          };
        } else if (isArray(next[key]) && isArray(previous[key])) {
          return {
            ...result,
            [key]: deepDifferenceArray(
              next[key],
              previous[key],
              omitKeys,
              callback,
              arrayKey
            )
          };
        } else {
          if (next[key] === previous[key]) {
            return { ...result };
          } else
            return {
              ...result,
              [key]: {
                diff: true,
                next: next[key],
                previous: previous[key]
              }
            };
        }
      } else {
        return {
          ...result,
          [key]: { diff: true, next: next[key], previous: undefined }
        };
      }
    }, {});
  }

  function deepEqual(obj1, obj2) {
    if (obj1 === null) obj1 = {};
    if (obj2 === null) obj2 = {};

    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== 'object' ||
      obj1 === null ||
      typeof obj2 !== 'object' ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  const compareArray = (first, second, key = 'slug') => {
    const itemKey = (item) =>
      !key ? item : key === 'id' ? parseInt(item[key]) : item[key];

    const firstItemKeys = key ? first.map((f) => itemKey(f)) : first;
    const secondItemKeys = key ? second.map((s) => itemKey(s)) : second;

    const added = first.filter((f) => !secondItemKeys.includes(itemKey(f)));
    const removed = second.filter((s) => !firstItemKeys.includes(itemKey(s)));
    if (first.length === second.length) {
    }

    return {
      isEqual: first.length === second.length,
      isFirstArrayBigger: first.length > second.length,
      added, // available in first but not in second,
      removed // available in first but not in second,
    };
  };

  const arrayFromNumber = (number, startFromZero = false) => {
    return Array.from({ length: number }, (_, i) =>
      startFromZero ? i : i + 1
    );
  };

  const isJsonParsable = (data) => {
    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      return false;
    }
  };

  // matches the length argument consecutive characters in two strings
  const partialMatchBetweenStrings = (string1, string2, length) => {
    for (let i = 0; i <= string1.length - length; i++) {
      const substring1 = string1.substr(i, length);
      for (let j = 0; j <= string2.length - length; j++) {
        const substring2 = string2.substr(j, length);
        if (substring1 === substring2) {
          return true;
        }
      }
    }
    return false;
  };

  const hasSpecialCharacters = (str) => {
    const regex = /[\W_]+/;
    return regex.test(str);
  };

  const isValidEmail = (email) => {
    if (!email) return false;
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const makeDelay = async (delay = 1000) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });
  };

  return {
    fitstCharsOfString,
    shortName,
    stripLastChars,
    isEmptyObject,
    isEqualObject,
    arrayOfNullElements,
    generateRandomString,
    generateRandomNumber,
    randomNumberWithinRange,
    stringCapitalize,
    textCapitalize,
    upper,
    lower,
    filterHtmlTags,
    randomItemFromArray,
    // showDateTime,
    isPastDate,
    isValidDate,
    lastIndexOfArray,
    setEndOfContenteditable,
    getCursorPosition,
    typedTextsWidth,
    getFileSize,
    isImageFile,
    isImageByFilename,
    isVideoFile,
    isPdfFile,
    isDocFile,
    isExcelFile,
    hexToRgb,
    rgbToHex,
    getEncodedURI,
    cssPixelToInt,
    intToCssPixel,
    shortStr,
    isObject,
    isArray,
    disableKeys,
    filterPropsFromObject,
    omitPropsFromObject,
    filterNotValuesFromObject,
    getComparedValue,
    downloadCsv,
    toKeyValuePair,
    csvStringToOriginalData,
    deepCompare,
    deepCompareArray,
    deepDifference,
    deepEqual,
    compareArray,
    arrayFromNumber,
    isJsonParsable,
    partialMatchBetweenStrings,
    hasSpecialCharacters,
    isValidEmail,
    makeDelay
  };
};

export default useCommonUtils;
