<script setup>
import { computed } from 'vue';

const props = defineProps({
	file: {
		type: Object,
	},
	hasStatus: {
		type: Function,
	},
});

const status = computed(() => {
	if (
		!props.file?.status ||
		props.hasStatus(props.file, ['success', 'completed'])
	) {
		return {
			state: 'completed',
			text: 'completed...',
			variant: 'success',
		};
	} else if (props.hasStatus(props.file, ['not_found', 'not-found'])) {
		return {
			state: 'failed',
			text: 'not found...',
			variant: 'danger',
		};
	} else if (props.hasStatus(props.file, ['not_matched', 'not-matched'])) {
		return {
			state: 'failed',
			text: 'not matched...',
			variant: 'danger',
		};
	} else {
		return {
			state: 'processing',
			text: 'uploading..',
			variant: 'secondary',
		};
	}
});
</script>

<template>
	<div
		class="w-100 p-3 rounded-1 shadow-small border bg-color-1 dark-bg-color-12 mb-2"
	>
		<div class="flex-between">
			<div class="d-flex flex-column">
				<div class="fw-bold text-14px text-semi-contrast">{{ file?.name }}</div>
				<div class="text-12px" :class="[`text-${status.variant}`]">
					{{ status.text }}
				</div>
			</div>

			<div class="ms-4 w-max-content flex-end flex-1">
				<!-- v-if="['before-upload', 'before-parse'].includes(status.state)" -->
				<FeatherIcon
					v-if="['processing'].includes(status.state)"
					type="loader"
					size="14"
					animation="spin"
				/>

				<FeatherIcon
					v-else-if="status.state === 'completed'"
					type="check"
					stroke-width="3px"
					size="14"
					class="text-success"
				/>

				<FeatherIcon
					v-else-if="status.state === 'failed'"
					type="x"
					stroke-width="3px"
					size="14"
					class="text-danger"
				/>
			</div>
		</div>
	</div>
</template>
