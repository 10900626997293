<script setup>
import { onMounted, ref, computed } from 'vue';
// import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Breadcrumb from '@/views/common/Breadcrumb.vue';
import useAuth from '@/composables/auth/useAuth';
import NotificationDropDown from '@/components/layout/NotificationDropDown';
import useEnv from '@/composables/app/useEnv';
import useToast from '@/composables/app/useToast';
import useAppConfig from '@/composables/app/useAppConfig';

const { showDefaultToast, showConfirmModal } = useToast();
const { websocketURL, serverUrl } = useEnv();
const store = useStore();
const router = useRouter();
const { currentBreakPoint } = useAppConfig();

const { authUser, changeRole } = useAuth();
const selectedLang = ref(null);
const countryList = ref(store.state.countryList);
// const i18n = useI18n();
const socketInstance = ref(null);

onMounted(() => {
	selectedLang.value = window.$appSetting.toggleLanguage();
	toggleMode();

	if (localStorage.getItem('user_id')) {
		const url = `${websocketURL}/${localStorage.getItem('user_id')}/`;
		socketInstance.value = new WebSocket(url);

		socketInstance.value.onmessage = function (event) {
			if (event?.data) {
				try {
					const eventData = JSON.parse(event?.data);
					if (eventData.message) {
						console.log(eventData?.message);
						store.commit(
							'notification/getNotificationRealtime',
							eventData.message
						);
						showDefaultToast('New Notification Arrived', 'success');
					}
				} catch (error) {
					console.log(error);
				}
			}
		};
	}
});

const toggleMode = mode => {
	window.$appSetting.toggleMode(mode);
};

// const changeLanguage = item => {
// 	selectedLang.value = item;
// 	window.$appSetting.toggleLanguage(item);

// 	i18n.locale.value = item.code;
// };

/**
 * ------------------------------------------------------------------------
 * logout
 * ------------------------------------------------------------------------
 */
const handleLogout = () => {
	if (socketInstance.value) {
		socketInstance.value.close(1000, 'User logged out');
	}
	store.commit('auth/accessToken', null);
	localStorage.removeItem('refresh');
	localStorage.clear();
	authUser.value = null;
	location.reload();
};

const onChangeRole = async role => {
	await changeRole(role);
};

const current_role = computed(() => {
	return store.state.auth.currentRole;
});

const profileInfo = computed(() => {
	return store.state.auth.profile;
});

const logoUrl = computed(() => {
	return profileInfo?.value.photo
		? `${serverUrl}${profileInfo?.value.photo}`
		: '/favicon.png';
});
</script>

<template>
	<div>
		<!--  BEGIN NAVBAR  -->
		<div class="header-container fixed-top">
			<header class="header navbar navbar-expand-sm f flex-between">
				<!-- logo -->
				<ul class="navbar-item theme-brand flex-row text-center">
					<li class="nav-item theme-logo">
						<router-link to="/">
							<img
								v-if="currentBreakPoint === 'xs'"
								src="/favicon.png"
								class="navbar-logo"
								alt="logo"
							/>

							<img
								v-else
								src="@/assets/images/logo.png"
								class="navbar-logo"
								alt="logo"
							/>
						</router-link>
					</li>
				</ul>

				<!-- menu items -->
				<div class="navbar-item flex-row ms-md-auto">
					<div class="dark-mode d-flex align-items-center">
						<a
							v-if="$store.state.dark_mode == 'light'"
							href="javascript:;"
							class="d-flex align-items-center"
							@click="toggleMode('dark')"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-sun"
							>
								<circle cx="12" cy="12" r="5"></circle>
								<line x1="12" y1="1" x2="12" y2="3"></line>
								<line x1="12" y1="21" x2="12" y2="23"></line>
								<line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
								<line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
								<line x1="1" y1="12" x2="3" y2="12"></line>
								<line x1="21" y1="12" x2="23" y2="12"></line>
								<line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
								<line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
							</svg>
							<span class="ms-2">Light</span>
						</a>
						<a
							v-if="$store.state.dark_mode == 'dark'"
							href="javascript:;"
							class="d-flex align-items-center"
							@click="toggleMode('system')"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-moon"
							>
								<path
									d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"
								></path>
							</svg>
							<span class="ms-2">Dark</span>
						</a>
						<a
							v-if="$store.state.dark_mode == 'system'"
							href="javascript:;"
							class="d-flex align-items-center"
							@click="toggleMode('light')"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-airplay"
							>
								<path
									d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"
								></path>
								<polygon points="12 15 17 21 7 21 12 15"></polygon>
							</svg>
							<span class="ms-2">System</span>
						</a>
					</div>

					<NotificationDropDown />

					<div class="dropdown nav-item user-profile-dropdown btn-group">
						<a
							href="javascript:;"
							id="ddluser"
							data-bs-toggle="dropdown"
							aria-expanded="false"
							class="btn dropdown-toggle btn-icon-only user nav-link"
						>
							<img :src="logoUrl" alt="avatar" />
						</a>
						<ul
							class="dropdown-menu dropdown-menu-right"
							aria-labelledby="ddluser"
						>
							<li v-if="authUser?.role?.length > 1" role="presentation">
								<a
									class="dropdown-item d-flex align-items-center cursor-pointer d-flex align-items-center"
								>
									<div class="me-2">Login as</div>

									<div>
										<div
											v-for="(item, index) in authUser?.role?.filter(
												x => x !== current_role
											)"
											class="badge bg-primary mx-1"
											:key="index"
											@click="onChangeRole(item)"
										>
											{{ item }}
										</div>
									</div>
								</a>
							</li>
							<li role="presentation">
								<router-link
									:to="{ name: 'user-profile' }"
									class="dropdown-item"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
										class="feather feather-user"
									>
										<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
										<circle cx="12" cy="7" r="4"></circle>
									</svg>
									Profile
								</router-link>
							</li>
							<li role="presentation">
								<router-link
									:disabled="true"
									event="click"
									to="/auth/login"
									class="dropdown-item"
									@click="handleLogout"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
										class="feather feather-log-out"
									>
										<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
										<polyline points="16 17 21 12 16 7"></polyline>
										<line x1="21" y1="12" x2="9" y2="12"></line>
									</svg>
									Sign Out
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</header>
		</div>

		<!--  END NAVBAR  -->
		<!--  BEGIN NAVBAR  -->
		<div class="sub-header-container">
			<header class="header navbar navbar-expand-sm">
				<a
					href="javascript:void(0);"
					class="sidebarCollapse"
					data-placement="bottom"
					@click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="feather feather-menu"
					>
						<line x1="3" y1="12" x2="21" y2="12"></line>
						<line x1="3" y1="6" x2="21" y2="6"></line>
						<line x1="3" y1="18" x2="21" y2="18"></line>
					</svg>
				</a>
				<Breadcrumb />
			</header>
		</div>
	</div>
</template>
<style scoped>
.role {
	visibility: visible !important;
	opacity: 1 !important;
}
</style>
