<script setup>
import { ref, onBeforeMount, watch, onMounted } from 'vue';
import emitter from 'tiny-emitter/instance';

import Modal from '@/views/common/Modal.vue';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import * as yup from 'yup';

import useCommonUtils from '@/composables/app/useCommonUtils';
import useCrud from '@/composables/app/useCrud';
import useHtmlEditor from '@/composables/app/useHtmlEditor';
import useFileUpload from '@/composables/recruitments/useFileUpload';
import useValidation from '@/composables/app/useValidation';
import useToast from '@/composables/app/useToast';

const { getComparedValue, getFileSize, filterPropsFromObject } =
	useCommonUtils();
const { getData: getEmailTemplates } = useCrud('/admin-app/mail-template/');
const { quillLightToolbar } = useHtmlEditor();

const {
	uploadedFiles,
	uploadByInput,
	uploadByDrop,
	isDragging,
	onDragEnter,
	onDragLeave,
} = useFileUpload('multiple-files');
const { createData: sendEmail } = useCrud('/send/email/');
const { runYupValidation } = useValidation();
const { showDefaultToast } = useToast();

const showModal = ref(false);

const initialModalData = {
	to: [],
	subject: '',
	email_template_id: null,
	body: '',
	allow_modify_to: false,
};

const emailTemplates = ref([]);

const modalData = ref({ ...initialModalData });
const modalElement = ref(null);

const submit_btn = ref(null);
emitter.on('send-email', async (data, callback) => {
	emailTemplates.value = await getEmailTemplates();
	if (data?.type) {
		emailTemplates.value = emailTemplates.value.filter(
			x => x?.category?.type === data?.type || x?.category?.type === 'all'
		);
	}
	modalData.value = Object.keys(initialModalData).reduce(
		(result, key) => ({
			...result,
			[key]: getComparedValue(key, data, initialModalData),
		}),
		{}
	);
	if (modalData.value.email_template_id) {
		const template = emailTemplates.value.find(
			template =>
				parseInt(template.id) === parseInt(modalData.value.email_template_id)
		);
		console.log();
		if (template) {
			modalData.value = {
				...modalData.value,
				email_template_id: template,
				body: template.body,
			};
			if (quill.value) quill.value.pasteHTML(template.body);
		} else {
			modalData.value = {
				...modalData.value,
				email_template_id: null,
			};
		}
	}
	showModal.value = true;
	setTimeout(() => {
		if (modalElement.value && callback && typeof callback === 'function') {
			modalElement.value.addEventListener('hidden.bs.modal', () => {
				callback(null);
			});
		}

		if (submit_btn.value) {
			submit_btn.value.onclick = async () => {
				await onEmailSend(callback);
			};
		}
	}, 200);
});

watch(
	() => showModal.value,
	() => {
		if (!showModal.value) {
			modalData.value = { ...initialModalData };
			if (quill.value) quill.value.pasteHTML('');
		}
	}
);

// onBeforeMount(async () => {
// 	// emailTemplates.value = await getEmailTemplates();
// });
// onMounted(async () => {
// 	// emailTemplates.value = await getEmailTemplates();
// });

const quill = ref(null);
const onTemplateSelect = option => {
	modalData.value = {
		...modalData.value,
		body: option.body,
	};
	setTimeout(() => {
		if (quill.value) quill.value.pasteHTML(option.body);
	}, 500);
};

const modalDataErrors = ref({});
const schema = yup.object().shape({
	to: yup
		.array()
		.of(yup.string().email())
		.min(1, 'minimum one email address required')
		.required(),
	subject: yup.string().required(),
	body: yup.string().required(),
});

const onEmailSend = async callback => {
	let filteredFields = {
		...filterPropsFromObject(modalData.value, [
			'to',
			'subject',
			'email_template_id',
			'body',
		]),
	};
	filteredFields = {
		...filteredFields,
		email_template_id: filteredFields.email_template_id
			? filteredFields.email_template_id?.id
			: null,
	};
	const { validated, errors } = await runYupValidation(schema, filteredFields);
	if (!validated) {
		modalDataErrors.value = errors;
		if (Object.keys(modalDataErrors.value).some(key => key.includes('to['))) {
			modalDataErrors.value = {
				...modalDataErrors.value,
				to: 'all email addresses must be valid',
			};
		}
		return;
	}
	modalDataErrors.value = {};
	const data = new FormData();
	data.append('data', JSON.stringify(filteredFields));
	if (uploadedFiles.value) {
		Array.from(uploadedFiles.value).forEach(file => {
			if (file instanceof File) data.append('files[]', file);
		});
	}

	const success = await sendEmail(data);
	if (success) {
		if (callback && typeof callback === 'function') callback(true);
		showDefaultToast('Success', 'Email sent');
		showModal.value = false;
	} else {
		showDefaultToast("Email couldn't send, please try again", 'danger');
		if (callback && typeof callback === 'function') callback(false);
	}
};
</script>

<template>
	<Modal
		v-model="showModal"
		size="lg"
		:centered="true"
		:scrollable="true"
		@ready="modalElement = $event.modalElement"
	>
		<div class="modal-header">
			<h5 class="modal-title" id="staticBackdropLabel">Send Email</h5>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="modal"
				aria-label="Close"
			></button>
		</div>

		<PerfectScrollbar class="modal-body">
			<div class="col-12 form-group">
				<label>To</label>
				<VSelect
					v-model="modalData.to"
					:options="[]"
					:taggable="modalData.allow_modify_to ? true : false"
					:multiple="true"
					:clearable="false"
					:disabled="modalData.allow_modify_to ? false : true"
				>
				</VSelect>
				<div
					v-if="modalDataErrors && modalDataErrors.to"
					class="w-100 text-12px text-danger mt-1"
				>
					{{ modalDataErrors.to }}
				</div>
			</div>

			<div class="col-12 form-group">
				<label>Subject</label>
				<input
					v-model="modalData.subject"
					class="form-control h-min-content py-2"
				/>
				<div
					v-if="modalDataErrors && modalDataErrors.subject"
					class="w-100 text-12px text-danger mt-1"
				>
					{{ modalDataErrors.subject }}
				</div>
			</div>

			<div class="col-12 form-group">
				<label>Email Template</label>
				<VSelect
					v-model="modalData.email_template_id"
					:options="emailTemplates"
					label="name"
					@option:selected="onTemplateSelect"
				>
				</VSelect>
			</div>

			<div class="col-12 form-group">
				<label>Message</label>
				<QuillEditor
					v-model:content="modalData.body"
					content-type="html"
					class="bg-body"
					:toolbar="quillLightToolbar"
					@ready="quill = $event"
				/>
				<div
					v-if="modalDataErrors && modalDataErrors.body"
					class="w-100 text-12px text-danger mt-1"
				>
					{{ modalDataErrors.body }}
				</div>
			</div>

			<!-- attachment container -->
			<div class="form-group w-100">
				<label>Attachments</label>
				<label
					for="drag-drop-file-attachment"
					class="drag-drop-file-attachment-container form-control py-2 h-min-content d-flex justify-content-center align-items-center py-3"
					@dragenter="onDragEnter"
					@dragleave="onDragLeave"
					@dragover.prevent
					@drop="uploadByDrop"
				>
					<Feather-icon v-if="isDragging" type="file-plus" size="64" />
					<div
						v-else-if="uploadedFiles && uploadedFiles.length"
						class="d-flex flex-row flex-wrap w-100"
					>
						<div
							v-for="(file, index) in uploadedFiles"
							:key="index"
							class="rounded-1 border d-flex text-11px bg-body mb-1 px-2 py-1 w-100"
						>
							{{ `${file.name} (${getFileSize(file.size)})` }}
						</div>
					</div>
					<span v-else class="fw-700">Upload or drop files</span>
				</label>
				<input
					id="drag-drop-file-attachment"
					type="file"
					class="drag-drop-file-attachment"
					@change="uploadByInput"
					multiple
				/>
			</div>

			<div class="w-100 mt-4 d-flex flex-row align-items-end">
				<button ref="submit_btn" class="btn btn-primary px-4 py-2 ms-auto">
					Send email
				</button>
			</div>
		</PerfectScrollbar>
	</Modal>
</template>

<style lang="scss" scoped>
@import '@/assets/base/_base.scss';

// drag drop attachment container
.drag-drop-file-attachment {
	width: 1px;
	height: 1px;
	display: none;
}
.drag-drop-file-attachment-container {
	// background: red;
	max-width: 100% !important;
	min-height: 168px !important;
	height: 100% !important;
	cursor: pointer;
	border: 2px dashed $m-color_6;

	img {
		width: 136px;
		height: 100%;
		border-radius: 4px;
	}
}

.uploaded-file {
	max-width: 100%;
	padding-left: 3px;
	padding-right: 3px;
}
</style>
