const logRoutes = [
    {
		path: '/activity-log',
		name: 'activity-log',
		component: () =>
			import(
				/* webpackChunkName: "activity-log" */ '@/views/log/ActivityLog.vue'
			),
	},
    {
		path: '/reports',
		name: 'reports',
		component: () =>
			import(
				/* webpackChunkName: "reports" */ '@/views/log/Reports.vue'
			),
	}
]
export default logRoutes;