<script setup>
import { ref, onMounted, watch, onBeforeUnmount, computed } from 'vue';
import useCommonUtils from '@/composables/app/useCommonUtils';
import useBootstrap from '@/composables/app/useBootstrap';

const props = defineProps({
	modelValue: {
		type: Boolean,
		default: () => false,
	},
	options: {
		type: [Object, undefined],
		default: () => ({}),
	},
	centered: {
		type: Boolean,
		default: () => false,
	},
	scrollable: {
		type: Boolean,
		default: () => false,
	},
	size: {
		type: String,
		default: () => '',
	},
	modalClass: {
		type: String,
		default: () => '',
	},
	containerClass: {
		type: String,
		default: () => '',
	},
});

const emit = defineEmits(['update:modelValue', 'ready', 'show', 'hide']);

const { generateRandomString } = useCommonUtils();
const { initModal, showModal, hideModal } = useBootstrap();

const modalId = ref(`modal-${generateRandomString(8, false)}`);

const modalOptions = computed(() => ({
	'data-bs-backdrop': props.options.backdrop
		? props.options.backdrop
		: undefined,
	'data-bs-keyboard':
		typeof props?.options?.keyboard !== 'undefined'
			? props.options.keyboard
			: true,
	tabindex: props.options.tabIndex ? props.options.tabIndex : -1,
	'aria-labelledby': props.options.ariaLabel
		? props.options.ariaLabel
		: modalId.value,
	'aria-hidden': props.options.ariaHidden ? props.options.ariaHidden : true,
}));

const modal = ref(null);
const modalEl = ref(null);

onMounted(() => {
	modal.value = initModal(`#${modalId.value}`);
	modalEl.value = document.getElementById(modalId.value);

	if (modalEl.value) {
		modalEl.value.addEventListener('hidden.bs.modal', () => {
			emit('update:modelValue', false);
		});
	}
	emit('ready', { modalInstance: modal.value, modalElement: modalEl.value });
});

onBeforeUnmount(() => {
	if (modalEl.value) {
		modalEl.value.removeEventListener('hidden.bs.modal', () => {
			emit('update:modelValue', false);
		});
	}
});

watch(
	() => props.modelValue,
	() => {
		if (modal.value) {
			if (props.modelValue) showModal(modal.value);
			else hideModal(modal.value);
		}
		props.modelValue ? emit('show') : emit('hide');
	}
);
</script>

<template>
	<Teleport to="body">
		<div
			:id="modalId"
			class="modal fade"
			:class="[containerClass]"
			v-bind="modalOptions"
		>
			<div
				class="modal-dialog"
				:class="[
					centered ? 'modal-dialog-centered' : '',
					scrollable ? 'modal-dialog-scrollable' : '',
					['xl', 'lg', 'sm'].includes(size) ? `modal-${size}` : '',
					modalClass ? modalClass : '',
				]"
			>
				<div class="modal-content">
					<slot>
						<div class="modal-header">
							<h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">...</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							<button type="button" class="btn btn-primary">Understood</button>
						</div>
					</slot>
				</div>
			</div>
		</div>
	</Teleport>
</template>
