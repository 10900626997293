import { computed } from 'vue';
import axios from 'axios';
import store from '@/store';
import { useRouter } from 'vue-router';
import jwtDecode from 'jwt-decode';
import useCommonUtils from '@/composables/app/useCommonUtils';

const useAuth = () => {
    const router = useRouter();
    const { omitPropsFromObject } = useCommonUtils();

    // check if a object is empty
    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const authUser = computed({
        get: () => store.state.auth.authUser,
        set: (data) => store.commit('auth/setAuthUser', data)
    });

    const authMember = computed(() => {
        if (!authUser.value) return null;
        const member = store.state.auth.members.find(
            (m) => parseInt(m?.user?.id) === parseInt(authUser.value.user_id)
        );
        return member ? member : null;
    });

    const authMemberName = computed(() => {
        if (!authMember.value) return null;

        return authMember.value?.user?.first_name
            ? `${authMember.value?.user?.first_name} ${authMember.value?.user?.last_name}`
            : authMember.value?.user?.username;
    });

    const isAdmin = computed(() => authMember.value?.current_role === 'Admin');

    const resolveUserName = (user) => {
        if (!user) return null;

        return user?.first_name
            ? `${user?.first_name} ${user?.last_name}`
            : user?.username;
    };

    const teamMembers = computed(() => store.state.auth.members);

    const getAllTeamMembers = async () => {
        try {
            store.commit('auth/loading', true);
            const response = await axios.get('/users/member/?all=true');
            if (response.data) {
                store.commit('auth/members', response.data);
                store.commit('auth/loading', false);
            }
            store.commit('auth/loading', false);
        } catch (error) {
            // console.log(error);
            store.commit('auth/loading', false);
        }
    };

    const createTeamMember = async (params) => {
        return await axios.post('/users/member/', params);
    };

    const updateTeamMember = async (member) => {
        try {
            const response = await axios.patch(
                `/users/member/${member.id}/`,
                member
            );
            if ((response.status = 200)) {
                await getAllTeamMembers();
                return true;
            }
            return false;
        } catch (error) {
            // console.log(error);
            return false;
        }
    };

    // Roles
    const getAllRoles = async () => {
        try {
            store.commit('auth/loading', true);
            const response = await axios.get('/users/role/');
            if (response.data) {
                store.commit('auth/roles', response.data);
                store.commit('auth/loading', false);
            }
            store.commit('auth/loading', false);
        } catch (error) {
            // console.log(error);
            store.commit('auth/loading', false);
        }
    };

    const verifyAccessToken = async () => {
        const { accessToken } = store.state.auth;
        try {
            if (!accessToken) return false;

            const response = await axios.post('/token/verify/', {
                token: accessToken
            });

            if (isEmptyObject(response.data) && response.status === 200)
                return true;
            return false;
        } catch (error) {
            return false;
        }
    };

    const refreshAccessToken = async () => {
        try {
            if (!localStorage.getItem('refresh')) return false;

            const response = await axios.post('/token/refresh/', {
                refresh: localStorage.getItem('refresh')
            });

            if (response.data && response.status === 200) {
                store.commit('auth/accessToken', response.data.access);
                localStorage.setItem('refresh', response.data.refresh);
                authUser.value = omitPropsFromObject(
                    parseJwt(response.data.access),
                    ['exp', 'iat', 'jti', 'token_type']
                );
                axios.defaults.headers.common['Authorization'] =
                    'Bearer ' + response.data.access;
                return true;
            }

            store.commit('auth/accessToken', null);
            localStorage.removeItem('refresh');
            authUser.value = null;
            if (typeof router?.push === 'function')
                router.push({ name: 'login' });
            return false;
        } catch (error) {
            store.commit('auth/accessToken', null);
            localStorage.removeItem('refresh');
            authUser.value = null;
            if (typeof router?.push === 'function')
                router.push({ name: 'login' });
            return false;
        }
    };

    function parseJwt(token) {
        try {
            if (!token) return null;
            return jwtDecode(token);
        } catch (error) {
            return null;
        }
    }
    const changeRole = async (role) => {
        try {
            const params = {
                role: role
            };
            const response = await axios.post('/users/change-role/', params);
            if (response?.data?.role) {
                store.commit('auth/setCurrentRole', response.data.role);
                router.push('/');
                refreshAccessToken();
            }
        } catch (error) {
            // console.log(error);
        }
    };
    const getCurrentRole = async () => {
        try {
            const response = await axios.get('/users/get-current-role/');
            if (response?.data?.role) {
                store.commit('auth/setCurrentRole', response.data.role);
                return response.data.role;
            }
        } catch (error) {
            return null;
            // console.log(error);
        }
    };

    const getCountriesList = async () => {
        try {
            store.commit('auth/loading', true);
            const response = await axios.get('/admin-app/country-list/');
            if (response.data) {
                store.commit('auth/setCountries', response.data);
            }
            store.commit('auth/loading', false);
        } catch (error) {
            // console.log(error);
            store.commit('auth/loading', false);
        }
    };

    const getNationalityList = async () => {
        try {
            store.commit('auth/loading', true);
            const response = await axios.get('/admin-app/nationality/');
            if (response.data) {
                store.commit('auth/setNationalities', response.data);
            }
            store.commit('auth/loading', false);
        } catch (error) {
            // console.log(error);
            store.commit('auth/loading', false);
        }
    };

    const roleMiddleWare = (role, currentRole) => {
        if (!role || !currentRole) return false;
        if (typeof role === 'string') role = [role];
        return role.includes(currentRole);
    };
    const resendInvitation = async (params) => {
        return await axios.post('/users/resend-activation/', params);
    };

    const verifyEmail = async (uid, token) => {
        return await axios.get(`/users/verify/${uid}/${token}/`);
    };

    const getProfileInformation = async () => {
        try {
            store.commit('auth/loading', true);
            const response = await axios.get('/users/profile/');
            if (response.data) {
                store.commit('auth/setProfile', response.data);
            }
            store.commit('auth/loading', false);
        } catch (error) {
            // console.log(error);
            store.commit('auth/loading', false);
        }
    };

    const hasAccess = (permission) => {
        let getters = store.getters;
        if (
            getters['auth/isAdminLoggedIn'] ||
            getters['auth/isSuperRecruiterLoggedIn']
        )
            return true;
        else if (getters['auth/isHiringManagerLoggedIn']) {
            if (
                permission?.name === 'job' ||
                permission?.name === 'applicant'
            ) {
                return true;
            }
            return false;
        } else if (getters['auth/isRecruiterLoggedIn']) {
            if (
                getters['auth/recruiterPermissions'] &&
                getters['auth/recruiterPermissions']?.length
            ) {
                const index =
                    permission?.option === 'create'
                        ? 1
                        : permission?.option === 'view'
                        ? 0
                        : 2;
                const hasPermissionAccess = getters[
                    'auth/recruiterPermissions'
                ]?.find(
                    (p) =>
                        p?.module_name === permission.name &&
                        p?.options[index]?.value === true
                );
                if (hasPermissionAccess) return true;
            }
        }
        return false;
    };

    axios.interceptors.response.use(
        (res) => res,
        (err) => {
            if (err?.response?.data?.code === 'user_inactive') {
                store.commit('auth/accessToken', null);
                localStorage.removeItem('refresh');
                authUser.value = null;
                router.push({ name: 'login' });
            }
            throw err;
        }
    );

    return {
        verifyAccessToken,
        refreshAccessToken,
        getAllTeamMembers,
        createTeamMember,
        updateTeamMember,
        getAllRoles,
        authUser,
        authMember,
        isAdmin,
        authMemberName,
        resolveUserName,
        teamMembers,
        parseJwt,
        changeRole,
        getCurrentRole,
        roleMiddleWare,
        resendInvitation,
        verifyEmail,
        getCountriesList,
        getProfileInformation,
        hasAccess,
        getNationalityList
    };
};

export default useAuth;
