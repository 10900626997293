import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import axios from 'axios';

const useBusinessUnit = () => {
	const store = useStore();
	const route = useRoute();
	const routeQuery = computed(() => route.query);

	const getAllBusinessUnits = async (queries = undefined) => {
		try {
			store.commit('recruitments/businessUnits/loading', true);

			queries = queries
				? { ...routeQuery.value, ...queries }
				: routeQuery.value;
			const query = '?' + new URLSearchParams(queries).toString();

			const response = await axios.get(`/customer/business-unit/${query}`);
			if (response.data) {
				store.commit('recruitments/businessUnits/units', response.data);
				store.commit('recruitments/businessUnits/loading', false);
			}
			store.commit('recruitments/businessUnits/loading', false);
		} catch (error) {
			store.commit('recruitments/businessUnits/units', []);
			store.commit('recruitments/businessUnits/loading', false);
		}
	};

	const createBusinessUnit = async params => {
		try {
			const response = await axios.post(`/customer/business-unit/`, params, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data) {
				getAllBusinessUnits();
				return response.data;
			}
			return null;
		} catch (error) {
			return null;
			// do something with error
		}
	};

	const updateBusinessUnit = async (params, id) => {
		try {
			const response = await axios.patch(
				`/customer/business-unit/${id}/`,
				params,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			if (response.data) {
				getAllBusinessUnits();
				return true;
			}
			return false;
		} catch (error) {
			return false;
			// do something with error
		}
	};

	const getBusinessUnitFiles = async buId => {
		try {
			const response = await axios.get(`/customer/unit-file/?unit_id=${buId}`);
			if (response.data) {
				return response.data;
			}
			return [];
		} catch (error) {
			return [];
		}
	};

	const deleteBusinessUnitFile = async fileId => {
		try {
			const response = await axios.delete(`/customer/unit-file/${fileId}/`);
			if ([200, 201, 204].includes(response.status)) return true;
			return false;
		} catch (error) {
			return false;
		}
	};

	return {
		getAllBusinessUnits,
		createBusinessUnit,
		updateBusinessUnit,
		getBusinessUnitFiles,
		deleteBusinessUnitFile,
	};
};

export default useBusinessUnit;
