import * as yup from 'yup';
const useValidation = () => {
	const runYupValidation = async (schema, validatingValues) => {
		try {
			const validated = await schema.validate(validatingValues, {
				abortEarly: false,
			});
			return {
				validated: true,
				data: validatingValues,
			};
		} catch (error) {
			if (error instanceof yup.ValidationError) {
				return {
					validated: false,
					errors: error.inner.reduce((prev, err, index) => {
						const { path, message } = err;
						if (!message) return prev;
						return { ...prev, [path ? path : index]: message };
					}, {}),
				};
			}
			console.log('error in runYupValidation', error);
			return {
				validated: false,
				errors: {},
			};
		}
	};

	return {
		runYupValidation,
	};
};

export default useValidation;
