<script setup>
import { ref, watch, computed } from 'vue';
import useFileUpload from '@/composables/recruitments/useFileUpload';
import useCommonUtils from '@/composables/app/useCommonUtils';
import useToast from '@/composables/app/useToast';
import { v4 } from 'uuid';

const props = defineProps({
	modelValue: {
		type: Array,
		default: () => [],
	},
	label: {
		type: [String, null],
		default: () => null,
	},
	image: {
		type: Boolean,
		default: () => false,
	},
	multiple: {
		type: Boolean,
		default: () => false,
	},
	folder: {
		type: Boolean,
		default: () => false,
	},
	accept: {
		type: [String, null],
		default: () => null,
	},
	containerClass: {
		type: String,
		default: () => '',
	},
	listFilter: {
		type: Function,
		default: files => files,
	},
	uploadFilter: {
		type: Function,
	},
	dropPlaceholder: {
		type: String,
		default: () => '',
	},
	dropSubplaceholder: {
		type: String,
		default: () => '',
	},
	imageWidth: {
		type: String,
		default: () => '136px',
	},
	imageHeight: {
		type: String,
		default: () => '136px',
	},
	reset: {
		type: Boolean,
		default: () => false,
	},
	previews: {
		type: [String, Array],
		default: () => [],
	},
});
const emit = defineEmits(['upload', 'update:model-value', 'preview-removed']);

const resolveUploadType = () => {
	if (props.multiple && props.image) return 'multiple-images';
	if (props.multiple && !props.image) return 'multiple-files';
	if (!props.multiple && props.image) return 'single-image';
	if (!props.multiple && !props.image) return 'single-file';
	return 'multiple-files';
};

const activePlaceholder = computed(() => {
	if (props.dropPlaceholder) return props.dropPlaceholder;

	let placeholder = '';
	if (props.folder) {
		placeholder = `${placeholder}Upload or drop folder`;
	} else {
		if (props.image) {
			placeholder = `${placeholder}Upload or drop image`;
			if (props.multiple) placeholder = `${placeholder}s`;
		} else {
			placeholder = `${placeholder}Upload or drop file`;
			if (props.multiple) placeholder = `${placeholder}s`;
		}
	}

	return placeholder;
});
const {
	onDragEnter,
	onDragLeave,
	uploadByDrop,
	uploadedFiles,
	uploadedImages,
	uploadedImagesUrl,
	uploadByInput,
	isDragging,
	filesUploadedAndProcessed,
	discardFile,
	discardImage,
} = useFileUpload(resolveUploadType(), props.folder);
const { getFileSize, isImageByFilename } = useCommonUtils();
const { showDefaultToast } = useToast();

const id = ref(`file-upload-input-${v4()}`);

if (props.image) uploadedImages.value = props.modelValue || [];
else uploadedFiles.value = props.modelValue || [];

watch(
	() => uploadedFiles.value,
	() => {
		if (!filesUploadedAndProcessed.value) return;
		if (props.uploadFilter && typeof props.uploadFilter === 'function') {
			const result = props.uploadFilter(uploadedFiles.value);

			if (Array.isArray(result)) uploadedFiles.value = result;
			else {
				if (
					result?.message &&
					uploadedFiles.value?.length !== result?.files?.length
				) {
					showDefaultToast(
						result?.variant === 'warning'
							? 'Warning'
							: result?.variant === 'success'
							? 'Success'
							: 'Error',
						result?.variant === 'warning'
							? 'warning'
							: result?.variant === 'success'
							? 'success'
							: 'danger',
						result.message
					);
				}
				uploadedFiles.value = result?.files || [];
			}
		}

		emit('upload', uploadedFiles.value);
		emit('update:model-value', uploadedFiles.value);
		filesUploadedAndProcessed.value = false;
	}
);
watch(
	() => uploadedImages.value,
	() => {
		if (!filesUploadedAndProcessed.value) return;
		if (props.uploadFilter && typeof props.uploadFilter === 'function') {
			const result = props.uploadFilter(uploadedImages.value);

			if (Array.isArray(result)) uploadedImages.value = result;
			else {
				if (
					result?.message &&
					uploadedImages.value?.length !== result?.files?.length
				) {
					showDefaultToast('Error', 'danger', result.message);
				}
				uploadedImages.value = Array.isArray(result?.files)
					? result.files
					: uploadedImages.value;
			}
		}

		uploadedImagesUrl.value = [];
		Array.from(uploadedImages.value).forEach(file => {
			uploadedImagesUrl.value.push(URL.createObjectURL(file));
		});

		emit('upload', uploadedImages.value);
		emit('update:model-value', uploadedImages.value);
		filesUploadedAndProcessed.value = false;
	}
);

watch(
	() => props.reset,
	() => {
		if (props.reset) {
			if (['single-file', 'multiple-files'].includes(resolveUploadType())) {
				uploadedFiles.value = [];
				emit('upload', uploadedFiles.value);
				emit('update:model-value', uploadedFiles.value);
			}

			if (['single-image', 'multiple-images'].includes(resolveUploadType())) {
				uploadedImages.value = [];
				uploadedImagesUrl.value = [];
				emit('upload', uploadedImages.value);
				emit('update:model-value', uploadedImages.value);
			}
		}
	}
);

const onFileDiscard = index => {
	discardFile(index);
	emit('upload', uploadedFiles.value);
	emit('update:model-value', uploadedFiles.value);
};

const onImageDiscard = index => {
	discardImage(index);
	emit('upload', uploadedImages.value);
	emit('update:model-value', uploadedImages.value);
};

const previewUrls = computed(() => {
	if (!props.previews) return [];
	if (typeof props.previews === 'string') return [props.previews];
	return props.previews;
});
const isImageTypePreview = computed(() => {
	return !previewUrls.value.some(url => !isImageByFilename(url));
});

const onPreviewRemove = (preview, index) => {
	emit('preview-removed', preview, index);
};
</script>

<template>
	<div class="form-group w-100 mb-0">
		<div class="flex-between w-100">
			<slot name="title">
				<label v-if="label">{{ label }}</label>
			</slot>

			<slot name="after_title"></slot>
		</div>

		<label
			:for="id"
			class="drag-drop-file-attachment-container form-control py-2 h-min-content d-flex justify-content-center align-items-center py-3"
			:class="[containerClass ? containerClass : '']"
			@dragenter="onDragEnter"
			@dragleave="onDragLeave"
			@dragover.prevent
			@drop="uploadByDrop"
		>
			<Feather-icon v-if="isDragging" type="file-plus" size="64" />
			<div
				v-else-if="
					listFilter(uploadedFiles) && listFilter(uploadedFiles)?.length
				"
				class="d-flex flex-row flex-wrap w-100"
			>
				<div
					v-for="(file, index) in listFilter(uploadedFiles)"
					:key="index"
					class="rounded-1 border flex-between text-11px bg-body mb-1 px-2 py-1 w-100"
				>
					<div>{{ `${file.name} (${getFileSize(file.size)})` }}</div>

					<FeatherIcon
						type="x"
						stroke-width="2px"
						size="16"
						class="cursor-pointer"
						@click.native.stop.prevent="onFileDiscard(index)"
					/>
				</div>
			</div>

			<div
				v-else-if="
					listFilter(uploadedImagesUrl) && listFilter(uploadedImagesUrl)?.length
				"
				class="flex-center flex-wrap w-100"
			>
				<div
					class="image-container me-2 mb-2 position-relative"
					v-for="(url, index) in listFilter(uploadedImagesUrl)"
					:key="url"
				>
					<img :src="url" class="w-100 h-100" />

					<div
						class="remove-image rounded-1 border bg-white cursor-pointer flex-center p-1 text-black"
						@click.native.stop.prevent="onImageDiscard(index)"
					>
						<FeatherIcon type="x" stroke-width="2px" size="16" />
					</div>
				</div>
			</div>

			<div v-else-if="previewUrls?.length" class="flex-center flex-wrap w-100">
				<template v-if="isImageTypePreview">
					<div
						class="image-container me-2 mb-2 position-relative"
						v-for="(preview, index) in previewUrls"
						:key="index"
					>
						<img :src="preview" class="w-100 h-100" />

						<div
							class="remove-image rounded-1 border bg-white cursor-pointer flex-center p-1 text-black"
							@click.native.stop.prevent="onPreviewRemove(preview, index)"
						>
							<FeatherIcon type="x" stroke-width="2px" size="16" />
						</div>
					</div>
				</template>

				<template v-else>
					<div
						v-for="(preview, index) in previewUrls"
						:key="index"
						class="rounded-1 border flex-between text-11px bg-body mb-1 px-2 py-1 w-100"
					>
						<div>{{ preview }}</div>

						<FeatherIcon
							type="x"
							stroke-width="2px"
							size="16"
							class="cursor-pointer"
							@click.native.stop.prevent="onPreviewRemove(preview, index)"
						/>
					</div>
				</template>
			</div>

			<div v-else class="w-100 px-0 px-md-5 flex-center">
				<span class="fw-700 text-center">{{ activePlaceholder }}</span>
				<span v-if="dropSubplaceholder" class="text-center text-12px">
					{{ dropSubplaceholder }}
				</span>
			</div>
		</label>

		<input
			:id="id"
			type="file"
			class="drag-drop-file-attachment"
			:webkitdirectory="folder"
			:directory="folder"
			:multiple="multiple || folder"
			:accept="accept ? accept : image ? 'image/*' : false"
			@change="uploadByInput"
		/>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/base/_base.scss';

// drag drop attachment container
.drag-drop-file-attachment {
	width: 1px;
	height: 1px;
	display: none;
}
.drag-drop-file-attachment-container {
	// background: red;
	max-width: 100% !important;
	min-height: 168px !important;
	height: 100% !important;
	cursor: pointer;
	border: 2px dashed $m-color_6;

	.image-container {
		width: v-bind('imageWidth');
		height: v-bind('imageHeight');
		border-radius: 4px;

		img {
			width: 100%;
			height: 100%;
			border-radius: 4px;
		}

		.remove-image {
			position: absolute;
			top: 8px;
			right: 8px;
			z-index: 2;
		}
	}
}
</style>
