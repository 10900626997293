import { ref } from 'vue';
const useDragDrop = () => {
	const dragCount = ref(0);
	const isDragging = ref(false);

	// entered the dragging zone
	const onDragEnter = event => {
		event.preventDefault();
		dragCount.value++;
		isDragging.value = true;
		return false;
	};

	// leave the dragging zone
	const onDragLeave = event => {
		event.preventDefault;
		dragCount.value--;
		if (dragCount.value <= 0) {
			isDragging.value = false;
		}
	};

	return {
		dragCount,
		isDragging,
		onDragEnter,
		onDragLeave,
	};
};

export default useDragDrop;
