import useCommonUtils from '@/composables/app/useCommonUtils';
import useCrud from '@/composables/app/useCrud';

const { isJsonParsable, omitPropsFromObject } = useCommonUtils();
const { updateData: onUpdateUserSettings } = useCrud('/customer/user-setting/');
const { updateData: onUpdateTeamSettings } = useCrud(
	'/customer/admin-setting/'
);

const jsonFields = ['settings'];
const parseData = payload => {
	return Object.entries(payload || {}).reduce((r, [key, val]) => {
		if (jsonFields.includes(key)) {
			return { ...r, [key]: isJsonParsable(val) ? JSON.parse(val) : val };
		}
		return { ...r, [key]: val };
	}, {});
};

const state = {
	userSettings: {},
	teamSettings: {},
};

const mutations = {
	// use to set the whole user settings at starting
	setUserSettings: (state, userSettingsRow) => {
		state.userSettings = parseData(userSettingsRow);
		// onUpdateUserSettings({ settings: null }, false);
	},

	// update user settings, only the settings key payload will be given
	updateUserSettings: (state, settings) => {
		if (!state?.userSettings?.id) return;
		state.userSettings = {
			...state.userSettings,
			settings,
		};

		onUpdateUserSettings({ settings: JSON.stringify(settings) }, false);
	},

	// use to set the whole team settings at starting
	setTeamSettings: (state, userSettingsRow) => {
		state.teamSettings = parseData(userSettingsRow);
		// onUpdateTeamSettings({ settings: null }, false);
	},

	// update team settings, only the settings key payload will be given
	updateTeamSettings: (state, settings) => {
		if (!state?.teamSettings?.id) return;
		state.teamSettings = {
			...state.teamSettings,
			settings,
		};

		onUpdateTeamSettings({ settings: JSON.stringify(settings) }, false);
	},
};

export default { state, mutations };
