import { ref } from 'vue';
import useDragDrop from '@/composables/app/useDragDrop';

const useFileUpload = (
	type = 'multiple-files',
	folder = false,
	appendFiles = true
) => {
	const { isDragging, dragCount, onDragEnter, onDragLeave } = useDragDrop();

	const filesUploadedAndProcessed = ref(false);

	// multiple files upload
	const uploadedFiles = ref([]);
	const uploadedFilesName = ref([]);

	// single file upload
	const uploadedImages = ref([]);
	const uploadedImagesName = ref([]);
	const uploadedImagesError = ref([]);
	const uploadedImagesUrl = ref([]);

	const uploadByInput = event => {
		filesUploadedAndProcessed.value = true;
		const files = event.target.files;
		if (['multiple-files', 'single-file'].includes(type))
			processUploadedFiles(files);
		if (['multiple-images', 'single-image'].includes(type))
			processUploadedImages(files);
	};

	const uploadByDrop = async event => {
		filesUploadedAndProcessed.value = true;
		event.preventDefault();
		event.stopPropagation();

		if (dragCount.value > 0) {
			dragCount.value--;
		}
		isDragging.value = false;

		if (folder) {
			const entries = await getAllFileEntries(event.dataTransfer.items);
			const filePromise = Array.from(entries).map(async entry => {
				const file = await getFile(entry);
				return file;
			});

			Promise.all(filePromise).then(files => {
				if (['multiple-files', 'single-file'].includes(type))
					processUploadedFiles(files);
				if (['multiple-images', 'single-image'].includes(type))
					processUploadedImages(files);
			});
		} else {
			const files = event.dataTransfer.files;
			if (['multiple-files', 'single-file'].includes(type))
				processUploadedFiles(files);
			if (['multiple-images', 'single-image'].includes(type))
				processUploadedImages(files);
		}
	};

	const processUploadedFiles = files => {
		if (files && files.length) {
			if (type === 'single-file') {
				files = Array.from(files).filter((file, index) => index === 0);
			} else {
				if (appendFiles) {
					files = (Array.from(uploadedFiles.value) || []).concat(
						Array.from(files)
					);
				}
			}

			uploadedFiles.value = files;
			uploadedFilesName.value = [];
			Array.from(files).forEach(file => {
				uploadedFilesName.value.push(file.name);
			});
		}
	};

	const processUploadedImages = files => {
		if (files && files.length) {
			if (type === 'single-image') {
				files = Array.from(files).filter((file, index) => index === 0);
			} else {
				if (appendFiles) {
					files = (Array.from(uploadedImages.value) || []).concat(
						Array.from(files)
					);
				}
			}

			uploadedImages.value = Array.from(files).filter(file =>
				file?.type.includes('image')
			);

			uploadedImagesName.value = [];
			uploadedImagesError.value = [];
			uploadedImagesUrl.value = [];

			Array.from(uploadedImages.value).forEach(file => {
				if (!file.type.includes('image')) {
					uploadedImagesError.value.push(`${file.name} is not an image`);
				}
				uploadedImagesUrl.value.push(URL.createObjectURL(file));
				uploadedImagesName.value.push(file.name);
			});
		}
	};

	const getAllFileEntries = async dataTransferItemList => {
		let fileEntries = [];
		// Use BFS to traverse entire directory/file structure
		let queue = [];
		// Unfortunately dataTransferItemList is not iterable i.e. no forEach
		for (let i = 0; i < dataTransferItemList.length; i++) {
			// Note webkitGetAsEntry a non-standard feature and may change
			// Usage is necessary for handling directories
			queue.push(dataTransferItemList[i].webkitGetAsEntry());
		}
		while (queue.length > 0) {
			let entry = queue.shift();
			if (entry.isFile) {
				fileEntries.push(entry);
			} else if (entry.isDirectory) {
				queue.push(...(await readAllDirectoryEntries(entry.createReader())));
			}
		}
		return fileEntries;
	};

	// Get all the entries (files or sub-directories) in a directory
	// by calling readEntries until it returns empty array
	const readAllDirectoryEntries = async directoryReader => {
		let entries = [];
		let readEntries = await readEntriesPromise(directoryReader);
		while (readEntries.length > 0) {
			entries.push(...readEntries);
			readEntries = await readEntriesPromise(directoryReader);
		}
		return entries;
	};

	// Wrap readEntries in a promise to make working with readEntries easier
	// readEntries will return only some of the entries in a directory
	// e.g. Chrome returns at most 100 entries at a time
	const readEntriesPromise = async directoryReader => {
		try {
			return await new Promise((resolve, reject) => {
				directoryReader.readEntries(resolve, reject);
			});
		} catch (err) {
			console.log(err);
		}
	};

	const getFile = async fileEntry => {
		try {
			return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
		} catch (err) {
			console.log(err);
		}
	};

	const discardFile = index => {
		if (index < 0) return;
		uploadedFiles.value = uploadedFiles.value.filter((_, idx) => idx !== index);
	};

	const discardImage = index => {
		if (index < 0) return;
		uploadedImages.value = uploadedImages.value.filter(
			(_, idx) => idx !== index
		);

		uploadedImagesUrl.value = [];
		Array.from(uploadedImages.value).forEach(file => {
			uploadedImagesUrl.value.push(URL.createObjectURL(file));
		});
	};

	return {
		isDragging,
		dragCount,
		onDragEnter,
		onDragLeave,
		filesUploadedAndProcessed,
		uploadedFiles,
		uploadedFilesName,
		uploadedImages,
		uploadedImagesError,
		uploadedImagesName,
		uploadedImagesUrl,
		uploadByInput,
		uploadByDrop,
		processUploadedFiles,
		discardFile,
		discardImage,
	};
};

export default useFileUpload;
