import { onMounted, onBeforeUnmount } from 'vue';
import useAppConfig from '@/composables/app/useAppConfig.js';

const useTableHeaderStickToTop = (options = {}) => {
    const { currentWindowWidth } = useAppConfig();
    const defaultOptions = {
        scrollEl: 'window',
        targetOffsetTop: ['header', '.sub-header-container'],
        offset: 0,
        stickySearchRow: false
    };
    let { scrollEl, targetOffsetTop, offset, stickySearchRow } = Object.assign(defaultOptions, options);

    // if (Array.isArray(targetOffsetTop)) {
    // 	setTimeout(() => {
    // 		targetOffsetTop = targetOffsetTop.reduce((r, selector) => {
    // 			const el = document.querySelector(selector);
    // 			if (!el) return r;
    // 			return r + el.clientHeight || 0;
    // 		}, 0);
    // 	}, 200);
    // }

    const setTargetOffesetTop = () => {
        if (Array.isArray(targetOffsetTop)) {
            return targetOffsetTop.reduce((r, selector) => {
                const el = document.querySelector(selector);
                if (!el) return r;
                return r + el.clientHeight || 0;
            }, 0);
        } else {
            return targetOffsetTop;
        }
    };

    const StickToTop = (el = null) => {
        if (currentWindowWidth.value <= 768) return;

        const tableHead = document.querySelector('.custom-table table thead tr');
        if (!tableHead) return;

        const allTh = tableHead.querySelectorAll('th');
        if (!allTh?.length) return;

        let searchRowTh = [];
        if (stickySearchRow) {
            const searchRow = document.querySelector('.custom-table table thead tr.VueTables__filters-row');
            searchRowTh = searchRow.querySelectorAll('th') || [];
        }

        const offsetTop = el ? el.scrollTop : window.scrollY;
        const top = offsetTop - targetOffsetTop - offset;

        if (offsetTop > targetOffsetTop) {
            [].forEach.call(allTh, (th) => {
                th.style.position = 'sticky';
                th.style.top = `${top}px`;
                th.style.zIndex = 57;
            });

            if (searchRowTh?.length) {
                [].forEach.call(searchRowTh, (th) => {
                    th.style.position = 'sticky';
                    th.style.top = `${top + tableHead?.clientHeight || 0}px`;
                    th.style.zIndex = 57;
                });
            }
        } else {
            [].forEach.call(allTh, (th) => {
                th.style.top = 0;
                th.style.zIndex = 'auto';
            });
            if (searchRowTh?.length) {
                [].forEach.call(searchRowTh, (th) => {
                    th.style.top = 0;
                    th.style.zIndex = 'auto';
                });
            }
        }
    };

    const rewatch = (newOptions) => {
        ({ scrollEl, targetOffsetTop, offset, stickySearchRow } = Object.assign(options, newOptions));
        targetOffsetTop = setTargetOffesetTop();
        StickToTop(scrollEl === 'window' ? null : document.querySelector(scrollEl));
    };

    onMounted(() => {
        targetOffsetTop = setTargetOffesetTop();
        if (scrollEl === 'window') {
            window.addEventListener('scroll', () => StickToTop(), false);
        } else {
            const el = document.querySelector(scrollEl);
            if (!el) return;
            el.addEventListener('scroll', () => StickToTop(el), false);
        }
    });

    onBeforeUnmount(() => {
        if (scrollEl === 'window') {
            window.removeEventListener('scroll', () => StickToTop(), false);
        } else {
            const el = document.querySelector(scrollEl);
            if (!el) return;
            el.removeEventListener('scroll', () => StickToTop(el), false);
        }
    });

    return {
        rewatch
    };
};

export default useTableHeaderStickToTop;
