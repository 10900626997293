let subscriptionRoutes = [
	{
		path: '/subscriptions',
		name: 'subscriptions',
		component: () =>
			import(
				/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/Subscriptions.vue'
			),
	},
	{
		path: '/pricing',
		name: 'pricing',
		component: () =>
			import(
				/* webpackChunkName: "pricing" */ '@/views/subscriptions/Pricing.vue'
			),
	},
	{
		path: '/billing',
		name: 'billing',
		component: () =>
			import(
				/* webpackChunkName: "billing" */ '@/views/subscriptions/Billing.vue'
			),
	},
];
subscriptionRoutes = [...subscriptionRoutes].map(route => ({
	...route,
	meta: { ...route.meta, role: ['Admin'] },
}));
export default subscriptionRoutes;
