<script setup>
	import { ref } from 'vue';
	import emitter from 'tiny-emitter/instance';
	import useCommonUtils from '@/composables/app/useCommonUtils';

	const { isObject, isEmptyObject } = useCommonUtils();

	const show = ref(false);
	const initialModalData = {
		message: 'Are you absolutely sure to delete?',
		warning:
			'This action cannot be undone. This will permanently delete the data',
		input: 'i-know-what-i-am-doing',
		buttonText: 'I understand the consequences, delete',
	};
	const setModalData = data => {
		if (!data || !isObject(data) || isEmptyObject(data))
			return initialModalData;
		const { message, warning, input, buttonText } = data;

		const mergedData = {
			...initialModalData,
			message: message ? message : initialModalData.message,
			warning: warning ? warning : initialModalData.warning,
			input: input ? input : initialModalData.input,
			buttonText: buttonText ? buttonText : initialModalData.buttonText,
		};

		return mergedData;
	};
	const modalData = ref(initialModalData);

	const input = ref(null);

	const input_el = ref(null);
	const button_el = ref(null);
	const close_button_el = ref(null);
	const modal_overlay = ref(null);

	emitter.on('show-text-input-confirm-modal', (data, callback) => {
		show.value = true;
		modalData.value = setModalData(data);

		setTimeout(() => {
			if (input_el.value) input_el.value.focus();

			if (button_el.value) {
				button_el.value.onclick = () => {
					if (input.value !== modalData.value.input) return;
					else {
						show.value = false;
						input.value = null;
						callback(true);
					}
				};
			}

			if (close_button_el.value) {
				close_button_el.value.onclick = () => {
					show.value = false;
					input.value = null;
					callback(false);
				};
			}

			if (modal_overlay.value) {
				modal_overlay.value.onclick = () => {
					show.value = false;
					input.value = null;
					callback(null);
				};
			}
		}, 100);
	});
</script>

<template>
	<Teleport to="body">
		<!-- overlay -->
		<div v-if="show" ref="modal_overlay" class="confirm-modal-overlay"></div>

		<!-- modal -->
		<Transition>
			<PerfectScrollbar
				v-if="show"
				:settings="{ maxScrollbarLength: 75 }"
				class="confirm-modal rounded border bg-card"
			>
				<div
					class="p-3 border-bottom d-flex flex-row justify-content-between align-items-center"
				>
					<div class="fw-bold text-warning">{{ modalData.message }}</div>

					<div ref="close_button_el" class="">
						<FeatherIcon type="x" size="16" class="cursor-pointer" />
					</div>
				</div>
				<div class="p-3 w-100">
					<div v-if="modalData.warning" class="w-100 mb-3">
						{{ modalData.warning }}
					</div>

					<div class="form-group w-100">
						<label>{{ `Please type '${modalData.input}' to confirm` }}</label>
						<input
							v-model="input"
							ref="input_el"
							class="form-control h-min-content py-2"
						/>
					</div>

					<div class="mt-3 w-100">
						<div
							ref="button_el"
							class="w-100 py-2 rounded-1 fw-bold d-flex justify-content-center align-items-center"
							:class="[
								input === modalData.input
									? ' text-white bg-danger cursor-pointer'
									: 'border text-danger cursor-not-allowed',
							]"
						>
							{{ modalData.buttonText }}
						</div>
					</div>
				</div>
			</PerfectScrollbar>
		</Transition>
	</Teleport>
</template>

<style lang="scss" scoped>
	@import '@/assets/base/_base.scss';

	.confirm-modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: #000;
		z-index: 55555 !important;
		opacity: 0.1 !important;
	}
	.confirm-modal {
		position: fixed;
		top: 20vh;
		left: 30%;
		width: 40%;
		max-height: 40vh;
		z-index: 55556;

		&.v-enter-from {
			opacity: 0;
			transform: scale(0.75);
		}
		&.v-leave-to {
			opacity: 0;
			transform: scale(0.75);
		}
		&.v-enter-active {
			transition: all 0.25s ease-out;
		}

		&.v-leave-active {
			transition: all 0.25s ease-out;
		}
	}

	.dark {
		.confirm-modal-overlay {
			background: $m-color_18;
			opacity: 0.5 !important;
		}
	}

	@media screen and (max-width: 992px) {
		.confirm-modal {
			width: 60%;
			left: 20%;
		}
	}

	@media screen and (max-width: 768px) {
		.confirm-modal {
			width: 80%;
			left: 10%;
		}
	}

	@media screen and (max-width: 576px) {
		.confirm-modal {
			width: 96%;
			left: 2%;
		}
	}
</style>
