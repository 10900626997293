export default {
	mounted: (el, { arg, modifiers }) => {
		if (!modifiers.mounted) return;
		setTimeout(() => {
			const toolbar = el.querySelector('.ck-editor__top');
			if (!toolbar) return;
			const stickyContent = toolbar.querySelector('.ck-sticky-panel__content');
			let offset = arg ? `${arg}px` : '0px';
			toolbar.style.position = 'sticky';
			toolbar.style.top = offset;
			if (stickyContent) {
				stickyContent.style.position = 'static';
				stickyContent.style.width = '100%';
			}
		}, 100);
	},
	updated: (el, { value, oldValue, arg }) => {
		if (typeof value === 'undefined' || value === oldValue) return;
		const toolbar = el.querySelector('.ck-editor__top');
		if (!toolbar) return;
		const stickyContent = toolbar.querySelector('.ck-sticky-panel__content');
		let offset = arg ? `${arg}px` : '0px';
		toolbar.style.position = 'sticky';
		toolbar.style.top = offset;
		if (stickyContent) {
			stickyContent.style.position = 'static';
			stickyContent.style.width = '100%';
		}
	},
};
