const state = {
    companies: [],
    loading: false
};

const mutations = {
    companies: (state, payload) => (state.companies = payload),
	loading: (state, payload) => (state.loading = payload)
};

export default { state, mutations };
