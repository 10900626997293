<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { v4 } from 'uuid';

const props = defineProps({
  // for positioning, should add class 'dropstart/dropend/dropup/dropdown at the component declaration
  // props not necessary

  maxHeight: {
    type: String,
    default: () => 'max-content'
  },
  maxWidth: {
    type: String,
    default: 'max-content'
  },
  minWidth: {
    type: String,
    default: 'min-content'
  },
  width: {
    type: String,
    default: 'auto'
  },
  height: {
    type: String,
    default: 'auto'
  },
  dropdownClass: {
    type: String,
    default: ''
  },
  tag: {
    type: String,
    default: () => 'ul'
  },
  offset: {
    type: [String, null],
    default: () => null
  },
  expanded: {
    type: Boolean,
    default: () => false
  }
});

const emit = defineEmits(['ready', 'show', 'hide']);

const id = ref(`dropdown-${v4()}`);
const ulId = ref(`dropdown-ul-${v4()}`);

const scrollbar = ref(null);

const dropdown_el = ref(null);
const dropdown = ref(null);

const dropdownShown = ref(false);

const preventWindowScroll = (e) => {
  e.preventDefault();
};
watch(
  () => dropdownShown.value,
  () => {
    if (dropdownShown.value) {
      window.addEventListener('scroll', preventWindowScroll, {
        passive: false
      });
      window.addEventListener('wheel', preventWindowScroll, { passive: false });
      window.addEventListener('touchmove', preventWindowScroll, {
        passive: false
      });
    } else {
      window.removeEventListener('scroll', preventWindowScroll);
      window.removeEventListener('wheel', preventWindowScroll);
      window.removeEventListener('touchmove', preventWindowScroll);
    }
  }
);

onMounted(() => {
  scrollbar.value = document.querySelector(`#${ulId.value} .ps__rail-y`);
  if (scrollbar.value) {
    scrollbar.value.addEventListener(
      'click',
      (e) => e.stopPropagation(),
      false
    );
  }

  if (dropdown_el.value) {
    dropdown.value = new window.bootstrap.Dropdown(dropdown_el.value);

    dropdown_el.value.addEventListener(
      'shown.bs.dropdown',
      (event) => {
        dropdownShown.value = true;
        emit('show', event);
      },
      false
    );
    dropdown_el.value.addEventListener(
      'hidden.bs.dropdown',
      (event) => {
        dropdownShown.value = false;
        emit('hide', event);
      },
      false
    );
  }
  emit('ready', dropdown.value, dropdown_el.value);
});

onBeforeUnmount(() => {
  if (scrollbar.value) {
    scrollbar.value.removeEventListener(
      'click',
      (e) => e.stopPropagation(),
      false
    );
  }
  if (dropdown_el.value) {
    dropdown_el.value.removeEventListener(
      'shown.bs.dropdown',
      (event) => {
        dropdownShown.value = true;
        emit('show', event);
      },
      false
    );
    dropdown_el.value.removeEventListener(
      'hidden.bs.dropdown',
      (event) => {
        dropdownShown.value = false;
        emit('hidden', event);
      },
      false
    );
  }

  window.removeEventListener('scroll', preventWindowScroll);
  window.removeEventListener('wheel', preventWindowScroll);
  window.removeEventListener('touchmove', preventWindowScroll);
});
</script>

<template>
  <div>
    <div
      :id="id"
      ref="dropdown_el"
      class="cursor-pointer"
      data-bs-toggle="dropdown"
      :data-bs-offset="offset"
      :aria-expanded="expanded"
    >
      <slot name="trigger"></slot>
    </div>
    <PerfectScrollbar
      :tag="tag"
      :id="ulId"
      class="dropdown-menu text-body shadow-lg border"
      :class="[dropdownClass]"
      :aria-labelledby="id"
      :style="{ maxWidth, maxHeight, minWidth, width, height }"
    >
      <slot></slot>
    </PerfectScrollbar>
  </div>
</template>
