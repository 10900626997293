import { computed } from 'vue';
import store from '@/store';
import useCrud from '@/composables/app/useCrud';
import useDate from '@/composables/app/useDate';

const useCustomFields = () => {
  const { updateData: updateCustomFields } = useCrud(
    '/admin-app/custom-field/'
  );
  const { formatDate } = useDate();

  const customFields = computed({
    get: () => store?.state?.masterSettings?.masterView?.customFields,
    set: async (customFields) => {
      let data = [];
      if (
        !Array.isArray(customFields) &&
        customFields?.data &&
        Array.isArray(customFields.data)
      ) {
        data = customFields?.data;
      } else {
        data = customFields;
      }
      store.commit('masterSettings/masterView/customFields', data);
      await updateCustomFields({ data: JSON.stringify(data) });
      if (
        !Array.isArray(customFields) &&
        customFields?.callback &&
        typeof customFields.callback === 'function'
      ) {
        customFields.callback(true, data);
      }
    }
  });

  const getCustomFieldValue = (row, field) => {
    const customFieldData = row?.custom_fields
      ? JSON.parse(row?.custom_fields)
      : {};
    let data = customFieldData[field.key] ? customFieldData[field.key] : null;
    if (data && ['date', 'datetime'].includes(field.type))
      data = formatDate(
        data,
        field?.type === 'datetime' ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'
      );

    if (data && field.type === 'options') data = data?.label;

    return data;
  };

  const setTableCustomFields = (tableName) => {
    const table = customFields.value.find(
      (table) => table.table === tableName && table?.active
    );
    if (!table) return [];
    const fields = (table?.fields || []).filter(
      (field) => field?.active && field?.show_on_table
    );
    if (!fields?.length) return [];

    return fields.map((field) => {
      const slug = field?.title?.toLowerCase()?.replaceAll(' ', '_');
      const duplicate = fields.some(
        (df) =>
          df?.id !== field?.id &&
          df?.title?.toLowerCase()?.replaceAll(' ', '_') === slug
      );
      if (duplicate)
        return { ...field, slug: `${slug}(${field.key.replaceAll('_', '-')})` };
      return { ...field, slug };
    });
  };

  return {
    customFields,
    getCustomFieldValue,
    setTableCustomFields
  };
};

export default useCustomFields;
