<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import axios from 'axios';
import useToast from '@/composables/app/useToast';
import notification from '@/store/notification';
import Modal from '@/views/common/Modal.vue';
import * as yup from 'yup';
import useValidation from '@/composables/app/useValidation';
import useHtmlEditor from '@/composables/app/useHtmlEditor';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import useCrud from '@/composables/app/useCrud';

const { createData: createNoteJob } = useCrud('/customer/job-note/');
const { createData: createNoteOffer } = useCrud('/customer/applicant-note/');

const { quillToolbar } = useHtmlEditor();
const { runYupValidation } = useValidation();
const { showDefaultToast, showConfirmModal } = useToast();
const store = useStore();
const router = useRouter();
const showNotesModal = ref(false);
const selectedAction = ref('');
const job = ref(null);
const uploadingNote = ref(false);
const getNotification = computed(() => {
    return store.getters['notification/getNotification'];
});
const latestTenNotification = computed(() => {
    return [...getNotification?.value?.results?.slice(0, 10)];
});
const noteUpdateKey = ref(1);
const selectedNotification = ref(null);
const newNoteData = ref({
    title: '',
    description: '',
});
const newNoteErrors = ref({});
const noteSchema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().required(),
});

const readNotification = async (id) => {
    const payload = {
        is_read: true,
    };
    await axios.patch(`/customer/notifications/${id}/`, payload);
    store.commit('notification/reloadNotificationPage', true);
    await store.dispatch('notification/getNotificationList', { page: 1 });
};
const viewItem = async (notification) => {
    if (notification?.context === 'job' && notification?.job) {
        router.push(`/recruitments/jobs/${notification?.job}`);
    } else if (notification?.context === 'offer') {
        router?.push(
            `/recruitments/applicants?job_id=${notification?.job}&candidate_id=${notification?.candidate}`
        );
    } else if (notification?.context === 'documentation_upload') {
        router?.push(
            `/recruitments/onboarding-documents?job=${notification?.job}&applicant=${notification?.candidate}`
        );
        if (!notification?.is_read) {
            await readNotification(notification.id);
        }
    } else if (notification?.context === 'interview') {
        router?.push(
            `/recruitments/applicants?job_id=${notification?.job}&candidate_id=${notification?.candidate}`
        );
        if (!notification?.is_read) {
            await readNotification(notification.id);
        }
    }
};
const confirmedApproved = async (note_id) => {
    const payload = {
        is_approved: true,
        notification_id: selectedNotification.value.id,
        is_read: true,
        note_id: note_id,
    };
    await axios
        .patch(selectedNotification.value.request_url, payload)
        .then((res) => {
            if (res?.data?.code === 0) {
                store.commit('notification/reloadNotificationPage', true);
                showDefaultToast(res?.data?.message, 'success');
                store.dispatch('notification/getNotificationList', { page: 1 });
            } else {
                showDefaultToast(res?.data?.message, 'danger');
            }
        });
};
const approveItem = async (notification) => {
    showConfirmModal(
        { message: 'Are you sure to approve ?' },
        async (confirm) => {
            if (!confirm) return;
            selectedNotification.value = notification;
            selectedAction.value = 'approve';
            newNoteData.value.title = '';
            newNoteData.value.description = '';
            newNoteErrors.value = {};
            noteUpdateKey.value = noteUpdateKey.value + 1;
            showNotesModal.value = true;
        }
    );
};
const confirmDeny = async (note_id) => {
    const payload = {
        is_approved: false,
        notification_id: selectedNotification.value.id,
        is_read: true,
        note_id: note_id,
    };
    await axios
        .patch(selectedNotification.value.request_url, payload)
        ?.then((res) => {
            if (res?.data?.code === 0) {
                store.commit('notification/reloadNotificationPage', true);
                showDefaultToast(res?.data?.message, 'success');
                store.dispatch('notification/getNotificationList', { page: 1 });
            } else {
                showDefaultToast(res?.data?.message, 'danger');
            }
        });
};
const denyItem = async (notification) => {
    showConfirmModal(
        { message: 'Are you sure to reject ?' },
        async (confirm) => {
            if (!confirm) return;
            selectedNotification.value = notification;
            selectedAction.value = 'reject';
            newNoteData.value.title = '';
            newNoteData.value.description = '';
            newNoteErrors.value = {};
            noteUpdateKey.value = noteUpdateKey.value + 1;
            showNotesModal.value = true;
        }
    );
};
const deleteItem = async (notification) => {
    showConfirmModal(null, async (confirm) => {
        if (!confirm) return;
        const payload = {
            is_archive: true,
        };
        await axios
            .patch(`customer/notifications/${notification?.id}/`, payload)
            .then((res) => {
                if (res?.data?.code === 0) {
                    store.commit('notification/reloadNotificationPage', true);
                    showDefaultToast(res?.data?.message, 'success');
                    store.dispatch('notification/getNotificationList', {
                        page: 1,
                    });
                } else {
                    showDefaultToast(res?.data?.message, 'danger');
                }
            });
    });
};
const confirmedNoteCreate = async () => {
    let payload = {
        ...newNoteData.value,
        type: 'system',
    };
    uploadingNote.value = true;
    let note_id = null;
    if (selectedNotification.value.context === 'job') {
        payload.job = selectedNotification.value.job;
        await createNoteJob(payload).then(async (res) => {
            if (res?.id) {
                note_id = res?.id;
            }
        });
    } else if (selectedNotification.value.context === 'offer') {
        payload.job_id = selectedNotification.value.job;
        payload.candidate_id = selectedNotification.value.candidate;
        await createNoteOffer(payload).then((res) => {
            if (res?.id) {
                note_id = res?.id;
            }
        });
    }
    if (note_id) {
        if (selectedAction.value === 'approve') {
            await confirmedApproved(note_id);
        } else if (selectedAction.value === 'reject') {
            await confirmDeny(note_id);
        }
    }
    uploadingNote.value = false;
    showNotesModal.value = false;
};
const onNoteSubmit = async () => {
    if (uploadingNote.value) return;
    if (selectedAction.value === 'reject') {
        const { validated, errors } = await runYupValidation(
            noteSchema,
            newNoteData.value
        );
        if (!validated) {
            newNoteErrors.value = { ...errors };
            return;
        }
        newNoteErrors.value = {};
        await confirmedNoteCreate();
    } else if (selectedAction.value === 'approve') {
        if (newNoteData.value.title) {
            await confirmedNoteCreate();
        } else {
            uploadingNote.value = false;
            showNotesModal.value = false;
            await confirmedApproved(null);
        }
    }
};
// const approveNotification = async;
</script>

<template>
    <div class="dropdown nav-item notification-dropdown btn-group">
        <button
            id="notification-dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            class="btn dropdown-toggle btn-icon-only nav-link"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-bell"
            >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <span
                v-if="getNotification?.unread_count > 0"
                class="badge badge-success"
                >{{
            }}</span>
        </button>

        <ul
            class="dropdown-menu dropdown-menu-right notification-dropdown-menu"
            aria-labelledby="notification-dropdown"
        >
            <perfect-scrollbar>
                <li
                    v-if="!getNotification?.results?.length"
                    role="presentation"
                >
                    <div class="dropdown-item">
                        <div class="media server-log">
                            <div class="media-body justify-content-center">
                                <div class="data-info">
                                    <h6 class="">
                                        You don't have any notification yet
                                    </h6>
                                    <p class="">Cheers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <div v-else>
                    <li class="text-end">
                        <div class="dropdown-item">
                            <div
                                @click="router.push('/notification')"
                                class="cursor-pointer text-info px-6 btn-sm py-1 rounded"
                            >
                                View All
                            </div>
                        </div>
                    </li>
                    <li
                        v-for="(notification, index) in latestTenNotification"
                        :key="index"
                    >
                        <div class="dropdown-item">
                            <div class="media server-log">
                                <div class="media-aside align-self-start">
                                    <FeatherIcon
                                        :type="
                                            notification?.context === 'job'
                                                ? 'briefcase'
                                                : 'award'
                                        "
                                        size="24"
                                        stroke-width="1"
                                        class="feather"
                                    />
                                </div>
                                <div class="media-body">
                                    <div class="data-info">
                                        <h6 class="">
                                            {{ notification?.message }}
                                        </h6>
                                        <p class="">
                                            {{
                                                notification?.created_at
                                                    ? format(
                                                          new Date(
                                                              notification?.created_at
                                                          ),
                                                          'dd/MM/yyyy'
                                                      )
                                                    : '-'
                                            }}
                                        </p>
                                        <div
                                            class="mt-3 gap-2 d-flex justify-content-end align-items-center"
                                        >
                                            <button
                                                @click="viewItem(notification)"
                                                class="btn btn-outline-primary btn-sm py-1 rounded"
                                            >
                                                View
                                            </button>
                                            <button
                                                v-if="
                                                    notification?.action ===
                                                        'popup' &&
                                                    !notification?.is_action_taken
                                                "
                                                class="btn btn-outline-success btn-sm py-1 rounded"
                                                @click="
                                                    approveItem(notification)
                                                "
                                            >
                                                Approve
                                            </button>
                                            <button
                                                v-if="
                                                    notification?.action ===
                                                        'popup' &&
                                                    !notification?.is_action_taken
                                                "
                                                class="btn btn-outline-danger btn-sm py-1 rounded"
                                                @click="denyItem(notification)"
                                            >
                                                Reject
                                            </button>
                                            <button
                                                @click="
                                                    deleteItem(notification)
                                                "
                                                v-if="notification?.is_read"
                                                class="btn btn-outline-danger btn-sm py-1 rounded"
                                            >
                                                Archive
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
            </perfect-scrollbar>
        </ul>
    </div>

    <Modal
        v-model="showNotesModal"
        :options="{ backdrop: 'static' }"
        :centered="true"
        :scrollable="true"
    >
        <div class="modal-header">
            <h6 v-if="selectedAction === 'reject'" class="modal-title">
                Note is required for the
                <span class="text-primary">Reject</span> status
            </h6>
            <h6 v-else-if="selectedAction === 'approve'" class="modal-title">
                You can add note for
                <span class="text-primary">approve</span> status (Optional)
            </h6>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>

        <div class="modal-body">
            <div class="w-100">
                <div id="note-editor" class="w-100">
                    <div class="form-group w-100">
                        <div
                            class="d-flex flex-row justify-content-between align-items-center"
                        >
                            <label
                                >Title
                                <small v-if="selectedAction === 'approve'"
                                    >(is required to save note)</small
                                ></label
                            >
                        </div>
                        <input
                            v-model="newNoteData.title"
                            class="form-control h-min-content py-2"
                        />
                        <div
                            v-if="newNoteErrors && newNoteErrors.title"
                            class="mt-1 text-danger text-12px"
                        >
                            {{ newNoteErrors.title }}
                        </div>
                    </div>

                    <div class="form-group w-100">
                        <label>Description</label>
                        <QuillEditor
                            v-model:content="newNoteData.description"
                            content-type="html"
                            class="bg-body"
                            :toolbar="quillToolbar"
                            :key="noteUpdateKey"
                        />
                        <div
                            v-if="newNoteErrors && newNoteErrors.description"
                            class="mt-1 text-danger text-12px"
                        >
                            {{ newNoteErrors.description }}
                        </div>
                    </div>

                    <div class="w-100 d-flex justify-content-end my-3">
                        <button
                            class="btn btn-secondary px-4 py-2"
                            @click="onNoteSubmit"
                        >
                            <div
                                v-if="uploadingNote"
                                class="spinner-border spinner-border-sm me-1"
                                role="status"
                                style="width: 10px; height: 10px"
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>

                            {{ uploadingNote ? 'Processing' : 'Submit' }}
                        </button>
                    </div>
                </div>
            </div>
        </div></Modal
    >
</template>

<style scoped>
.ps {
    max-height: 360px;
}

.notification-dropdown-menu {
    min-width: 80px !important;
    width: 25rem !important;
    max-width: calc(100vw - 88px) !important;
}
</style>
