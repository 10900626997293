const state = {
    candidates: [],
    loading: false,
};

const mutations = {
    candidates: (state, payload) => (state.candidates = payload),
	loading: (state, payload) => (state.loading = payload)
};

export default { state, mutations };
