<script setup>
import { useSlots, computed } from 'vue';
import useAppConfig from '@/composables/app/useAppConfig';

const props = defineProps({
	title: {
		type: String,
		default: () => 'Submit',
	},
	type: {
		type: String,
		default: () => 'button',
	},
	loadingTitle: {
		type: String,
		default: () => 'Processing',
	},
	variant: {
		type: String,
		default: () => 'secondary',
	},
	loading: {
		type: Boolean,
		default: () => false,
	},
	buttonClass: {
		type: String,
		default: () => 'px-4 py-2',
	},
	data: {
		required: false,
	},
});

const emit = defineEmits(['submit']);

const { currentWindowWidth } = useAppConfig();

const slots = useSlots();
const loaderStyle = computed(() => {
	return { width: '20px', height: '20px' };
});
</script>

<template>
	<button
		class="btn"
		:class="[`btn-${variant}`, buttonClass]"
		@click="$emit('submit', data)"
	>
		<div
			v-if="loading"
			class="spinner-border spinner-border-sm"
			:class="
				Object.keys(slots).length && currentWindowWidth <= 768 ? '' : 'me-2'
			"
			role="status"
			:style="loaderStyle"
		>
			<span class="visually-hidden">Loading...</span>
		</div>
		<slot>{{ loading ? loadingTitle : title }}</slot>
	</button>
</template>

<style lang="scss" scoped></style>
