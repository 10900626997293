import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import useEnv from '@/composables/app/useEnv';
import 'vue-select/dist/vue-select.css';
import '@/assets/sass/widgets/widgets.scss';
import 'vue-popper-utilities/style.css';
import '@vuepic/vue-datepicker/dist/main.css';
import axios from '@/utils/axios/axios';

const app = createApp(App);

// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

//vue-i18n
// import i18n from './i18n';

// datatables
import { ClientTable, ServerTable } from 'v-tables-3';

// axios default base url
// const { serverProductionUrl } = useEnv();
// import axios from 'axios';

// // axios.defaults.baseURL = 'http://localhost:8000/api/v1';
// axios.defaults.baseURL = `${serverProductionUrl}/api/v1`;
// axios.defaults.withCredentials = true;

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
// import VueFormWizard from 'vue3-form-wizard';
// import 'vue3-form-wizard/dist/style.css';

// set default settings
import appSetting from './app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();

import VueFeather from 'vue-feather';
import Pagination from 'v-pagination-3';

// directives
import focus from '@/directives/global/focus';
import stickyCk from '@/directives/global/stickyCk';

// local components
import LoadingButton from '@/views/common/components/LoadingButton.vue';
import SkeletonLoading from '@/views/common/components/SkeletonLoading.vue';
import Skeleton from '@/views/common/components/Skeleton.vue';
import FileUpload from '@/views/common/components/FileUpload.vue';
import highlightDirective from './directives/global/highlightDirective';

app
  .use(store)
  .use(router)
  // .use(i18n)
  .use(PerfectScrollbar)
  .use(VueNouislider)
  .use(Maska)
  .use(ClientTable)
  .use(ServerTable)
  .use(vue3JsonExcel)
  // .use(VueFormWizard)
  .use(head)
  .use(axios)
  .component('FeatherIcon', VueFeather)
  .component('pagination', Pagination)
  .component('LoadingButton', LoadingButton)
  .component('SkeletonLoading', SkeletonLoading)
  .component('Skeleton', Skeleton)
  .component('FileUpload', FileUpload)
  .directive('focus', focus)
  .directive('sticky-ck', stickyCk)
  .directive('highlight', highlightDirective)
  .mount('#app');
