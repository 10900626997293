const state = {
	search: {},
	isSearched: false,
	candidates: [],
	count: 0,
};

const mutations = {
	setSearchedData(state, payload) {
		state.isSearched = true;
		state.search = { ...payload };
	},
	resetSearchedData(state) {
		state.search = {};
		state.isSearched = false;
	},
	candidates(state, payload) {
		state.candidates = [...payload?.results];
		state.count = payload?.count;
	},
	resetCandidates(state) {
		state.candidates = [];
	},
};

export default { state, mutations };
