<script setup>
import {
	ref,
	onBeforeMount,
	watchEffect,
	computed,
	onMounted,
	onBeforeUnmount,
	watch,
} from 'vue';

import ParsingItem from '@/views/bulk-parsing/ParsingItem.vue';
import {
	Dropdown,
	DropdownItem,
	DropdownDivider,
} from '@/views/common/components/dropdown';

import useCrud from '@/composables/app/useCrud';
import useEnv from '@/composables/app/useEnv';
import useAuth from '@/composables/auth/useAuth';
import useCommonUtils from '@/composables/app/useCommonUtils';
import useToast from '@/composables/app/useToast';

import emitter from 'tiny-emitter/instance';
import { v4 } from 'uuid';

const { getData: getDataFromDbRequest } = useCrud(
	'/customer/upload-bulk-file/'
);
const {
	createData: markParsingAsComplete,
	postRequestLoading: markingAsComplete,
} = useCrud('/customer/parsing-mark-as-completed/');
const { createData: cancelParsing } = useCrud('/customer/cancel-parsing/');

const { websocketURL } = useEnv();
const { authMember } = useAuth();
const { isJsonParsable } = useCommonUtils();
const { showDefaultToast, showConfirmModal } = useToast();

const minimized = ref(true);
const show = ref(false);

const filesFromDb = ref([]);
const uploadedFiles = ref([]);
const lastSocketResponse = ref(null);
const responsesFromSocket = ref([]);
const responsesFromAction = ref([]);

watchEffect(() => {
	show.value = Boolean(
		uploadedFiles.value?.length ||
			filesFromDb.value?.length ||
			responsesFromSocket.value?.length
	);
});

const getDataFromDb = async () => {
	const response = await getDataFromDbRequest();
	// filesFromDb.value = response?.length ? response : [];
	filesFromDb.value = response?.length
		? response.map(r => ({ ...r, origin: 'db', timestamp: Date.now() }))
		: [];
};

onBeforeMount(() => {
	getDataFromDb();
});
emitter.on('bulk-files-uploaded', files => {
	minimized.value = false;
	uploadedFiles.value = files;
});

const socket = ref(null);
const socketUrl = computed(() => {
	const baseUrl = websocketURL.includes('/notifications')
		? websocketURL.replace('/notifications', '')
		: websocketURL;
	return `${baseUrl}/file_update/${authMember.value?.id}/`;
});

const onSocketOpen = () => {
	socket.value = new WebSocket(socketUrl.value);
	if (socket.value) {
		socket.value.onmessage = payload => {
			let payloadData = isJsonParsable(payload?.data)
				? JSON.parse(payload?.data)
				: {};

			lastSocketResponse.value = payloadData?.message
				? {
						id: `socket-${v4()}`,
						...payloadData?.message,
						origin: 'socket',
						timestamp: Date.now(),
				  }
				: {};

			responsesFromSocket.value = [lastSocketResponse.value].concat(
				responsesFromSocket.value
			);
		};
	}
};
const onSocketClose = () => {
	if (!socket.value) return;
	socket.value.close();
};
onMounted(() => {
	onSocketOpen();
	// window.addEventListener('beforeunload', browserCloseDialogue, false);
});
onBeforeUnmount(() => {
	onSocketClose();
	// window.removeEventListener('beforeunload', browserCloseDialogue, false);
});

const filterOptions = computed(() => {
	return [
		{ id: v4(), label: 'All', value: 'all', appendDivider: true },
		{ id: v4(), label: 'Uploaded', value: 'uploaded', appendDivider: false },
		{
			id: v4(),
			label: 'Not uploaded',
			value: 'not-uploaded',
			appendDivider: true,
		},
		{ id: v4(), label: 'Parsed', value: 'parsed', appendDivider: false },
		{ id: v4(), label: 'Not parsed', value: 'not-parsed', appendDivider: true },
		{
			id: v4(),
			label: 'Need action',
			value: 'need-action',
			appendDivider: false,
		},
		{ id: v4(), label: 'Completed', value: 'completed', appendDivider: false },
		{
			id: v4(),
			label: 'Not complted',
			value: 'not-completed',
			appendDivider: true,
		},
		{
			id: v4(),
			label: 'Not cv',
			value: 'not-cv',
			appendDivider: false,
		},
	];
});
const activeFilter = ref(filterOptions.value.find(f => f?.value === 'all'));

const isCompleted = file => {
	return Boolean(
		file?.uploaded && (file?.invalid_cv || (file?.parsed && !file?.candidate))
	);
};

const filterFiles = files => {
	switch (activeFilter.value?.value) {
		case 'all':
			return files;

		case 'uploaded':
			return files.filter(f => f?.uploaded);

		case 'not-uploaded':
			return files.filter(f => !f?.uploaded);

		case 'parsed':
			return files.filter(f => f?.parsed);

		case 'not-parsed':
			return files.filter(f => !f?.parsed);

		case 'need-action':
			return files.filter(f => f?.candidate);

		case 'completed':
			return files.filter(f => isCompleted(f));

		case 'not-completed':
			return files.filter(f => !isCompleted(f));

		case 'not-cv':
			return files.filter(f => f?.invalid_cv);

		default:
			return files;
	}
};

const fileList = computed(() => {
	const allFiles = [
		...filesFromDb.value,
		...responsesFromSocket.value,
		...uploadedFiles.value,
		...responsesFromAction.value,
	];

	const allFileIds = allFiles
		.filter(file => file?.file_id)
		.map(file => file?.file_id);

	const uniqueFileIds = allFileIds.filter(
		(id, i, files) => files.indexOf(id) === i
	);

	const files = uniqueFileIds.map(fileId => {
		const filesById = allFiles.filter(file => file?.file_id === fileId);
		if (filesById?.length === 1) return filesById[0];
		else {
			const sortedFilesByTimestamp = filesById.sort((a, b) =>
				Number(a?.timestamp) >= Number(b?.timestamp) ? -1 : 1
			);
			return sortedFilesByTimestamp[0];
		}
	});

	return files;
});

const filteredFiles = computed(() => filterFiles(fileList.value));

const onMarkParsingAsComplete = async () => {
	const response = await markParsingAsComplete({});
	if (response?.code == 0) {
		uploadedFiles.value = [];
		responsesFromAction.value = [];
		responsesFromSocket.value = [];
		await getDataFromDb();

		showDefaultToast(
			'Success',
			'success',
			response?.message
				? response?.message
				: 'Parsing data has been reset successfully...'
		);
	} else {
		showDefaultToast(
			'Error',
			'danger',
			response?.message ? response?.message : 'Parsing data not reset...'
		);
	}
};

const isParsingCompleted = computed(() =>
	fileList.value.every(file => isCompleted(file))
);
const parsingStatus = computed(() => {
	const countFiles = fileList.value?.length;
	const countUploaded = fileList.value.filter(file => file?.uploaded)?.length;
	const countParsed = fileList.value.filter(file => file?.parsed)?.length;
	const countNeedAction = fileList.value.filter(
		file => file?.parsed && file?.candidate
	)?.length;

	const countNotCv = fileList.value.filter(file => file?.invalid_cv)?.length;
	let msg = `Out of ${countFiles} resumes: ${countUploaded} uploaded, ${countParsed} parsed${
		countNotCv ? `, ${countNotCv} not CV file` : ''
	}, ${countNeedAction} needs action...`;

	return msg;
});

const hasAllFileParsed = computed(() => {
	if (!responsesFromSocket.value) return false;
	return fileList.value.every(
		file => file?.uploaded && (file?.parsed || file?.invalid_cv)
	);
});
watch(
	() => hasAllFileParsed.value,
	(val, oldVal) => {
		if (!oldVal && val) {
			emitter.emit('reload-candidate-list');
		}
	}
);

const onCancelParsing = () => {
	showConfirmModal({ message: 'Are you sure to cancel?' }, async confirmed => {
		if (!confirmed) return;

		const response = await cancelParsing();
		if (response?.code == 0) {
			showDefaultToast('Success', 'success', 'Parsing has been canceled');
			filesFromDb.value = [];
			responsesFromSocket.value = [];
			uploadedFiles.value = [];
			responsesFromAction.value = [];
		}
	});
};
</script>

<template>
	<Teleport to="body">
		<div
			v-if="fileList?.length"
			class="bulk-parsing-container shadow-small bg-card d-flex flex-column"
		>
			<div
				class="w-full flex-between parsing-header bg-color-18 dark-bg-color-19"
			>
				<div>
					<div class="fw-bold text-color-2">Bulk parsing status</div>
					<div class="text-11px">{{ parsingStatus }}</div>
				</div>

				<div class="flex-start">
					<FeatherIcon
						:type="minimized ? 'maximize-2' : 'minimize-2'"
						size="20"
						class="cursor-pointer"
						@click="minimized = !minimized"
					/>
				</div>
			</div>

			<div
				v-if="!minimized"
				class="p-3 parsing-body overflow-auto flex-1 border"
			>
				<div class="w-100 flex-between">
					<div>
						<Dropdown min-width="196px">
							<template #trigger>
								<div class="flex-start">
									<span>{{ activeFilter?.label }}</span>
									<FeatherIcon type="chevron-down" class="ms-2" />
								</div>
							</template>

							<div>
								<template
									v-for="filterOption in filterOptions"
									:key="filterOption.id"
								>
									<DropdownItem @click="activeFilter = filterOption">{{
										filterOption?.label
									}}</DropdownItem>
									<DropdownDivider v-if="filterOption?.appendDivider" />
								</template>
							</div>
						</Dropdown>
					</div>

					<div class="mb-3 flex-end ms-4">
						<button
							class="btn btn-danger flex-center font-bold text-18px cancel-btn"
							@click="onCancelParsing"
						>
							x
						</button>

						<LoadingButton
							v-if="isParsingCompleted"
							title="Mark Complete"
							variant="primary"
							class="ms-2"
							@submit="onMarkParsingAsComplete"
						/>
					</div>
				</div>

				<ParsingItem
					v-for="file in filteredFiles"
					:key="file?.file_id"
					:file="file"
					@action-success="
						responsesFromAction = [$event].concat(responsesFromAction)
					"
				/>
			</div>
		</div>
	</Teleport>
</template>

<style lang="scss" scoped>
.bulk-parsing-container {
	position: fixed;
	bottom: 0;
	right: 64px;
	width: 520px;
	max-width: calc(98% - 64px);
	max-height: calc(100% - 120px);
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	z-index: 2999;

	.parsing-header {
		padding: 12px 16px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		border-top: 1px solid #3b3f5c;
		border-left: 1px solid #3b3f5c;
		border-right: 1px solid #3b3f5c;
		// height: 52px;
	}

	.cancel-btn {
		width: 39px;
		height: 39px;
	}
}
</style>
