<script setup>
import { ref } from 'vue';
import emitter from 'tiny-emitter/instance';
import useCommonUtils from '@/composables/app/useCommonUtils';

const { isObject, isEmptyObject } = useCommonUtils();

const show = ref(false);
const initialModalData = {
	message: 'Are you sure to delete?',
	actionButton: {
		variant: 'success',
		text: 'Proceed',
	},
	returnButton: {
		variant: 'danger',
		text: 'Go back',
	},
};
const setModalData = data => {
	if (!data || !isObject(data) || isEmptyObject(data)) return initialModalData;
	const { message, actionButton, returnButton } = data;

	const mergedData = {
		...initialModalData,
		message: message ? message : initialModalData.message,
		actionButton: actionButton ? actionButton : initialModalData.actionButton,
		returnButton: returnButton ? returnButton : initialModalData.returnButton,
	};

	return mergedData;
};
const modalData = ref(initialModalData);

const action_button = ref(null);
const return_button = ref(null);
const modal_overlay = ref(null);

emitter.on('show-confirm-modal', (data, callback) => {
	show.value = true;
	modalData.value = setModalData(data);

	setTimeout(() => {
		if (action_button.value) {
			action_button.value.onclick = () => {
				show.value = false;
				callback(true);
			};
		}

		if (return_button.value) {
			return_button.value.onclick = () => {
				show.value = false;
				callback(false);
			};
		}

		if (modal_overlay.value) {
			modal_overlay.value.onclick = () => {
				show.value = false;
				callback(null);
			};
		}
	}, 100);
});
</script>

<template>
	<Teleport to="body">
		<!-- overlay -->
		<div v-if="show" ref="modal_overlay" class="confirm-modal-overlay"></div>

		<!-- modal -->
		<Transition>
			<div v-if="show" class="confirm-modal rounded border bg-card">
				<div class="p-3 fw-bold pb-3 border-bottom">
					{{ modalData.message }}
				</div>
				<div class="p-3 w-100 d-flex justify-content-end align-items-center">
					<div class="d-flex flex-row align-items-center">
						<button
							ref="return_button"
							class="btn me-2 px-4 py-2"
							:class="[`btn-${modalData.returnButton.variant}`]"
						>
							{{ modalData.returnButton.text }}
						</button>
						<button
							ref="action_button"
							class="btn px-4 py-2"
							:class="[`btn-${modalData.actionButton.variant}`]"
						>
							{{ modalData.actionButton.text }}
						</button>
					</div>
				</div>
			</div>
		</Transition>
	</Teleport>
</template>

<style lang="scss" scoped>
@import '@/assets/base/_base.scss';

.confirm-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #000;
	z-index: 55555 !important;
	opacity: 0.1 !important;
}
.confirm-modal {
	position: fixed;
	top: 72px;
	width: 40%;
	left: 30%;
	z-index: 55556;

	&.v-enter-from {
		opacity: 0;
		transform: scale(0.75);
	}
	&.v-leave-to {
		opacity: 0;
		transform: scale(0.75);
	}
	&.v-enter-active {
		transition: all 0.25s ease-out;
	}

	&.v-leave-active {
		transition: all 0.25s ease-out;
	}
}

.dark {
	.confirm-modal-overlay {
		background: $m-color_18;
		opacity: 0.5 !important;
	}
}

@media screen and (max-width: 992px) {
	.confirm-modal {
		width: 60%;
		left: 20%;
	}
}

@media screen and (max-width: 768px) {
	.confirm-modal {
		width: 80%;
		left: 10%;
	}
}

@media screen and (max-width: 576px) {
	.confirm-modal {
		width: 96%;
		left: 2%;
	}
}
</style>
