const state = {
    units: [],
    loading: false,
};

const mutations = {
    units: (state, payload) => (state.units = payload),
	loading: (state, payload) => (state.loading = payload)
};

export default { state, mutations };
