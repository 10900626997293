import axios from 'axios';
import useEnv from '@/composables/app/useEnv';
const { websocketURL } = useEnv();
const state = {
	socketInstance: null,
	notification: {},
	reloadNotificationPage: false,
};

const mutations = {
	setNotification(state, payload) {
		state.notification = payload;
	},
	getNotificationRealtime(state, payload) {
		state.notification?.results?.unshift(payload);
		state.notification.unread_count = state.notification?.unread_count + 1;
		state.reloadNotificationPage = true;
	},
	reloadNotificationPage(state, payload) {
		state.reloadNotificationPage = payload;
	},
};
const getters = {
	getNotification(state) {
		return state.notification;
	},
	getReloadNotificationPage(state) {
		return state.reloadNotificationPage;
	},
};
const actions = {
	async getNotificationList({ commit }, payload) {
		await axios
			.get(`/customer/notifications/?page=${payload?.page}`)
			.then(res => {
				commit('setNotification', res.data);
			});
	},
};

export default { state, mutations, getters, actions };
