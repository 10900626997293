<script setup>
import { watch, ref } from 'vue';
import { useStore } from 'vuex';
import lodash from 'lodash';

const toastOptions = {
	animation: true,
	autohide: true,
	delay: 10000,
};

const store = useStore();
const toasts = ref([]);

const toast_dom_nodes = ref(null);

watch(
	() => lodash.cloneDeep(store.state?.utils?.toast),
	toast => {
		if (!toast || !toast.id) return;
		toasts.value.unshift(toast);
		const toastOptions = {
			animation: true,
			autohide: true,
			delay: toast.delay,
		};
		setTimeout(() => {
			const node = toast_dom_nodes.value.find(node => node.id === toast.id);
			if (node) {
				const initiated = new bootstrap.Toast(node, toastOptions);
				initiated.show();
			}
		}, 500);
	}
);
</script>

<template>
	<div>
		<div aria-live="polite" aria-atomic="true" class="position-relative">
			<div class="toast-container position-fixed top-0 end-0 p-3 z-9999">
				<!-- Then put toasts within -->
				<div
					v-for="toast in toasts"
					:key="toast.id"
					:id="toast.id"
					class="toast opacity-100"
					:class="[`bg-${toast.variant}`]"
					role="alert"
					aria-live="assertive"
					aria-atomic="true"
					ref="toast_dom_nodes"
				>
					<div class="toast-header" :class="[`bg-${toast.variant}`]">
						<div class="me-auto my-auto py-2">
							<div class="d-flex flex-column">
								<h6 class="text-white fw-bold mb-0">{{ toast.message }}</h6>
								<div v-if="toast.text" class="text-13px text-white">
									{{ toast.text }}
								</div>
							</div>
						</div>
						<FeatherIcon
							type="x"
							size="18"
							class="text-white cursor-pointer close-btn"
							data-bs-dismiss="toast"
							aria-label="Close"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.close-btn {
	min-width: 18px;
}
</style>
