const reportRoutes = [
  {
    path: '/reports',
    name: 'reports',
    component: () =>
      import(/* webpackChunkName: "docs" */ '@/views/report/ReportBase.vue')
  }
];

export default reportRoutes;
