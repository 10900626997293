const state = {
	orgs: [],
	loading: false,
};

const mutations = {
	orgs: (state, payload) => (state.orgs = payload),
	loading: (state, payload) => (state.loading = payload),
};

export default { state, mutations };
