import hljs from 'highlight.js/lib/core';

export default {
  mounted(el) {
    hljs.highlightElement(el);
  },
  updated(el) {
    hljs.highlightElement(el);
  },
};
