import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import axios from 'axios';

const useCompany = () => {
	const store = useStore();
	const route = useRoute();
	const routeQuery = computed(() => route.query);

	const getAllCompanies = async (queries = undefined) => {
		try {
			store.commit('recruitments/companies/loading', true);
			queries = queries
				? { ...routeQuery.value, ...queries }
				: routeQuery.value;

			const query = '?' + new URLSearchParams(queries).toString();
			const response = await axios.get(`/customer/company/${query}`);
			if (response.data) {
				store.commit('recruitments/companies/companies', response.data);
				store.commit('recruitments/companies/loading', false);
			}
			store.commit('recruitments/companies/loading', false);
		} catch (error) {
			store.commit('recruitments/companies/companies', []);
			store.commit('recruitments/companies/loading', false);
		}
	};

	const createCompany = async params => {
		try {
			const response = await axios.post('/customer/company/', params, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data) {
				await getAllCompanies();
				return response.data;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

	const updateCompany = async (params, id) => {
		try {
			const response = await axios.patch(`/customer/company/${id}/`, params, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.data) {
				await getAllCompanies();
				return true;
			}
			return false;
		} catch (error) {
			return false;
			// do something with error
		}
	};

	const getCompanyFiles = async companyId => {
		try {
			const response = await axios.get(
				`/customer/company-file/?company_id=${companyId}`
			);
			if (response.data) {
				return response.data;
			}
			return [];
		} catch (error) {
			return [];
		}
	};

	const deleteCompanyFile = async fileId => {
		try {
			const response = await axios.delete(`/customer/company-file/${fileId}/`);
			if ([200, 201, 204].includes(response.status)) return true;
			return false;
		} catch (error) {
			return false;
		}
	};

	return {
		getAllCompanies,
		createCompany,
		updateCompany,
		getCompanyFiles,
		deleteCompanyFile,
	};
};

export default useCompany;
