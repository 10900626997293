let resumeSearchRoutes = [
	{
		path: '/resume-search/',
		name: 'resume-search',
		component: () =>
			import(
				/* webpackChunkName: "resume-search" */ '@/views/resumeSearch/ResumeSearch.vue'
			),
	},
];
resumeSearchRoutes = [...resumeSearchRoutes].map(route => ({
	...route,
	meta: { ...route.meta, role: ['Admin', 'Recruiter'] },
}));
export default resumeSearchRoutes;
