<script setup>
import { computed } from 'vue';

const props = defineProps({
	skeletonClass: {
		type: String,
		default: () => '',
	},
	skeletonStyle: {
		type: Object,
		default: () => ({}),
	},
	width: {
		type: [String, null],
		default: () => null,
	},
	height: {
		type: [String, null],
		default: () => null,
	},
});

const computedStyle = computed(() => {
	let style = props.skeletonStyle || {};
	if (props.width) style = { ...style, width: props.width };
	if (props.height) style = { ...style, height: props.height };

	return style;
});
</script>

<template>
	<p class="placeholder-glow mb-0">
		<span
			class="placeholder"
			:class="[skeletonClass]"
			:style="computedStyle"
		></span>
	</p>
</template>
