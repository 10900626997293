import useCrud from '@/composables/app/useCrud';
const { getData: getResumeFormats, deleteData: onDeleteResumeFormat } = useCrud(
	'/customer/applicant-resume-format/'
);
const state = {
	candidatesResumeFormatList: [],
	loading: false,
};

const mutations = {
	setResumeFormatList(state, payload) {
		state.candidatesResumeFormatList = payload;
	},
};
const getters = {
	resumeFormatList(state) {
		return state.candidatesResumeFormatList?.map(attachment => {
			return {
				...attachment,
				name: `${
					attachment.file && attachment.file.split('/').length > 1
						? attachment.file.split('/')[attachment.file.split('/').length - 1]
						: ''
				} (${attachment.size})`,
			};
		});
	},
};
const actions = {
	async getResumeFormatList({ commit }, payload) {
		await getResumeFormats({ payload }).then(res => {
			commit('setResumeFormatList', res);
		});
	},
	async deleteResumeFormat({commit},payload){
		await onDeleteResumeFormat(payload);
	}
};

export default { state, mutations, getters, actions };
