let routes = [
  // interview
  {
    path: '/settings/master/master-view/interview/stages',
    name: 'interview-stage-resource',
    component: () =>
      import('@/views/settings/master/master-view/interviews/Stage.vue')
  },

  // requirement
  {
    path: '/settings/master/master-view/requirement/status',
    name: 'requirement-status-resource',
    component: () =>
      import(
        '@/views/settings/master/master-view/requirements/RequirementStatus.vue'
      )
  },

  // workflow
  {
    path: '/settings/master/master-view/workflows',
    name: 'workflows-resource',
    component: () =>
      import('@/views/settings/master/master-view/workflows/Workflow.vue')
  },
  {
    path: '/settings/master/master-view/stage-permission',
    name: 'workflows-stage-permission',
    component: () =>
      import(
        '@/views/settings/master/master-view/workflows/StagePermission.vue'
      )
  },
  {
    path: '/settings/master/master-view/workflows/stages',
    name: 'workflows-stages-resource',
    component: () =>
      import('@/views/settings/master/master-view/workflows/WorkflowStage.vue')
  },
  {
    path: '/settings/master/master-view/reasons',
    name: 'workflows-reasons-resource',
    component: () =>
      import('@/views/settings/master/master-view/reasons/Reasons.vue')
  },

  // email templates
  {
    path: '/settings/master/email/templates',
    name: 'email-templates-resource',
    component: () =>
      import('@/views/settings/master/email/template/EmailTemplate.vue')
  },

  // email configuration
  {
    path: '/settings/master/email/configurations',
    name: 'email-configurations',
    component: () =>
      import('@/views/settings/master/email/config/EmailConfig.vue')
  },

  // email signature
  // {
  // 	path: '/settings/master/email/signatures',
  // 	name: 'email-signatures',
  // 	component: () =>
  // 		import('@/views/settings/master/email/signature/EmailSignatures.vue'),
  // },

  // auto mailer templates
  {
    path: '/settings/master/email/auto-mailer-templates',
    name: 'email-auto-mailer-templates',
    component: () =>
      import(
        '@/views/settings/master/email/auto-mailers/AutoMailerTemplates.vue'
      )
  },

  // offer manager
  {
    path: '/settings/master/master-view/offer-manager/salary-template',
    name: 'salary-template',
    component: () =>
      import(
        '@/views/settings/master/master-view/offer-manager/SalaryTemplate.vue'
      )
  },
  {
    path: '/settings/master/master-view/offer-manager/salary-header',
    name: 'salary-header',
    component: () =>
      import(
        '@/views/settings/master/master-view/offer-manager/SalaryHeaders.vue'
      )
  },
  {
    path: '/settings/master/master-view/offer-manager/salary-level',
    name: 'salary-level',
    component: () =>
      import(
        '@/views/settings/master/master-view/offer-manager/SalaryLevel.vue'
      )
  },
  {
    path: '/settings/master/master-view/offer-manager/salary-grade',
    name: 'salary-grade',
    component: () =>
      import(
        '@/views/settings/master/master-view/offer-manager/SalaryGrade.vue'
      )
  },
  {
    path: '/settings/master/master-view/offer-manager/offer-template',
    name: 'offer-template',
    component: () =>
      import(
        '@/views/settings/master/master-view/offer-manager/OfferTemplate.vue'
      )
  },

  // requisition list
  {
    path: '/settings/master/master-view/designation-list',
    name: 'designation-list',
    component: () =>
      import(
        '@/views/settings/master/master-view/requisition/DesignationList.vue'
      )
  },

  {
    path: '/settings/master/master-view/country-list',
    name: 'country-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/CountryList.vue')
  },

  {
    path: '/settings/master/master-view/city-list',
    name: 'city-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/CityList.vue')
  },

  {
    path: '/settings/master/master-view/state-list',
    name: 'state-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/StateList.vue')
  },

  {
    path: '/settings/master/master-view/currency-list',
    name: 'currency-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/CurrencyList.vue')
  },

  {
    path: '/settings/master/master-view/skill-list',
    name: 'skill-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/SkillList.vue')
  },

  {
    path: '/settings/master/master-view/language-list',
    name: 'language-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/LanguageList.vue')
  },

  {
    path: '/settings/master/master-view/education-list',
    name: 'education-list',
    component: () =>
      import(
        '@/views/settings/master/master-view/requisition/EducationList.vue'
      )
  },

  {
    path: '/settings/master/master-view/degree-list',
    name: 'degree-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/DegreeList.vue')
  },

  {
    path: '/settings/master/master-view/industry-list',
    name: 'industry-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/IndustryList.vue')
  },

  {
    path: '/settings/master/master-view/nationality-list',
    name: 'nationality-list',
    component: () =>
      import(
        '@/views/settings/master/master-view/requisition/NationalityList.vue'
      )
  },

  {
    path: '/settings/master/master-view/job-type-list',
    name: 'job-type-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/JobTypeList.vue')
  },

  {
    path: '/settings/master/master-view/level-of-education',
    name: 'level-of-education',
    component: () =>
      import(
        '@/views/settings/master/master-view/requisition/LevelOfEducationList.vue'
      )
  },

  {
    path: '/settings/master/master-view/specialization-list',
    name: 'specialization-list',
    component: () =>
      import(
        '@/views/settings/master/master-view/requisition/SpecializationList.vue'
      )
  },

  {
    path: '/settings/master/master-view/staffing-type-list',
    name: 'staffing-type-list',
    component: () =>
      import(
        '@/views/settings/master/master-view/requisition/StaffingTypeList.vue'
      )
  },

  {
    path: '/settings/master/master-view/university-list',
    name: 'university-list',
    component: () =>
      import(
        '@/views/settings/master/master-view/requisition/UniversityList.vue'
      )
  },

  {
    path: '/settings/master/master-view/company-list',
    name: 'company-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/CompanyList.vue')
  },

  {
    path: '/settings/master/master-view/role-list',
    name: 'role-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/RoleList.vue')
  },
  {
    path: '/settings/master/master-view/candidate-filetype-list',
    name: 'candidate-filetype-list',
    component: () =>
      import(
        '@/views/settings/master/master-view/requisition/CandidateFileTypeList.vue'
      )
  },
  {
    path: '/settings/master/master-view/cv-source-list',
    name: 'cv-source-list',
    component: () =>
      import('@/views/settings/master/master-view/requisition/CvSourceList.vue')
  },

  // assessment
  {
    path: '/settings/master/master-view/assessment-templates',
    name: 'assessment-templates-resource',
    component: () =>
      import('@/views/settings/master/master-view/assessment/Templates.vue')
  },

  // joining
  {
    path: '/settings/master/master-view/joining-templates',
    name: 'joining-templates',
    component: () =>
      import('@/views/settings/master/master-view/joining/JoiningTemplates.vue')
  },

  // custom fields
  {
    path: '/settings/master/master-view/custom-fields',
    name: 'custom-fields',
    component: () =>
      import(
        '@/views/settings/master/master-view/custom-fields/CustomFields.vue'
      )
  },

  // sidebar
  {
    path: '/settings/master/master-view/sidebar/menu-names',
    name: 'sidebar-menu-names',
    component: () =>
      import('@/views/settings/master/master-view/sidebar/SidebarName.vue')
  },

  // parsing alias
  {
    path: '/settings/master/master-view/parsing-alias/country',
    name: 'parsing-alias-country',
    component: () =>
      import(
        '@/views/settings/master/master-view/parsing-alias/CountryList.vue'
      )
  },
  {
    path: '/settings/master/master-view/parsing-alias/nationality',
    name: 'parsing-alias-nationality',
    component: () =>
      import(
        '@/views/settings/master/master-view/parsing-alias/NationalityList.vue'
      )
  },

  // matching parameters
  {
    path: '/settings/master/master-view/matching-parameters-weightage',
    name: 'matching-parameters-weightage',
    component: () =>
      import(
        '@/views/settings/master/master-view/MatchingParametersWeightage/Home.vue'
      )
  },
  {
    path: '/settings/master/master-view/matching-parameters-weightage-job',
    name: 'matching-parameters-weightage-job',
    component: () =>
      import(
        '@/views/settings/master/master-view/MatchingParametersWeightage/Job.vue'
      )
  },
  {
    path: '/settings/master/master-view/matching-parameters-weightage-candidate',
    name: 'matching-parameters-weightage-candidate',
    component: () =>
      import(
        '@/views/settings/master/master-view/MatchingParametersWeightage/Candidate.vue'
      )
  }
];

routes = [...routes].map((route) => ({
  ...route,
  meta: { ...route.meta, role: ['Admin'] }
}));
export default routes;
