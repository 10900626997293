const useEnv = () => {
	const env = process.env;

	const environment = env?.NODE_ENV ? env.NODE_ENV : 'development';

	// for backend url
	const serverProductionUrl = env?.VUE_APP_SERVER_PRODUCTION_URL
		? env.VUE_APP_SERVER_PRODUCTION_URL
		: 'https://ats.aosworld.com';

	const serverTestUrl = env?.VUE_APP_SERVER_TEST_URL
		? env.VUE_APP_SERVER_TEST_URL
		: 'http://127.0.0.1:8000';

	const serverLocalUrl = env?.VUE_APP_SERVER_LOCAL_URL
		? env.VUE_APP_SERVER_LOCAL_URL
		: '';

	const careerPortalUrl = env?.VUE_APP_CAREER_PORTAL_URL
		? env?.VUE_APP_CAREER_PORTAL_URL
		: '';

	const nodeServerUrl = env?.VUE_APP_NODE_SERVER_URL
		? env?.VUE_APP_NODE_SERVER_URL
		: '';

	const fileSystem = env?.VUE_APP_FILESYSTEM ? env.VUE_APP_FILESYSTEM : 'local';

	// for assets
	const serverUrl = ['s3'].includes(fileSystem)
		? ''
		: environment === 'production'
		? serverProductionUrl
		: environment === 'test'
		? serverTestUrl
		: serverLocalUrl;

	// const clientProductionUrl = env?.VUE_APP_CLIENT_SERVER_URL
	// 	? env.VUE_APP_CLIENT_SERVER_URL
	// 	: 'https://ats.fe.aosworld.com';
	// const clientlocalUrl = env?.VUE_APP_CLIENT_LOCAL_URL
	// 	? env.VUE_APP_CLIENT_LOCAL_URL
	// 	: 'http://localhost:8080';

	// const clientUrl =
	// 	environment === 'production' ? clientProductionUrl : clientlocalUrl;

	const websocketURL = env?.VUE_APP_WEB_SOCKET_SERVER_URL
		? env?.VUE_APP_WEB_SOCKET_SERVER_URL
		: 'wss://ats.aosworld.com/ws/notifications';

	const azureAuth = Object.entries(env).reduce((r, [key, value]) => {
		if (!key.includes('VUE_APP_AZURE_')) return r;
		key = key.replace('VUE_APP_AZURE_', '').toLowerCase();

		return { ...r, [key]: value };
	}, {});

	const google = Object.entries(env).reduce((r, [key, value]) => {
		if (!key.includes('VUE_APP_GOOGLE_')) return r;
		key = key.replace('VUE_APP_GOOGLE_', '').toLowerCase();

		return { ...r, [key]: value };
	}, {});

	const resolvePdfServerUrl = () => {
		let pdfUrl = env?.VUE_APP_PDF_SERVER_URL;
		if (!pdfUrl) return 'https://pdf.aosworld.com';

		if (pdfUrl?.slice(-1) === '/') pdfUrl = pdfUrl?.slice(0, -1);
		return pdfUrl;
	};
	const pdfServerUrl = resolvePdfServerUrl();

	return {
		env,
		environment,
		serverLocalUrl,
		serverTestUrl,
		serverProductionUrl,
		nodeServerUrl,
		careerPortalUrl,
		fileSystem,
		// clientProductionUrl,
		// clientlocalUrl,
		// clientUrl,
		serverUrl,
		websocketURL,
		azureAuth,
		google,
		pdfServerUrl,
	};
};

export default useEnv;
