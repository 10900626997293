const state = {
  companies: [],
  templates: []
};

const mutations = {
  companies: (state, payload) => (state.companies = payload),
  templates: (state, payload) => (state.templates = payload)
};

export default { state, mutations };
