import useCommonUtils from '@/composables/app/useCommonUtils';
const { isJsonParsable } = useCommonUtils();
const state = {
    positions: [],
    loading: false,
    customFields: [],
    joiningTemplates: []
};

const mutations = {
    positions: (state, payload) => (state.positions = payload),
    loading: (state, payload) => (state.loading = payload),
    customFields: (state, payload) => (state.customFields = payload),
    joiningTemplates: (state, payload) => {
        const templates = payload.map((template) => {
            if (isJsonParsable(template?.template)) {
                template.template = JSON.parse(template.template);
            }
            return template;
        });
        state.joiningTemplates = templates;
    }
};

export default { state, mutations };
