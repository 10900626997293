import store from '@/store';
import useCommonUtils from '@/composables/app/useCommonUtils';
import emitter from 'tiny-emitter/instance';

const useToast = () => {
  const { generateRandomString, isObject } = useCommonUtils();

  const showDefaultToast = (
    message = 'Action successful',
    variant = 'success',
    text = null,
    type = 'default',
    delay = 10000
  ) => {
    const id = generateRandomString(8);
    const payload = { id, message, text, variant, type, delay };
    store.commit('utils/toast', payload);
  };

  // valid value of 'data' function argument
  // value should be null if want to render the default data
  // {
  // 	message: string // any text to show,
  // 	actionButton: {
  // 		variant: string // bootstrap-variant,
  // 		text: string // any text to render on the action button,
  // 	},
  // 	returnButton: {
  // 		variant: string // bootstrap-variant,
  // 		text: string // any text to render on the return button,
  // 	},
  // }
  const showConfirmModal = (data, callback) => {
    emitter.emit('show-confirm-modal', data, callback);
  };

  const showConfirmModalAsync = (data) => {
    return new Promise((resolve) => {
      showConfirmModal(data, (confirmed) => {
        resolve(confirmed);
      });
    });
  };

  // valid value of 'data' function argument
  // value should be null if want to render the default data
  // {
  // 	message: string // any text to show,
  // 	warning: string // warning text,
  // 	input: string // input text to match
  //	buttonText: string // confirm button text
  // }
  const showTextInputConfirmModal = (data, callback) => {
    emitter.emit('show-text-input-confirm-modal', data, callback);
  };

  // valid value of 'data' function argument
  // value should be null if want to render the default data
  // {
  // 	to: string || string[] // email address for to receipient, default [],
  // 	subject: string // subject of the email, default '',
  // 	body: string // email body, default '',
  //	email_template_id: // id of preselected of email template, default null,
  //	allow_modify_to: whether new address can be added to to receipient, default true
  // }
  const showSendEmailModal = (data, callback = null) => {
    data = {
      ...data,
      to:
        data.to && Array.isArray(data.to)
          ? data.to
          : data.to && typeof data.to === 'string'
          ? [data.to]
          : []
    };
    emitter.emit('send-email', data, callback);
  };

  const showToastFromResponse = (
    response,
    fallbackMesssage = {},
    hideToastIfNoResponse = true
  ) => {
    if (hideToastIfNoResponse && !response) return;
    if (
      response &&
      isObject(response) &&
      'code' in response &&
      response?.message
    ) {
      showDefaultToast(
        response?.code == 0 ? 'Success' : 'Error',
        response?.code == 0 ? 'success' : 'danger',
        response?.message
      );
    } else if (fallbackMesssage?.message) {
      showDefaultToast(
        fallbackMesssage?.type || 'Success',
        fallbackMesssage?.variant || 'success',
        fallbackMesssage?.message
      );
    }
  };

  return {
    showDefaultToast,
    showConfirmModal,
    showConfirmModalAsync,
    showTextInputConfirmModal,
    showSendEmailModal,
    showToastFromResponse
  };
};

export default useToast;
